import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Vantagens from './vantagens';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

// const SOBRE = gql`
//   query {
//     page(id: 24, idType: DATABASE_ID) {
//         id
//         title
//         content
//         sobrenos {
//             fieldGroupName
//             descricaoSobreNos
//             tituloSobreNos
//             titulodestaqueSobreNos
//             imagemSobreNos {
//               altText
//               sourceUrl
//             }
//           }
//       }
//   }
// `;


const SOBRE = gql`
query {
    elementosWP(where: {id: 51}) {
        edges {
            node {
                sobrenos {
                    tituloSobreNos
                    titulodestaqueSobreNos
                    descricaoSobreNos
              }
              }
        }
      }
  }
  `;


const SobreNos = () => {
    const { loading, error, data } = useQuery(SOBRE);
    const sobre = data?.elementosWP.edges[0].node.sobrenos
    // console.log(sobre)


    if (loading) return
    if (error) return <p>Erro ao carregar: {error.message}</p>;


    function createMarkup(html) {
        return { __html: html };
    }



    return (
        <div className='section-main'>
            <div className='section-default-center sobre-nos-section'>
                <div className='coluna-default-left'>
                    <div className='coluna-sobre-nos-child'>
                        <h4>{sobre.tituloSobreNos}</h4>
                        <h1>{sobre.titulodestaqueSobreNos}</h1>
                    </div>
                    <div className='coluna-sobre-nos-child sobre-nos-descricao'>
                        <p dangerouslySetInnerHTML={createMarkup(sobre.descricaoSobreNos)} />
                        
                        <Link className="menu-label" to="/formulario" onClick={() => smoothScrollToTop()}>
                            <Button type="primary" className="sobre-form-button-f-branco">
                                Faça a sua reserva!
                            </Button>
                        </Link>
                        
                    
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SobreNos;


function smoothScrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}
