import React, { useEffect, useState, useRef } from 'react';
import { useQuery, gql } from '@apollo/client';
import Slider from '../components/elementos-dinamicos/slider';
import SobreNos from '../components/componentes-home/sobre-nos';
import Servicos from '../components/componentes-home/servicos';
import ComoFunciona from '../components/componentes-home/comofunciona';
import DestaqueReserva from '../components/componentes-home/destaque-reserva';
import Faqs from '../components/componentes-home/faqs';
import Testemunhos from '../components/componentes-home/testemunhos';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SLIDERDATA } from '../data/sliderData';
import { SERVICOSDATA } from '../data/servicosData';
import Vantagens from '../components/componentes-home/vantagens';

// Mobile
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
    }}
    spin
  />
);

const LoadingSpinner = () => {
  return (
    <div className="loading-sec">
      <Spin indicator={antIcon} />
    </div>
  );
};


const Home = ({ 
  isTabletMobile, 
  setCliquenoParqueHome,
  aboutUsRef,
  servicesRef,
  howItWorksRef,
  faqsRef,
  testimonialsRef,
 }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sliderData, setSliderData] = useState(null); // Estado para guardar os dados do slider
  const [servicosData, setServicosData] = useState(null); // Estado para guardar os dados dos servicos

  const { loading: sliderLoading, error: sliderError, data: sliderQueryData } = useQuery(SLIDERDATA);
  const { loading: servicosLoading, error: servicosError, data: servicosQueryData } = useQuery(SERVICOSDATA);


  useEffect(() => {
    if (!sliderLoading && !sliderError) {
      const sliderData = sliderQueryData?.elementosWP.edges[0].node.sliderHome;
      setSliderData(sliderData);
    }
    if (!servicosLoading && !servicosError) {
      const servicosData = servicosQueryData?.elementosWP.edges[0].node.servicos;
      setServicosData(servicosData);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, [sliderLoading, sliderError, sliderQueryData, servicosLoading, servicosError, servicosQueryData]);


  // // Função para rolar para uma seção
  // const scrollToSection = (ref) => {
  //   if (ref.current) {
  //     ref.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  return (
    <div className='section-main'>
      {isLoading ? (
        <div className='loading-home'>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Slider sliderData={sliderData} isTabletMobile={isTabletMobile} />
          <DestaqueReserva />
          <div ref={servicesRef} id="servicos">
            <Servicos servicosData={servicosData} setCliquenoParqueHome={setCliquenoParqueHome} />
          </div>
          <div ref={aboutUsRef} id="sobre-nos">
            <SobreNos />
          </div>
          <div id="vantagens">
            <Vantagens />
          </div>
          <div ref={howItWorksRef} id='comofunciona'>
            <ComoFunciona />
          </div>
          <div ref={faqsRef} id='faqs'>
            <Faqs />
          </div>
          <div ref={testimonialsRef} id='testemunhos'>
            <Testemunhos />
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
