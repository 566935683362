import { BiError } from "react-icons/bi";

export function PesoMsg(props) {
    return (
        <div className="mensagem-de-aviso">
            <BiError />
            <span> Alerta, {props.mensagem}</span>
        </div>
    )
}


