import React, { useEffect, useState } from 'react';
import { DADOSPESSOAISID } from '../../data/obterReservaID';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { AutoComplete, Button } from 'antd';
import { todosPaises } from '../../data/todosPaises.js';
import { indicativosTelefone } from '../../data/indicativosTelefone.js';
import { LoadingOutlined } from '@ant-design/icons';
import { BiError, BiCheckCircle } from "react-icons/bi";
import { Spin } from 'antd';
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


const Pagamentos = ({ idDoPostSave, idDaOrderSave, manterDadosPessoais, setManterDadosPessoais }) => {

    //id da reserva
    const { id } = useParams();

    const { data } = useQuery(DADOSPESSOAISID, {
        variables: { id },
    });

    const dadodareserva = data?.reserva
    // console.log(data)

    useEffect(() => {
        if (manterDadosPessoais && data && data.reserva) {
            setPrimeiroNomeCh(dadodareserva.dadosDoCliente.primeiroNome)
            setUltimonomeCh(dadodareserva.dadosDoCliente.ultimoNome)
            setTelefoneCh(dadodareserva.dadosDoCliente.telemovel)
            setTelefonePaisCh(dadodareserva.dadosDoCliente.telemovelPais);
            setEmailCh(dadodareserva.dadosDoCliente.email)
            setManterDadosPessoais(false)
        }
    }, [data]);


    const [primeironomech, setPrimeiroNomeCh] = useState('');
    const [ultimonomech, setUltimonomeCh] = useState('');
    const [paisregiaoch, setPaisRegiaoCh] = useState('');
    const [moradach, setMoradaCh] = useState('');
    const [localidadech, setLocalidadeCh] = useState('');
    const [codigopostalch, setCodigoPostalCh] = useState('');
    const [telefonech, setTelefoneCh] = useState('');
    const [telefonePaisch, setTelefonePaisCh] = useState('');
    const [emailch, setEmailCh] = useState('');
    const [contribuintech, setContribuinteCh] = useState('');
    const [formularioenviado, setFormularioenviado] = useState(false);

    const [formularioerro, setFormularioErro] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [camposVazios, setCamposVazios] = useState(false);




    //ERROS CAMPOS OBRIGATORIOS
    const [erroNoInputNome, setErroNoInputNome] = useState(false)
    const [erroNoInputSobrenome, setErroNoInputSobrenome] = useState(false)
    const [erroNoInputTelemovelPais, setErroNoInputTelemovelPais] = useState(false)
    const [erroNoInputTelemovel, setErroNoInputTelemovel] = useState(false)
    const [erroNoInputEmail, setErroNoInputEmail] = useState(false)
    const [erroNoInputPaisRegiao, setErroNoInputPaisRegiao] = useState(false)
    const [erroNoInputCodigoPostal, setErroNoInputCodigoPostal] = useState(false)



    const handleNIFChange = (e) => {
        const input = e.target.value;
        const onlyNumbers = input.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        setContribuinteCh(onlyNumbers);
    };

    const handlePrimeiroNome = (e) => {
        setPrimeiroNomeCh(e.target.value);
        setErroNoInputNome(false)
    };

    const handleUltimoNome = (e) => {
        setUltimonomeCh(e.target.value);
        setErroNoInputSobrenome(false)
    };

    const handleSearchPais = (value) => {
        setPaisRegiaoCh(value);
    };

    const handleSelectPais = (value) => {
        setPaisRegiaoCh(value);
        setErroNoInputPaisRegiao(false)
    };

    const filteredOptionsPaises = todosPaises.filter(paises =>
        paises.toLowerCase().includes(paisregiaoch.toLowerCase())
    );

    const handleMorada = (e) => {
        setMoradaCh(e.target.value);
    };

    const handleLocalidade = (e) => {
        setLocalidadeCh(e.target.value);
    };

    const handleCodigoPostal = (e) => {
        const valorDigitado = e.target.value;

        // Usando uma expressão regular para permitir apenas números e símbolos comuns
        const regex = /^[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
    
        if (regex.test(valorDigitado) || valorDigitado === '') {
          setCodigoPostalCh(valorDigitado);
          setErroNoInputCodigoPostal(false);
        } else {
          setErroNoInputCodigoPostal(true);
        }
    };

    // const handleTelefone = (e) => {
    //     const input = e.target.value;
    //     const onlyNumbers = input.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    //     const maxLength = 9;
    //     const limitedValue = onlyNumbers.slice(0, maxLength); // Limita o valor a 9 dígitos
    //     const formattedValue = limitedValue.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3'); // Adiciona os espaços
    //     setTelefoneCh(formattedValue);
    //     // setTelefoneCh(e.target.value);
    // };

    const handleSearchTelefone = (value) => {

        setTelefonePaisCh(value);

    };

    const handleSelectTelefone = (value) => {

        setTelefonePaisCh(value);
        setErroNoInputTelemovelPais(false)

    };

    const filteredOptionsTelemovel = indicativosTelefone.filter(option =>
        option.value.includes(telefonePaisch)
    );

    const handleEmail = (e) => {
        setEmailCh(e.target.value);
        setErroNoInputEmail(false)
    };



    //PARA ENVIAR A RESERVA
    const enviarDadosFaturacao = {
        nomecheckout: primeironomech,
        ultimonomecheckout: ultimonomech,
        paisregiao: paisregiaoch,
        morada: moradach,
        localidade: localidadech,
        codigopostal: codigopostalch,
        telefone: telefonech,
        telemovel_pais: telefonePaisch,
        contribuinte: contribuintech,
        emailcheckout: emailch,
        post_id: idDoPostSave,
        order_id: idDaOrderSave
    }


    // const handleSubmit = async () => {


    //     setIsLoading(true)
    //     setCamposVazios(false);



    //     if (primeironomech === '') {
    //         console.log('vazio')
    //     } else {
    //         console.log('completo')
    //     }

    //     if (ultimonomech === '') {
    //         console.log('vazio')
    //     } else {
    //         console.log('completo')
    //     }

    //     if (paisregiaoch === '') {
    //         console.log('vazio')
    //     } else {
    //         console.log('completo')
    //     }

    //     if (codigopostalch === '') {
    //         console.log('vazio')
    //     } else {
    //         console.log('completo')
    //     }

    //     if (telefonePaisch === '') {
    //         console.log('vazio')
    //     } else {
    //         console.log('completo')
    //     }

    //     if (telefonech === '') {
    //         console.log('vazio')
    //     } else {
    //         console.log('completo')
    //     }

    //     if (emailch === '') {
    //         console.log('vazio')
    //     } else {
    //         console.log('completo')
    //     }


    //     if (primeironomech !== '' && ultimonomech !== '') {
    //         //pedido de envio de informacao API
    //         axios.post('https://app.indoorparkinglowcost.pt/wp-json/api/submit_billing', enviarDadosFaturacao)
    //             .then(response => {

    //                 const paymentUrl = response.data;

    //                 setCamposVazios(false);
    //                 setIsLoading(false)
    //                 setFormularioenviado(true);
    //                 setFormularioErro(false)
    //                 setTimeout(() => {
    //                     window.location.href = (paymentUrl);
    //                 }, 1000);

    //             })
    //             .catch(error => {
    //                 setCamposVazios(false);
    //                 setIsLoading(false)
    //                 setFormularioenviado(false);
    //                 setFormularioErro(true);
    //                 console.error('Erro:', error);
    //             });
    //     } else {
    //         setIsLoading(false)
    //         setFormularioenviado(false);
    //         setCamposVazios(true);
    //     }

    // };

    const handleSubmit = async () => {
        setIsLoading(true);
        setCamposVazios(false);

        const campos = {
            primeironome: primeironomech,
            ultimonome: ultimonomech,
            paisregiao: paisregiaoch,
            codigopostal: codigopostalch,
            telefonePais: telefonePaisch,
            telefone: telefonech,
            email: emailch,
        };

        // Função para verificar se um campo está vazio
        const campoVazio = (valor) => valor === '';

        // Iterar sobre os campos e verificar se estão vazios
        let algumCampoVazio = false;
        for (const campo in campos) {
            if (campoVazio(campos[campo])) {
                algumCampoVazio = true;
                // console.log(`${campo} está vazio`);
            } else {
                // console.log(`${campo} está completo`);
            }
        }

        if (!algumCampoVazio) {
            axios.post('https://app.indoorparkinglowcost.pt/wp-json/api/submit_billing', enviarDadosFaturacao)
                .then(response => {

                    const paymentUrl = response.data;

                    setCamposVazios(false);
                    setIsLoading(false)
                    setFormularioenviado(true);
                    setFormularioErro(false)
                    setTimeout(() => {
                        window.location.href = (paymentUrl);
                    }, 1000);

                })
                .catch(error => {
                    setCamposVazios(false);
                    setIsLoading(false)
                    setFormularioenviado(false);
                    setFormularioErro(true);
                    console.error('Erro:', error);
                });
        } else {
            setIsLoading(false);
            setFormularioenviado(false);
            setCamposVazios(true);


            if (primeironomech === '') {
                setErroNoInputNome(true)
            } else {
                setErroNoInputNome(false)
            }

            if (ultimonomech === '') {
                setErroNoInputSobrenome(true)
            } else {
                setErroNoInputSobrenome(false)
            }

            if (paisregiaoch === '') {
                setErroNoInputPaisRegiao(true)
            } else {
                setErroNoInputPaisRegiao(false)
            }

            if (codigopostalch === '') {
                setErroNoInputCodigoPostal(true)
            } else {
                setErroNoInputCodigoPostal(false)
            }

            if (telefonech === '') {
                setErroNoInputTelemovel(true)
            } else {
                setErroNoInputTelemovel(false)
            }

            if (telefonePaisch === '') {
                setErroNoInputTelemovelPais(true)
            } else {
                setErroNoInputTelemovelPais(false)
            }

            if (emailch === '') {
                setErroNoInputEmail(true)
            } else {
                setErroNoInputEmail(false)
            }

        }
    };


    // console.log(idDoPostSave)

    //PARA CONSTRUIR A MENSAGEM DE ERRO COM CAMPOS EM FALTA
    const errorMessage = [];

    if (erroNoInputNome) errorMessage.push('Nome');
    if (erroNoInputSobrenome) errorMessage.push('Sobrenome');
    if (erroNoInputPaisRegiao) errorMessage.push('País/Região');
    if (erroNoInputCodigoPostal) errorMessage.push('Código-Postal');
    if (erroNoInputTelemovelPais) errorMessage.push('Indicativo do País');
    if (erroNoInputTelemovel) errorMessage.push('Telemóvel');
    if (erroNoInputEmail) errorMessage.push('Email');


    return (
        <div className="formulario-de-reservas pagamentos-section">
            <div className="form main">
                <div>
                    <h1>Finalizar Reserva</h1>
                    <h3>Para completar a sua reserva com o id #{idDoPostSave}, preencha os campos.</h3>
                </div>
                <div className="sec-dados-pessoais sec-pagamentos">
                    <div className="flex-col-titulo-pagamento">
                        <h2>Dados de Faturação</h2>
                        <hr className="linha-principal" />
                    </div>
                    <div className='col-dados-pagamemento'>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">Nome *</a>
                            <br />
                            <input
                                required
                                type="text"
                                className={`input-calendario ${erroNoInputNome ? 'campo-com-erro' : ''}`}
                                placeholder="Primeiro nome"
                                value={primeironomech}
                                onChange={handlePrimeiroNome}
                            />
                        </div>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">Sobrenome *</a>
                            <br />
                            <input
                                required
                                type="text"
                                className={`input-calendario ${erroNoInputSobrenome ? 'campo-com-erro' : ''}`}
                                placeholder="Último nome"
                                value={ultimonomech}
                                onChange={handleUltimoNome}
                            />

                        </div>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">País/Região *</a>
                            <br />
                            <AutoComplete
                                className={`paises-select ${erroNoInputPaisRegiao ? 'campo-com-erro-tel' : ''}`}
                                style={{
                                    height: 47,
                                }}
                                options={filteredOptionsPaises.map(paises => ({ value: paises }))}
                                optionLabelProp="label"
                                value={paisregiaoch}
                                placeholder="País/Região"
                                filterOption={false}
                                onSearch={handleSearchPais}
                                onSelect={handleSelectPais}

                            />
                        </div>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">Morada</a>
                            <br />
                            <input
                                type="text"
                                className="input-calendario"
                                value={moradach}
                                onChange={handleMorada}

                            />
                        </div>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">Localidade</a>
                            <br />
                            <input
                                required
                                type="text"
                                className="input-calendario"
                                value={localidadech}
                                onChange={handleLocalidade}

                            />
                        </div>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">Código Postal *</a>
                            <br />
                            <input
                                type="text"
                                className={`input-calendario ${erroNoInputCodigoPostal ? 'campo-com-erro' : ''}`}
                                value={codigopostalch}
                                onChange={handleCodigoPostal}

                            />
                        </div>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">Telemóvel *</a>
                            <br />
                            <div className='flex-nogap'>
                                <AutoComplete
                                    style={{
                                        width: 160,
                                        height: 47,
                                    }}
                                    className={`${erroNoInputTelemovelPais ? 'campo-com-erro-tel' : ''}`}
                                    options={filteredOptionsTelemovel}
                                    optionLabelProp="label"
                                    value={telefonePaisch}
                                    placeholder="+000"
                                    filterOption={false}
                                    onSearch={handleSearchTelefone}
                                    onSelect={handleSelectTelefone}
                                />

                                <input
                                    required
                                    type="text"
                                    className={`input-calendario ${erroNoInputTelemovel ? 'campo-com-erro' : ''}`}
                                    value={telefonech}
                                    placeholder="000 000 000"
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const onlyNumbers = input.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
                                        setTelefoneCh(onlyNumbers); // Atualize o estado com o valor numérico corrigido
                                        setErroNoInputTelemovel(false)
                                    }}
                                />
                            </div>
                        </div>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">Email *</a>
                            <br />
                            <input
                                required
                                type="text"
                                className={`input-calendario ${erroNoInputEmail ? 'campo-com-erro' : ''}`}
                                value={emailch}
                                onChange={handleEmail}
                                placeholder="*******************@******"
                                onInvalid={(e) => {
                                    e.target.setCustomValidity('Insira um endereço de email válido');
                                }}
                                onInput={(e) => {
                                    e.target.setCustomValidity('');
                                }}
                            />
                        </div>
                        <div className='subcol-dados-pagamento'>
                            <a className="label-do-campo">Nº Contribuinte</a>
                            <br />
                            <input
                                type="text"
                                className="input-calendario"
                                value={contribuintech}
                                onChange={handleNIFChange}
                            />
                        </div>
                        <Button type="primary" className="login-form-button-f-branco" onClick={handleSubmit}>
                            <Link className="menu-label">Enviar</Link>
                        </Button>
                    </div>
                    {isLoading ? (
                        <>
                            <div className="loading-sec-baixo mensagem-de-loading fundo-branco">
                                <p>Aguarde...</p>
                                <Spin indicator={antIcon} />
                            </div>
                        </>
                    ) : null}
                    {formularioenviado ? (
                        <>

                            <div className="loading-sec-baixo mensagem-de-sucesso fundo-branco">
                                <p className="text">Dados de Faturação registados com sucesso! Aguarde...</p>
                                <BiCheckCircle />
                            </div>
                        </>
                    ) : formularioerro ? (

                        <div className="mensagem-de-erro">
                            <p>Ocorreu algum erro, tente novamente!</p>
                            <BiError />
                        </div>
                    ) : camposVazios ? (
                        <>
                            <div className="mensagem-de-erro">
                                <BiError />
                                {/* <p>Os campos de preenchimento obrigatório estão vazios. ({erroNoInputNome ? 'Nome,' : null} {erroNoInputSobrenome ? 'Sobrenome,' : null} {erroNoInputPaisRegiao ? 'País/Região,' : null} {erroNoInputCodigoPostal ? 'Código-Postal,' : null} {erroNoInputTelemovelPais ? 'Indicativo do País,' : null} {erroNoInputTelemovel ? 'Telemóvel,' : null} {erroNoInputEmail ? 'Email' : null})</p> */}
                                <p>
                                    {errorMessage.length > 0 && (
                                        <>Os campos de preenchimento obrigatório estão vazios. ({errorMessage.join(', ')})</>
                                    )}
                                </p>
                            </div>
                        </>
                    ) : null}
                </div>
            </div >
        </div >
    );
};

export default Pagamentos;
