const configAdmin = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer I?E$$o!Wo_ V&v[}>$auT*yKc5apn-Cu]B2nbbSIy>?@j}q4G!6CN1O=>. PW@||',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    wpApi: {
        endpoint: 'https://app.indoorparkinglowcost.pt/wp-json',
        username: 'admin_indoor',
        password: '4TBT lLeP hjBA qINa tSTe zUEo'
    },
    wooAPI:{
        API_BASE_URL : 'https://app.indoorparkinglowcost.pt/wp-json',
        CONSUMER_KEY : 'ck_e870bdcc628bbd787e67cc825ff625221814ed6d',
        CONSUMER_SECRET : 'cs_ae321885838a88e1a6ffdf75b77da0c78f0c8c7b'
    }
};



export default configAdmin;
