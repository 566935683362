import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Hamburger from '../elementos-dinamicos/hamburger';
import { BiUser } from "react-icons/bi";
import { AiFillFacebook } from "react-icons/ai";
import { Button } from 'antd';
import logocores from "../../assets/imagens/logo-main.png"


const Header = ({
  setCliquenoParqueHome,
  domainOrigin,
  isAuthenticated,
  userData,
  isTabletMobile,
  aboutUsRef,
  servicesRef,
  howItWorksRef,
  faqsRef,
  testimonialsRef,
}) => {

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);


  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
    setPopupOpen(!popupOpen);
  }

  const handleClosePopup = () => {
    setHamburgerOpen(!hamburgerOpen)
    setPopupOpen(!popupOpen);
  }

  const handleBackToHome = () => {
    smoothScrollToTop()
    setHamburgerOpen(false)
    setPopupOpen(false);
    setCliquenoParqueHome(false)
  }

  const userFromLocalStorage = localStorage.getItem('user');
  const userMain = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;


  // Função para rolar para uma seção
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Função para adicionar a classe que remove o scroll
    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
    };

    // Função para remover a classe e tornar a colocar o scroll
    const enableScroll = () => {
      document.body.style.overflow = 'auto';
    };

    if (hamburgerOpen) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [hamburgerOpen]);

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <>
      <header>
        <div className='top-bar'>
          <div className='tor-bar-section'>
            {isTabletMobile ? (
              //  Responsive
              <>
                <ul className='menu-main'>
                  <li><a href='tel:+351220733834'>+351 939 066 037*</a><span>*(chamada para rede móvel nacional)</span></li>
                  <li>{isAuthenticated ? (
                    <>
                      <Link to="/dashboard" className='icon-user-login'><BiUser className='user-login-icon-logado' /></Link>
                    </>
                  ) : (
                    <>
                      <Link to="/login"><BiUser className='user-login-icon' /></Link>
                    </>
                  )}
                  </li>
                </ul>
              </>

            ) : (
              <>
                <ul className='menu-main'>
                  <li><a href='tel:+351220733834'>+351 939 066 037*</a><span>*(chamada para rede móvel nacional)</span></li>
                  <li><a href='mailto:info@indoorparkinglowcost.pt'>info@indoorparkinglowcost.pt</a></li>
                  <li>{isAuthenticated ? (
                    <>
                      <div className='flex'>
                        <Link to="/dashboard"><BiUser className='user-login-icon-logado' /></Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <Link to="/login"><BiUser className='user-login-icon' /></Link>
                    </>
                  )}
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
        <div className='header-main'>
          <div className='header-section'>
            <div className='logotipo'>
              <Link to={`/`} onClick={handleBackToHome}>
                <img
                  src={logocores}
                  alt='Logotipo'
                />
              </Link>
            </div>
            <div className='flex'>

              {isTabletMobile ? (
                //  Responsive
                <div className="hamburger" onClick={toggleHamburger}>
                  <Hamburger isOpen={hamburgerOpen} />
                </div>
              ) : (

                <div className='flex'>
                  <ul className='menu-main'>
                    {isHomePage ? (
                      <>
                        <li onClick={() => scrollToSection(aboutUsRef)}>Sobre Nós</li>
                        <li onClick={() => scrollToSection(servicesRef)}>Serviços</li>
                        <li onClick={() => scrollToSection(howItWorksRef)}>Como Funciona</li>
                        <li onClick={() => scrollToSection(faqsRef)}>FAQ's</li>
                        <li onClick={() => scrollToSection(testimonialsRef)}>Testemunhos</li>
                      </>
                    ) : (
                      <>
                        <li><Link to="/">Início</Link></li>
                        <li><Link to="/">Sobre Nós</Link></li>
                        <li><Link to="/">Serviços</Link></li>
                        <li><Link to="/">Como Funciona</Link></li>
                        <li><Link to="/">FAQ's</Link></li>
                        <li><Link to="/">Testemunhos</Link></li>
                      </>
                    )}
                  </ul>
                  <div>
                    <Link to="/formulario">
                      <Button type="primary" className="login-form-button-f-branco" onClick={() => {
                        setCliquenoParqueHome(false)
                      }}>
                        Reservar
                      </Button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

      </header>
      {popupOpen && (
        <div className='popup-overlay'>
          <div className=' popup-menu-canva'>
            <div className='popup-main-sec'>
              <nav>
                <ul className='menu-main-popup menu'>
                  {isHomePage ? (
                    <>
                      <li className="menu-label" onClick={() => {
                        scrollToSection(aboutUsRef)
                        toggleHamburger()
                      }}>Sobre Nós</li>
                      <li className="menu-label" onClick={() => {
                        scrollToSection(servicesRef)
                        toggleHamburger()
                      }}>Serviços</li>
                      <li className="menu-label" onClick={() => {
                        scrollToSection(howItWorksRef)
                        toggleHamburger()
                      }}>Como Funciona</li>
                      <li className="menu-label" onClick={() => {
                        scrollToSection(faqsRef)
                        toggleHamburger()
                      }}>FAQ's</li>
                      <li className="menu-label" onClick={() => {
                        scrollToSection(testimonialsRef)
                        toggleHamburger()
                      }}>Testemunhos</li>
                    </>
                  ) : (
                    <>
                      <li className="menu-label" onClick={() => {
                        toggleHamburger()
                      }}><Link to="/">Início</Link></li>
                      <li className="menu-label" onClick={() => {
                        toggleHamburger()
                      }}><Link to="/">Sobre Nós</Link></li>
                      <li className="menu-label" onClick={() => {
                        toggleHamburger()
                      }}><Link to="/">Serviços</Link></li>
                      <li className="menu-label" onClick={() => {
                        toggleHamburger()
                      }}><Link to="/">Como Funciona</Link></li>
                      <li className="menu-label" onClick={() => {
                        toggleHamburger()
                      }}><Link to="/">FAQ's</Link></li>
                      <li className="menu-label" onClick={() => {
                        toggleHamburger()
                      }}><Link to="/">Testemunhos</Link></li>
                    </>
                  )}
                </ul>
              </nav>
              <div>
                {/* <Link to={`/formulario`}>
                  <button className='button-sem-fundo' onClick={handleClosePopup}>Reservar</button>
                </Link> */}
                <Link to="/formulario" onClick={handleClosePopup}>
                  <Button type="primary" className="button-sem-fundo">
                    Reservar
                  </Button>
                </Link>
              </div>
              <div className='texto-centro'>
                <Link className='facebook' to={`https://www.facebook.com/indoorparkinglowcost`} target="_blank">
                  <AiFillFacebook />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;


function smoothScrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
