import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs } from 'antd';
import { BiError } from "react-icons/bi";
import { CalendarOutlined } from '@ant-design/icons';
import TabDashboardAdmin from "./componente-dashboard/tab-admin-dashboard";
import TabDashboardCliente from "./componente-dashboard/tab-cliente-dashboard";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
    }}
    spin
  />
);



const Dashboard = ({ isTabletMobile, isAuthenticated, setIsAuthenticated }) => {

  const navigate = useNavigate();


  const [userAllReservations, setUserAllReservations] = useState([]);
  const [userDiaReservations, setUserDiaReservations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardErro, setDashboardErro] = useState(false);

  const userFromLocalStorage = localStorage.getItem('user');

  const userMain = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;

  // console.log(userMain.data.user_email)

  useEffect(() => {
    if (isAuthenticated) {

      // const userEmail = userMain?.data?.user_email;
      // const userRole = userMain?.roles[0]
      // console.log(userRole)
      setIsLoading(true)


      let obterdatareservas;

      obterdatareservas = {
        email: userMain.data.user_email,
      }

      axios
        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_reservas', obterdatareservas)
        .then(response => {
          const userReservations = response.data
          // console.log('userReservations',userReservations)
          setUserDiaReservations(userReservations)
          setUserAllReservations(userReservations);
          setIsAuthenticated(true)
          setIsLoading(false)
        })
        .catch(error => {
          console.error('Erro:', error);
          setIsLoading(false)
          setDashboardErro(true)
          setIsAuthenticated(false)
        })

    } else {
      setIsLoading(false)
    }
  }, [isAuthenticated]);



  const searchFilterSempre = userAllReservations
    .filter((reservation) => {
      const fullName = `${reservation.acf.primeiro_nome} ${reservation.acf.ultimo_nome}`;
      const reservationId = reservation.ID.toString();
      const matriculacarro = reservation.acf.matricula;
      const matriculacarroLower = typeof matriculacarro === 'string' ? matriculacarro.toLowerCase() : '';
      const datadeentradap = `${reservation.acf.dia_de_entrada}`;
      const datadesaidap = `${reservation.acf.dia_de_saida}`;
      const searchQueryLowerCase = searchQuery.toLowerCase();

      return (
        fullName.toLowerCase().includes(searchQueryLowerCase) ||
        reservationId.includes(searchQueryLowerCase) ||
        matriculacarroLower.includes(searchQueryLowerCase) ||
        datadeentradap.includes(searchQueryLowerCase) ||
        datadesaidap.includes(searchQueryLowerCase)
      );
    })
    .sort((a, b) => {
      const dateA = new Date(a.acf.datadaultimaalteracao);
      const dateB = new Date(b.acf.datadaultimaalteracao);

      return dateB - dateA;
    });








  //Pesquisa pela reserva de Hoje
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();

  const todayDateString = `${dd}-${mm}-${yyyy}`;



  const searchFilterDay = userAllReservations
    .filter((reservation) => {
      const reservationDateParts = reservation.acf.dia_de_entrada
      const fullName = `${reservation.acf.primeiro_nome} ${reservation.acf.ultimo_nome}`;
      const reservationId = reservation.ID.toString();
      const matriculacarro = reservation.acf.matricula;
      const matriculacarroLower = typeof matriculacarro === 'string' ? matriculacarro.toLowerCase() : '';
      const datadeentradap = `${reservation.acf.dia_de_entrada}`;
      const datadesaidap = `${reservation.acf.dia_de_saida}`;
      const searchQueryLowerCase = searchQuery.toLowerCase();

      return (
        reservationDateParts === todayDateString &&
        (fullName.toLowerCase().includes(searchQueryLowerCase) ||
          reservationId.includes(searchQueryLowerCase) ||
          matriculacarroLower.includes(searchQueryLowerCase) ||
          datadeentradap.includes(searchQueryLowerCase) ||
          datadesaidap.includes(searchQueryLowerCase))
      );
    })
    .sort((a, b) => {
      const dateA = new Date(a.acf.datadaultimaalteracao);
      const dateB = new Date(b.acf.datadaultimaalteracao);

      return dateB - dateA;
    });






  //PESQUISA NA DASHBOARD
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };


  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.href = "/";
  };

  const handleForm = () => {
    navigate("/formulario");
  };


  const handleFormInicio = () => {
    navigate("/");
  };



  const listaTraducoesFuncaodaConta = {
    "administrator": "Administrador",
    "shop_manager": "Supervisor",
    "contributor": "Funcionário",
  };

  const funcaoDaConta = userMain?.roles[0].toLowerCase();
  const traducoesFuncaodaConta = listaTraducoesFuncaodaConta[funcaoDaConta] || "";


  const handleMarcacaoRapida = () => {
    smoothScrollToTop()
    navigate("/marcacao-rapida");
  };


  return (
    <div className="sec-geral-top dashboard-section">
      <div className="form main">
        {isLoading ? (
          <div className="loading-normal">
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <>
            {isAuthenticated ? (
              <>
                {userAllReservations.length === 0 ? (
                  <>
                    {userMain?.roles[0] === "administrator" || userMain?.roles[0] === "shop_manager" || userMain?.roles[0] === "contributor" ? (
                      <>
                        <div className="faca-a-sua-reserva">
                          <CalendarOutlined />
                          <div>
                            <h3 className="texto-centro">Aguarde pela 1ºReserva</h3>
                          </div>
                          <Button type="primary" onClick={handleFormInicio} className="login-form-button">
                            Voltar ao Início!
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="faca-a-sua-reserva">
                          <CalendarOutlined />
                          <div>
                            <h3 className="texto-centro">Bem vindo/a <span>{userMain?.data?.user_login}</span></h3>
                            <p className="texto-centro">Faça a sua primeira reserva!</p>
                          </div>
                          <Button type="primary" onClick={handleForm} className="login-form-button">
                            Reservar!
                          </Button>
                          <Button type="primary" htmlType="submit" to="/" className="login-sair-form-button" onClick={handleLogout}>
                            Sair da Conta
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {userMain?.roles[0] === "administrator" || userMain?.roles[0] === "shop_manager" || userMain?.roles[0] === "contributor" ? (
                      <>
                        <div className="cump-ao-utilizador">
                          <h3 className="cump-ao-utilizador-c"><span className="funcao-do-utilizador">{traducoesFuncaodaConta}</span></h3>
                          <h3 className="cump-ao-utilizador-n">Olá, <span className="nome-do-utilizador">{userMain?.data?.display_name} </span></h3>
                        </div>
                        <h1>Lista de Reservas:</h1>
                        <TabDashboardAdmin
                          isTabletMobile={isTabletMobile}
                          searchFilterDay={searchFilterDay}
                          searchFilterSempre={searchFilterSempre}
                          searchQuery={searchQuery}
                          handleInputChange={handleInputChange}
                          smoothScrollToTop={smoothScrollToTop}
                        />
                      </>
                    ) : (
                      <>
                        <div className="cump-ao-utilizador">
                          <h3>Olá, <span className="nome-do-utilizador">{userMain?.data?.user_login}</span></h3>
                        </div>
                        <h1>Lista de Reservas:</h1>
                        <TabDashboardCliente
                          isTabletMobile={isTabletMobile}
                          searchFilterSempre={searchFilterSempre}
                          searchQuery={searchQuery}
                          handleInputChange={handleInputChange}
                          smoothScrollToTop={smoothScrollToTop}
                          isLoading={isLoading}
                        />
                      </>
                    )}
                    <div className="flex btn-dashboard-bottom">
                      {userMain?.roles[0] === "administrator" || userMain?.roles[0] === "shop_manager" || userMain?.roles[0] === "contributor" ? (
                        <Button type="primary" className="login-form-button" onClick={handleMarcacaoRapida}>
                          Marcação Rápida
                        </Button>
                      ) : (
                        <></>
                      )}
                      <Button type="primary" htmlType="submit" to="/" className="login-sair-form-button" onClick={handleLogout}>
                        Sair da Conta
                      </Button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="acesso-negado">
                  <BiError />
                  <p className="texto-centro">Não tem permissão para aceder a esta àrea.</p>
                  <Button type="primary" onClick={handleLogout} className="login-form-button">
                    Voltar ao Início
                  </Button>
                </div>
              </>
            )}
          </>
        )}


        {dashboardErro && (
          <div className="mensagem-de-erro">
            <p>Ocorreu algum erro, tente novamente!</p>
            <BiError />
          </div>
        )}
      </div>
    </div>
  );

};

export default Dashboard;


function smoothScrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}



// (
//   <div className="acesso-negado">
//     <BiError />
//     <p className="texto-centro">Não tem permissão para aceder a esta àrea.</p>
//     <Button type="primary" onClick={handleLogout} className="login-form-button">
//       Início
//     </Button>
//   </div>
// )