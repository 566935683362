import { BiError } from "react-icons/bi";

export function AlertaMsg(props) {
    return (
        <div className="mensagem-de-erro msg-sec">
            <BiError />
            <span> Alerta, {props.mensagem}</span >
        </div>
    )

}
