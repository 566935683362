export const todosPaises = [
    "Afeganistão",
    "Ilhas Aland",
    "Albânia",
    "Argélia",
    "Samoa Americana",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antártica",
    "Antígua e Barbuda",
    "Argentina",
    "Armênia",
    "Aruba",
    "Austrália",
    "Áustria",
    "Azerbaijão",
    "Bahamas",
    "Bahrein",
    "Bangladesh",
    "Barbados",
    "Bielorrússia",
    "Bélgica",
    "Belize",
    "Benin",
    "Bermudas",
    "Butão",
    "Bolívia",
    "Sint Eustatius e Saba Bonaire",
    "Bósnia e Herzegovina",
    "Botswana",
    "Ilha Bouvet",
    "Brasil",
    "Território Britânico do Oceano Índico",
    "Brunei Darussalam",
    "Bulgária",
    "Burkina Faso",
    "Burundi",
    "Camboja",
    "Camarões",
    "Canadá",
    "Cabo Verde",
    "Ilhas Cayman",
    "República Centro-Africana",
    "Chade",
    "Chile",
    "China",
    "Ilha do Natal",
    "Ilhas Cocos (Keeling)",
    "Colômbia",
    "Comores",
    "Congo",
    "República Democrática do Congo",
    "Ilhas Cook",
    "Costa Rica",
    "Costa do Marfim",
    "Croácia",
    "Cuba",
    "Curaçao",
    "Chipre",
    "República Tcheca",
    "Dinamarca",
    "Djibuti",
    "Dominica",
    "República Dominicana",
    "Equador",
    "Egito",
    "El Salvador",
    "Guiné Equatorial",
    "Eritreia",
    "Estônia",
    "Etiópia",
    "Ilhas Falkland (Malvinas)",
    "Ilhas Faroe",
    "Fiji",
    "Finlândia",
    "França",
    "Guiana Francesa",
    "Polinésia Francesa",
    "Territórios Franceses do Sul",
    "Gabão",
    "Gâmbia",
    "Geórgia",
    "Alemanha",
    "Gana",
    "Gibraltar",
    "Grécia",
    "Groenlândia",
    "Granada",
    "Guadalupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guiné",
    "Guiné-Bissau",
    "Guiana",
    "Haiti",
    "Ilha Heard e Ilhas McDonald",
    "Santa Sé (Estado da Cidade do Vaticano)",
    "Honduras",
    "Hong Kong",
    "Hungria",
    "Islândia",
    "Índia",
    "Indonésia",
    "Irã (República Islâmica do)",
    "Iraque",
    "Irlanda",
    "Ilha de Man",
    "Israel",
    "Itália",
    "Jamaica",
    "Japão",
    "Jersey",
    "Jordânia",
    "Cazaquistão",
    "Quênia",
    "Kiribati",
    "República Popular Democrática da Coréia",
    "República da Coréia",
    "Kosovo",
    "Kuwait",
    "Quirguistão",
    "República Democrática Popular do Laos",
    "Letônia",
    "Líbano",
    "Lesoto",
    "Libéria",
    "Jamahiriya Árabe da Líbia",
    "Liechtenstein",
    "Lituânia",
    "Luxemburgo",
    "Macau",
    "Antiga República Iugoslava da Macedônia",
    "Madagascar",
    "Malawi",
    "Malásia",
    "Maldivas",
    "Mali",
    "Malta",
    "Ilhas Marshall",
    "Martinica",
    "Mauritânia",
    "Maurício",
    "Mayotte",
    "México",
    "Estados Federados da Micronésia",
    "Moldávia, República da",
    "Mônaco",
    "Mongólia",
    "Montenegro",
    "Montserrat",
    "Marrocos",
    "Moçambique",
    "Mianmar",
    "Namíbia",
    "Nauru",
    "Nepal",
    "Países Baixos",
    "Antilhas Holandesas",
    "Nova Caledônia",
    "Nova Zelândia",
    "Nicarágua",
    "Níger",
    "Nigéria",
    "Niue",
    "Ilha Norfolk",
    "Ilhas Marianas do Norte",
    "Noruega",
    "Omã",
    "Paquistão",
    "Palau",
    "Território Palestino Ocupado",
    "Panamá",
    "Papua Nova Guiné",
    "Paraguai",
    "Peru",
    "Filipinas",
    "Pitcairn",
    "Polônia",
    "Portugal",
    "Porto Rico",
    "Catar",
    "Reunião",
    "Romênia",
    "Federação Russa",
    "Ruanda",
    "São Bartolomeu",
    "Santa Helena, Ascensão e Tristão da Cunha",
    "São Cristóvão e Nevis",
    "Santa Lúcia",
    "São Martinho",
    "São Pedro e Miquelão",
    "São Vicente e Granadinas",
    "Samoa",
    "San Marino",
    "São Tomé e Príncipe",
    "Arábia Saudita",
    "Senegal",
    "Sérvia",
    "Seychelles",
    "Serra Leoa",
    "Cingapura",
    "Sint Maarten (parte neerlandesa)",
    "Eslováquia",
    "Eslovênia",
    "Ilhas Salomão",
    "Somália",
    "África do Sul",
    "Geórgia do Sul e Ilhas Sandwich do Sul",
    "Sudão do Sul",
    "Espanha",
    "Sri Lanka",
    "Sudão",
    "Suriname",
    "Svalbard e Jan Mayen",
    "Suazilândia",
    "Suécia",
    "Suíça",
    "República Árabe da Síria",
    "Província da China Taiwan",
    "Tajiquistão",
    "República Unida da Tanzânia",
    "Tailândia",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad e Tobago",
    "Tunísia",
    "Turquia",
    "Turcomenistão",
    "Ilhas Turcas e Caicos",
    "Tuvalu",
    "Uganda",
    "Ucrânia",
    "Emirados Árabes Unidos",
    "Reino Unido",
    "Estados Unidos",
    "Ilhas Menores Distantes dos Estados Unidos",
    "Uruguai",
    "Uzbequistão",
    "Vanuatu",
    "Venezuela",
    "Vietnã",
    "Ilhas Virgens Britânicas",
    "Ilhas Virgens, EUA",
    "Wallis e Futuna",
    "Saara Ocidental",
    "Iémen",
    "Zâmbia",
    "Zimbábue"
];
