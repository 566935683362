import React from 'react';

const PreviewEntrega = ({ isTabletMobile, diaHoverMain, mesHover, anoHover, precoHover, diatoday, mestoday, anotoday, diaSelecionado, precodiaSelecionado }) => {

    return (
        <div className='preview-entrega'>

            {isTabletMobile ? (
                <>
                    {/* <div className='destaque-diaselecionado'>
                        <div className='flex'>
                            <a>{diaHoverMain}</a>
                            <p className='custo-do-dia'>{mesHover} - {anoHover}</p>
                        </div>
                        {precoHover}€
                    </div> */}
                </>
            ) : (
                <>
                    <div className='destaque-diaselecionado'>
                        <h3>MARCAÇÃO</h3>
                        <a>{diaHoverMain}</a>
                        <p className='custo-do-dia'>{mesHover} - {anoHover}</p>
                        <div className="custo-do-dia-price">
                            <span className="custo-do-dia">Custo do dia</span>
                            <br />
                            {precoHover}€
                        </div>
                    </div>
                </>
            )}


        </div>
    );

};

export default PreviewEntrega;
