import React from 'react';
// import { Select, Space } from 'antd';
import { AutoComplete } from 'antd';
import { carrosModelos } from '../../data/carrosModelos';

const DadosReserva = ({ matricula, setMatricula, modelo, setModelo, marcadocarro, setMarcaDoCarro }) => {


    // const handleMarcadoCarro = (e) => {
    //     setMarcaDoCarro(e.target.value);
    // };

    const handleSearch = (value) => {
        setMarcaDoCarro(value);
    };

    const handleSelect = (value) => {
        setMarcaDoCarro(value);
    };

    const filteredOptions = carrosModelos.filter(modelo =>
        modelo.toLowerCase().includes(marcadocarro.toLowerCase())
    );


    return (
        <div className="sec-dados-reserva">
            <div className="flex-col-titulo">
                <h2>Dados Reserva</h2>
            </div>
            <hr className="linha-principal" />
            <div className='col-dados-reserva'>

                <div className='subcol-dados-reserva'>
                    <a className="label-do-campo">Matrícula</a>
                    <br />
                    <input
                        type="text"
                        className="input-dados-reserva input-matricula"
                        value={matricula}
                        placeholder="XX - XX - XX"
                        onChange={(e) => setMatricula(e.target.value)}
                    />
                </div>

                <div className='subcol-dados-reserva custom-select custom-select-ant'>
                    <a className="label-do-campo">Marca</a>
                    <br />
                    {/* <select className='selecao-da-marca-carro' value={marcadocarro} onChange={handleMarcadoCarro}>
                        <option value="" >Marca</option>
                        <option value="Alfa Romeo">Alfa Romeo</option>
                        <option value="Audi">Audi</option>
                        <option value="BMW">BMW</option>
                        <option value="Citroen">Citroen</option>
                        <option value="Cupra">Cupra</option>
                        <option value="Dacia">Dacia</option>
                        <option value="Fiat">Fiat</option>
                        <option value="Ford">Ford</option>
                    </select> */}
                    <AutoComplete
                        style={{
                            width: 192,
                            height: 47,
                        }}
                        options={filteredOptions.map(modelo => ({ value: modelo }))}
                        optionLabelProp="label"
                        value={marcadocarro}
                        placeholder="Marca"
                        filterOption={false}
                        onSearch={handleSearch}
                        onSelect={handleSelect}
                    />
                    {/* <Select
                        defaultValue="Marca"
                        style={{
                            width: 200,
                        }}
                        onChange={handleMarcadoCarro}
                        options={[
                            {
                                value: '',
                                label: 'Marca',
                                disabled: true,
                            },
                            {
                                value: 'lucy',
                                label: 'Lucy',
                            },
                            {
                                value: 'Yiminghe',
                                label: 'yiminghe',
                            },
                            {
                                value: 'disabled',
                                label: 'Disabled',
                            },
                        ]}
                    /> */}
                </div>

                <div className='subcol-dados-reserva'>
                    <a className="label-do-campo">Modelo</a>
                    <br />
                    <input
                        type="text"
                        className="input-dados-reserva"
                        value={modelo}
                        placeholder="Modelo"
                        onChange={(e) => setModelo(e.target.value)}
                    />
                </div>
            </div>

        </div >
    );
};

export default DadosReserva;
