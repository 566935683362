import React, { useEffect, useState } from "react";
import axios from "axios";
// import { useQuery, gql } from '@apollo/client';
// import { DADOSPESSOAIS } from '../data/obterReserva';
import { Button, Modal, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import AreaGestaoReservaAdmin from "../components/componente-reserva/areagestareservaoadmin";
import AlterarEstadoDaReserva from "../components/componente-reserva/alterarestadodareserva";
import ServicosExtras from "../components/componente-reserva/servicosextras";
import { PiPencilBold } from "react-icons/pi";
import { MdKeyboardBackspace } from "react-icons/md";
import { SucessoMsg } from "../components/mensagens-alertas/mensagemdesucesso";
import { ErroMsg } from "../components/mensagens-alertas/mensagemdeerro";
import { AlertaMsg } from "../components/mensagens-alertas/mensagemdealerta";
import { LoadingMsg } from "../components/mensagens-alertas/loading";
import { AutoComplete } from 'antd';
import { carrosModelos } from '../data/carrosModelos';
const { Option } = Select;

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);






const RegistodoPedido = ({ isTabletMobile }) => {


    const { id } = useParams();
    const navigate = useNavigate();


    const userFromLocalStorage = localStorage.getItem('user');
    const userMain = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;
    const userRole = userMain?.roles[0]
    const userNameAccount = userMain?.data.user_nicename
    const dadosdofuncionarioparalogs = userMain?.data


    //obter servicos - para colocar na sec dos servicos adicionais a reserva
    const [extraDataServicosReserva, setExtraDataServicosReserva] = useState([]);


    const [isLoading, setIsLoading] = useState(true);
    const [localdeestacionamento, setLocaldeEstacionamento] = useState(''); //local de estacionamento ex (local 1)
    // const [historicodemovimentacoesdataArray, setHistoricodeMovimentacoesdataArray] = useState([]); //Array historico de estacionamento
    const [alterarestadodareserva, setAlterarestadodareserva] = useState(''); //estado da reserva ex (aberto)
    const [motivocancelarestadodareserva, setMotivocancelarestadodareserva] = useState(''); //descricao da reserva cancelada
    const [desejaeditarmotivo, setDesejaeditarmotivo] = useState(false);
    const [desejaadicionarmotivo, setDesejaadicionarmotivo] = useState(false);


    const [logsData, setLogsData] = useState([]); //estado sobre os logs


    const [isModalOpenEntrega, setIsModalOpenEntrega] = useState(false); //popup de edição do dia de entrega
    const [dataentreganovo, setDataentregaNovo] = useState(''); //popup de edição do dia de entrega
    //alertas do  dia de entrega
    const [isLoadingNovaDataEntrada, setIsLoadingNovaDataEntrada] = useState(false);
    const [isSucessoNovaDataEntrada, setIsSucessoNovaDataEntrada] = useState(false);
    const [isAlertaNovaDataEntrada, setIsAlertaNovaDataEntradaa] = useState(false);
    const [isFalhaNovaDataEntrada, setIsFalhaNovaDataEntrada] = useState(false);
    const [postcriadodiaentrada, setPostCriadodiaentrada] = useState(false);




    const [isModalOpenHoraEntrega, setIsModalOpenHoraEntrega] = useState(false); //popup de edição do hora de entrega
    const [horaentreganovo, setHoraentregaNovo] = useState(''); //popup de edição do hora de entrega
    //alertas do  hora de entrega
    const [isLoadingNovaHoraEntrada, setIsLoadingNovaHoraEntrada] = useState(false);
    const [isSucessoNovaHoraEntrada, setIsSucessoNovaHoraEntrada] = useState(false);
    const [isAlertaNovaHoraEntrada, setIsAlertaNovaHoraEntradaa] = useState(false);
    const [isFalhaNovaHoraEntrada, setIsFalhaNovaHoraEntrada] = useState(false);
    const [postcriadohoraentrada, setPostCriadohoraentrada] = useState(false);




    const [isModalOpenRecolha, setIsModalOpenRecolha] = useState(false); //popup de edição do dia de recolha
    const [datarecolhanovo, setDatarecolhaNovo] = useState(''); //popup de edição do hora de recolha
    //alertas do  hora de entrega
    const [isLoadingNovaDataRecolha, setIsLoadingNovaDataRecolha] = useState(false);
    const [isSucessoNovaDataRecolha, setIsSucessoNovaDataRecolha] = useState(false);
    const [isAlertaNovaDataRecolha, setIsAlertaNovaDataRecolhaa] = useState(false);
    const [isFalhaNovaDataRecolha, setIsFalhaNovaDataRecolha] = useState(false);
    const [postcriadodiarecolha, setPostCriadodiarecolha] = useState(false);




    const [isModalOpenHoraRecolha, setIsModalOpenHoraRecolha] = useState(false); //popup de edição do hora de recolha
    const [horarecolhanovo, setHorarecolhaNovo] = useState(''); //popup de edição do hora de recolha
    //alertas do  hora de recolha
    const [isLoadingNovaHoraRecolha, setIsLoadingNovaHoraRecolha] = useState(false);
    const [isSucessoNovaHoraRecolha, setIsSucessoNovaHoraRecolha] = useState(false);
    const [isAlertaNovaHoraRecolha, setIsAlertaNovaHoraRecolhaa] = useState(false);
    const [isFalhaNovaHoraRecolha, setIsFalhaNovaHoraRecolha] = useState(false);
    const [postcriadohorarecolha, setPostCriadohorarecolha] = useState(false);




    const [isModalOpenValetEntrega, setIsModalOpenValetEntrega] = useState(false); //popup de edição do valet de entrega
    const [valetentreganovo, setValetentregaNovo] = useState(false); //popup de edição do valet de entrega
    //alertas do valet de entrega
    const [isLoadingValetEntrada, setIsLoadingValetEntrada] = useState(false);
    const [isSucessoValetEntrada, setIsSucessoValetEntrada] = useState(false);
    const [isAlertaValetEntrada, setIsAlertaValetEntrada] = useState(false);
    const [isFalhaValetEntrada, setIsFalhaValetEntrada] = useState(false);
    const [postcriadovaletentrada, setPostCriadoValetEntrada] = useState(false);





    const [isModalOpenValetRecolha, setIsModalOpenValetRecolha] = useState(false); //popup de edição do valet de recolha
    const [valetrecolhanovo, setValetrecolhaNovo] = useState(false); //popup de edição do valet de recolha
    //alertas do valet de recolha
    const [isLoadingValetRecolha, setIsLoadingValetRecolha] = useState(false);
    const [isSucessoValetRecolha, setIsSucessoValetRecolha] = useState(false);
    const [isAlertaValetRecolha, setIsAlertaValetRecolha] = useState(false);
    const [isFalhaValetRecolha, setIsFalhaValetRecolha] = useState(false);
    const [postcriadovaletrecolha, setPostCriadoValetRecolha] = useState(false);




    const [isModalOpenTransferEntrega, setIsModalOpenTransferEntrada] = useState(false); //popup de edição do transfer de entrega
    const [transferentreganovo, setTransferentregaNovo] = useState(false); //sim ou nao transfer de entrega
    const [transferpessoasentreganovo, setTransferPessoasentregaNovo] = useState(''); //numero de pessoas transfer de entrega
    //alertas do transfer de entrega
    const [isLoadingTransferEntrada, setIsLoadingTransferEntrada] = useState(false);
    const [isSucessoTransferEntrada, setIsSucessoTransferEntrada] = useState(false);
    const [isAlertaTransferEntrada, setIsAlertaTransferEntrada] = useState(false);
    const [isFalhaTransferEntrada, setIsFalhaTransferEntrada] = useState(false);
    const [postcriadotransferentrada, setPostCriadoTransferEntrada] = useState(false);




    const [isModalOpenTransferRecolha, setIsModalOpenTransferRecolha] = useState(false); //popup de edição do transfer de recolha
    const [transferrecolhanovo, setTransferrecolhaNovo] = useState(false); //sim ou nao transfer de recolha
    const [transferpessoasrecolhanovo, setTransferPessoasrecolhaNovo] = useState(''); //numero de pessoas transfer de recolha
    //alertas do transfer de entrega
    const [isLoadingTransferRecolha, setIsLoadingTransferRecolha] = useState(false);
    const [isSucessoTransferRecolha, setIsSucessoTransferRecolha] = useState(false);
    const [isAlertaTransferRecolha, setIsAlertaTransferRecolha] = useState(false);
    const [isFalhaTransferRecolha, setIsFalhaTransferRecolha] = useState(false);
    const [postcriadotransferrecolha, setPostCriadoTransferRecolha] = useState(false);





    const [isModalOpenMatricula, setIsModalOpenMatricula] = useState(false); //popup de edição do campo matricula
    const [matriculaNovo, setMatriculaNovo] = useState(''); //sim ou nao transfer de recolha
    //alertas do matricula
    const [isLoadingMatricula, setIsLoadingMatricula] = useState(false);
    const [isSucessoMatricula, setIsSucessoMatricula] = useState(false);
    const [isAlertaMatricula, setIsAlertaMatricula] = useState(false);
    const [isFalhaMatricula, setIsFalhaMatricula] = useState(false);
    const [postPriadoMatricula, setPostCriadoMatricula] = useState(false);





    const [isModalOpenVooPartida, setIsModalOpenVooPartida] = useState(false); //popup de edição do Voo Partida
    const [vooPartidaNovo, setVooPartidaNovo] = useState(''); //sim ou nao transfer de recolha
    //alertas do Voo Partida
    const [isLoadingVooPartida, setIsLoadingVooPartida] = useState(false);
    const [isSucessoVooPartida, setIsSucessoVooPartida] = useState(false);
    const [isAlertaVooPartida, setIsAlertaVooPartida] = useState(false);
    const [isFalhaVooPartida, setIsFalhaVooPartida] = useState(false);
    const [postPriadoVooPartida, setPostCriadoVooPartida] = useState(false);








    const [isModalOpenVooChegada, setIsModalOpenVooChegada] = useState(false); //popup de edição do Voo Chegada
    const [vooChegadaNovo, setVooChegadaNovo] = useState(''); //sim ou nao transfer de recolha
    //alertas do Voo Chegada
    const [isLoadingVooChegada, setIsLoadingVooChegada] = useState(false);
    const [isSucessoVooChegada, setIsSucessoVooChegada] = useState(false);
    const [isAlertaVooChegada, setIsAlertaVooChegada] = useState(false);
    const [isFalhaVooChegada, setIsFalhaVooChegada] = useState(false);
    const [postCriadoVooChegada, setPostCriadoVooChegada] = useState(false);





    const [isModalOpenTipodeReserva, setIsModalOpenTipodeReserva] = useState(false); //popup de edição do Tipo de Reserva
    const [tipodeReservaNovo, setTipodeReservaNovo] = useState(''); //sim ou nao Tipo de Reserva
    //alertas do Tipo de Reserva
    const [isLoadingTipodeReserva, setIsLoadingTipodeReserva] = useState(false);
    const [isSucessoTipodeReserva, setIsSucessoTipodeReserva] = useState(false);
    const [isAlertaTipodeReserva, setIsAlertaTipodeReserva] = useState(false);
    const [isFalhaTipodeReserva, setIsFalhaTipodeReserva] = useState(false);
    const [postcriadoTipodeReserva, setPostCriadoTipodeReserva] = useState(false);






    const [isModalOpenOrigemdaReserva, setIsModalOpenOrigemdaReserva] = useState(false); //popup de edição do Tipo de Reserva
    const [origemdaReservaNovo, setOrigemdaReservaNovo] = useState(''); //sim ou nao Tipo de Reserva
    //alertas doOrigem da Reserva
    const [isLoadingOrigemdaReserva, setIsLoadingOrigemdaReserva] = useState(false);
    const [isSucessoTOrigemdaReserva, setIsSucessoOrigemdaReserva] = useState(false);
    const [isAlertaOrigemdaReservaa, setIsAlertaOrigemdaReserva] = useState(false);
    const [isFalhaOrigemdaReserva, setIsFalhaOrigemdaReserva] = useState(false);
    const [postcriadoOrigemdaReserva, setPostCriadoOrigemdaReserva] = useState(false);





    const [isModalOpenMarcaEModelo, setIsModalOpenMarcaEModelo] = useState(false); //popup de edição do Tipo de Reserva
    const [marcaNovo, setMarcaNovo] = useState(''); //sim ou nao Tipo de Reserva
    const [modeloNovo, setModeloNovo] = useState(''); //sim ou nao Tipo de Reserva
    //alertas doOrigem da Reserva
    const [isLoadingMarcaEModelo, setIsLoadingMarcaEModelo] = useState(false);
    const [isSucessoTMarcaEModelo, setIsSucessoMarcaEModelo] = useState(false);
    const [isAlertaMarcaEModelo, setIsAlertaMarcaEModelo] = useState(false);
    const [isFalhaMarcaEModelo, setIsFalhaMarcaEModelo] = useState(false);
    const [postcriadoMarcaEModelo, setPostCriadoMarcaEModelo] = useState(false);







    const [alertaMensagem, setAlertaMensagem] = useState("");



    const [dataReserva, setDataReserva] = useState([]);



    // const { error, data, loading, refetch } = useQuery(DADOSPESSOAIS, {
    //     variables: { id },
    // });

    // console.log(id)

    useEffect(() => {
        if (id) {

            let obterdatareservas;
            obterdatareservas = {
                hash: id,
            }

            axios
                .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                .then(response => {
                    const detalhesDaReserva = response.data
                    // console.log(detalhesDaReserva)
                    setDataReserva(detalhesDaReserva)
                })
                .catch(error => {
                    console.error('Erro:', error);
                })

        }

    }, []);



    //variaveis para obter dados
    const idDaReservaPost = dataReserva?.reservaId;
    // console.log(data)
    const dadodareserva = dataReserva;
    // console.log(dadodareserva)
    const fotografiassss = dataReserva?.fotografiascarros;
    const videoooos = dataReserva?.videocarros;
    const localdeestacionamentodata = dataReserva?.localdeestacionamentodaviatura;
    const motivodocancelamentoreserva = dataReserva?.motivoestadodareserva;
    const cancelamentodareserva = dataReserva?.estadodareserva;

    // console.log(videoooos)
    // console.log(fotografiassss)
    // console.log(localdeestacionamentodata)
    // console.log(motivodocancelamentoreserva)
    // console.log(cancelamentodareserva)

    //Faz uma só vez, guardar dentro dos estados os valores da data
    useEffect(() => {
        // setHistoricodeMovimentacoesdataArray(testearray);
        // console.log(dataReserva)
        setLocaldeEstacionamento(dataReserva?.localdeestacionamentodaviatura);
        setAlterarestadodareserva(dataReserva?.estadodareserva)
        setMotivocancelarestadodareserva(dataReserva?.motivoestadodareserva)
        setDataentregaNovo(dataReserva?.dia_de_entrada)
        setDatarecolhaNovo(dataReserva?.dia_de_saida)
        setHoraentregaNovo(dataReserva?.horario_de_entrada)
        setHorarecolhaNovo(dataReserva?.horario_de_saida)
        setMatriculaNovo(dataReserva?.matricula)
        setVooPartidaNovo(dataReserva?.n_voo_partida)
        setVooChegadaNovo(dataReserva?.n_voo_chegada)
        setOrigemdaReservaNovo(dataReserva?.origemdareserva)
        setTipodeReservaNovo(dataReserva?.tipodereserva)
        setModeloNovo(dataReserva?.modelo)
        setMarcaNovo(dataReserva?.marca)
        if (motivodocancelamentoreserva === null) {
            setDesejaeditarmotivo(false)
            setDesejaadicionarmotivo(true)
        } else {
            setDesejaeditarmotivo(true)
            setDesejaadicionarmotivo(false)
        }
    }, [dadodareserva]);




    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);


    const handleBacktoDashboard = () => {
        navigate("/dashboard");
    };


    // if (loading) return <p>espera</p>
    // if (error) return <p>{error.message}</p>;





    // ALTERAR DIA DE ENTREGA
    const handleEditarDiadeEntrega = async () => {
        setIsModalOpenEntrega(true);
    };


    const handleOkEntrega = async () => {

        setPostCriadodiaentrada(true);

        setIsSucessoNovaDataEntrada(false);
        setIsLoadingNovaDataEntrada(true);
        setIsAlertaNovaDataEntradaa(false);
        setIsFalhaNovaDataEntrada(false);

        if (!postcriadodiaentrada) {

            if (dataentreganovo === null || dataentreganovo === "") {
                setIsSucessoNovaDataEntrada(false);
                setIsLoadingNovaDataEntrada(false);
                setIsAlertaNovaDataEntradaa(true);
                setIsFalhaNovaDataEntrada(false);
                return

            } else {

                let enviarnovadataentrega

                enviarnovadataentrega = {
                    dia_de_entrada: dataentreganovo,
                    tipo: "data",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_data_inicio_reserva", enviarnovadataentrega)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoNovaDataEntrada(true);
                            setIsLoadingNovaDataEntrada(false);
                            setIsAlertaNovaDataEntradaa(false);
                            setIsFalhaNovaDataEntrada(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })



                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenEntrega(false);
                                        setPostCriadodiaentrada(false);
                                        setIsSucessoNovaDataEntrada(false);
                                        setIsLoadingNovaDataEntrada(false);
                                        setIsAlertaNovaDataEntradaa(false);
                                        setIsFalhaNovaDataEntrada(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoNovaDataEntrada(false);
                                    setIsLoadingNovaDataEntrada(false);
                                    setIsAlertaNovaDataEntradaa(false);
                                    setIsFalhaNovaDataEntrada(true);
                                    setPostCriadodiaentrada(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoNovaDataEntrada(false);
                            setIsLoadingNovaDataEntrada(false);
                            setIsAlertaNovaDataEntradaa(false);
                            setIsFalhaNovaDataEntrada(true);
                            setPostCriadodiaentrada(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoNovaDataEntrada(false);
                        setIsLoadingNovaDataEntrada(false);
                        setIsAlertaNovaDataEntradaa(false);
                        setIsFalhaNovaDataEntrada(true);
                        setPostCriadodiaentrada(false);

                    })
            }
        }


    };


    const handleCancelEntrega = () => {
        setIsModalOpenEntrega(false);
    };



    // ALTERAR DIA DE RECOLHA
    const handleEditarDiadeRecolha = async () => {
        setIsModalOpenRecolha(true);
    };


    const handleOkRecolha = async () => {

        setPostCriadodiarecolha(true);

        setIsSucessoNovaDataRecolha(false);
        setIsLoadingNovaDataRecolha(true);
        setIsAlertaNovaDataRecolhaa(false);
        setIsFalhaNovaDataRecolha(false);

        if (!postcriadodiarecolha) {

            if (datarecolhanovo === null || datarecolhanovo === "") {

                setIsSucessoNovaDataRecolha(false);
                setIsLoadingNovaDataRecolha(false);
                setIsAlertaNovaDataRecolhaa(true);
                setIsFalhaNovaDataRecolha(false);
                return

            } else {



                let enviarnovadatarecolha

                enviarnovadatarecolha = {
                    dia_de_saida: datarecolhanovo,
                    tipo: "data",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_data_fim_reserva", enviarnovadatarecolha)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoNovaDataRecolha(true);
                            setIsLoadingNovaDataRecolha(false);
                            setIsAlertaNovaDataRecolhaa(false);
                            setIsFalhaNovaDataRecolha(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)
                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenRecolha(false);
                                        setPostCriadodiarecolha(false);
                                        setIsSucessoNovaDataRecolha(false);
                                        setIsLoadingNovaDataRecolha(false);
                                        setIsAlertaNovaDataRecolhaa(false);
                                        setIsFalhaNovaDataRecolha(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoNovaDataRecolha(false);
                                    setIsLoadingNovaDataRecolha(false);
                                    setIsAlertaNovaDataRecolhaa(false);
                                    setIsFalhaNovaDataRecolha(true);
                                    setPostCriadodiarecolha(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoNovaDataRecolha(false);
                            setIsLoadingNovaDataRecolha(false);
                            setIsAlertaNovaDataRecolhaa(false);
                            setIsFalhaNovaDataRecolha(true);
                            setPostCriadodiarecolha(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoNovaDataRecolha(false);
                        setIsLoadingNovaDataRecolha(false);
                        setIsAlertaNovaDataRecolhaa(false);
                        setIsFalhaNovaDataRecolha(true);
                        setPostCriadodiarecolha(false);

                    })
            }
        }

    };


    const handleCancelRecolha = () => {
        setIsModalOpenRecolha(false);
    };





    // ALTERAR HORA DE ENTREGA
    const handleEditarHoradeEntrega = async () => {
        setIsModalOpenHoraEntrega(true);
    };


    const handleOkHoraEntrega = async () => {

        setPostCriadohoraentrada(true);

        setIsSucessoNovaHoraEntrada(false);
        setIsLoadingNovaHoraEntrada(true);
        setIsAlertaNovaHoraEntradaa(false);
        setIsFalhaNovaHoraEntrada(false);


        if (!postcriadohoraentrada) {

            if (horaentreganovo === null || horaentreganovo === "") {
                setIsSucessoNovaHoraEntrada(false);
                setIsLoadingNovaHoraEntrada(false);
                setIsAlertaNovaHoraEntradaa(true);
                setIsFalhaNovaHoraEntrada(false);
                return

            } else {

                let enviarnovahoraentrega

                enviarnovahoraentrega = {
                    horario_de_entrada: horaentreganovo,
                    tipo: "hora",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_data_inicio_reserva", enviarnovahoraentrega)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoNovaHoraEntrada(true);
                            setIsLoadingNovaHoraEntrada(false);
                            setIsAlertaNovaHoraEntradaa(false);
                            setIsFalhaNovaHoraEntrada(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)
                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenHoraEntrega(false);
                                        setPostCriadohoraentrada(false);
                                        setIsSucessoNovaHoraEntrada(false);
                                        setIsLoadingNovaHoraEntrada(false);
                                        setIsAlertaNovaHoraEntradaa(false);
                                        setIsFalhaNovaHoraEntrada(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoNovaHoraEntrada(false);
                                    setIsLoadingNovaHoraEntrada(false);
                                    setIsAlertaNovaHoraEntradaa(false);
                                    setIsFalhaNovaHoraEntrada(true);
                                    setPostCriadohoraentrada(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoNovaHoraEntrada(false);
                            setIsLoadingNovaHoraEntrada(false);
                            setIsAlertaNovaHoraEntradaa(false);
                            setIsFalhaNovaHoraEntrada(true);
                            setPostCriadohoraentrada(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoNovaHoraEntrada(false);
                        setIsLoadingNovaHoraEntrada(false);
                        setIsAlertaNovaHoraEntradaa(false);
                        setIsFalhaNovaHoraEntrada(true);
                        setPostCriadohoraentrada(false);

                    })

            }
        }

    };


    const handleCancelHoraEntrega = () => {
        setIsModalOpenHoraEntrega(false);
    };




    // ALTERAR HORA DE RECOLHA
    const handleEditarHoradeRecolha = async () => {
        setIsModalOpenHoraRecolha(true);
    };


    const handleOkHoraRecolha = async () => {

        setPostCriadohorarecolha(true);

        setIsSucessoNovaHoraRecolha(false);
        setIsLoadingNovaHoraRecolha(true);
        setIsAlertaNovaHoraRecolhaa(false);
        setIsFalhaNovaHoraRecolha(false);

        if (!postcriadohorarecolha) {

            if (horarecolhanovo === null || horarecolhanovo === "") {
                setIsSucessoNovaHoraRecolha(false);
                setIsLoadingNovaHoraRecolha(false);
                setIsAlertaNovaHoraRecolhaa(true);
                setIsFalhaNovaHoraRecolha(false);
                return

            } else {

                let enviarnovahorarecolha

                enviarnovahorarecolha = {
                    horario_de_saida: horarecolhanovo,
                    tipo: "hora",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_data_fim_reserva", enviarnovahorarecolha)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoNovaHoraRecolha(true);
                            setIsLoadingNovaHoraRecolha(false);
                            setIsAlertaNovaHoraRecolhaa(false);
                            setIsFalhaNovaHoraRecolha(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)


                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })

                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);

                                    setTimeout(() => {
                                        setIsModalOpenHoraRecolha(false);
                                        setPostCriadohorarecolha(false);
                                        setIsSucessoNovaHoraRecolha(false);
                                        setIsLoadingNovaHoraRecolha(false);
                                        setIsAlertaNovaHoraRecolhaa(false);
                                        setIsFalhaNovaHoraRecolha(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoNovaHoraRecolha(false);
                                    setIsLoadingNovaHoraRecolha(false);
                                    setIsAlertaNovaHoraRecolhaa(false);
                                    setIsFalhaNovaHoraRecolha(true);
                                    setPostCriadohorarecolha(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoNovaHoraRecolha(false);
                            setIsLoadingNovaHoraRecolha(false);
                            setIsAlertaNovaHoraRecolhaa(false);
                            setIsFalhaNovaHoraRecolha(true);
                            setPostCriadohorarecolha(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoNovaHoraRecolha(false);
                        setIsLoadingNovaHoraRecolha(false);
                        setIsAlertaNovaHoraRecolhaa(false);
                        setIsFalhaNovaHoraRecolha(true);
                        setPostCriadohorarecolha(false);

                    })
            }
        }


    };


    const handleCancelHoraRecolha = () => {
        setIsModalOpenHoraRecolha(false);
    };








    // ALTERAR VALET DE ENTREGA
    const handleEditarValetdeEntrega = async () => {
        setIsModalOpenValetEntrega(true);
    };

    // console.log(valetentreganovo)

    const handleOkValetEntrega = async () => {

        setPostCriadoValetEntrada(true);

        setIsSucessoValetEntrada(false);
        setIsLoadingValetEntrada(true);
        setIsAlertaValetEntrada(false);
        setIsFalhaValetEntrada(false);

        if (!postcriadovaletentrada) {

            if (valetentreganovo === null || valetentreganovo === "" || valetentreganovo === false) {
                setIsSucessoValetEntrada(false);
                setIsLoadingValetEntrada(false);
                setIsAlertaValetEntrada(true);
                setIsFalhaValetEntrada(false);
                setPostCriadoValetEntrada(false);
                return

            } else {

                let enviarnovovaletentrega

                // console.log(valetentreganovo)

                enviarnovovaletentrega = {
                    valet_service: valetentreganovo,
                    tipo: "entrada",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_valet", enviarnovovaletentrega)
                    .then(response => {
                        // console.log('response', response)
                        const modeValidation = response.data.validation



                        if (modeValidation === "valid") {
                            // console.log('modeValidation', modeValidation)
                            setIsSucessoValetEntrada(true);
                            setIsLoadingValetEntrada(false);
                            setIsAlertaValetEntrada(false);
                            setIsFalhaValetEntrada(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {

                                    // console.log(response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenValetEntrega(false);
                                        setPostCriadoValetEntrada(false);
                                        setIsSucessoValetEntrada(false);
                                        setIsLoadingValetEntrada(false);
                                        setIsAlertaValetEntrada(false);
                                        setIsFalhaValetEntrada(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoValetEntrada(false);
                                    setIsLoadingValetEntrada(false);
                                    setIsAlertaValetEntrada(false);
                                    setIsFalhaValetEntrada(true);
                                    setPostCriadoValetEntrada(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoValetEntrada(false);
                            setIsLoadingValetEntrada(false);
                            setIsAlertaValetEntrada(false);
                            setIsFalhaValetEntrada(false);
                            setPostCriadoValetEntrada(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoValetEntrada(false);
                        setIsLoadingValetEntrada(false);
                        setIsAlertaValetEntrada(false);
                        setIsFalhaValetEntrada(false);
                        setPostCriadoValetEntrada(false);
                    })
            }
        }


    };


    const handleCancelValetEntrega = () => {
        setIsModalOpenValetEntrega(false);
    };




    // ALTERAR VALET DE RECOLHA
    const handleEditarValetRecolha = async () => {
        setIsModalOpenValetRecolha(true);
    };


    const handleOkValetRecolha = async () => {

        setPostCriadoValetRecolha(true);

        setIsSucessoValetRecolha(false);
        setIsLoadingValetRecolha(true);
        setIsAlertaValetRecolha(false);
        setIsFalhaValetRecolha(false);

        if (!postcriadovaletrecolha) {

            if (valetrecolhanovo === false || valetrecolhanovo === "" || valetrecolhanovo === null) {
                setIsSucessoValetRecolha(false);
                setIsLoadingValetRecolha(false);
                setIsAlertaValetRecolha(true);
                setIsFalhaValetRecolha(false);
                setPostCriadoValetRecolha(false);
                return

            } else {

                let enviarnovovaletrecolha

                enviarnovovaletrecolha = {
                    valet_service: valetrecolhanovo,
                    tipo: "saida",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_valet", enviarnovovaletrecolha)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoValetRecolha(true);
                            setIsLoadingValetRecolha(false);
                            setIsAlertaValetRecolha(false);
                            setIsFalhaValetRecolha(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenValetRecolha(false);
                                        setPostCriadoValetRecolha(false);
                                        setIsSucessoValetRecolha(false);
                                        setIsLoadingValetRecolha(false);
                                        setIsAlertaValetRecolha(false);
                                        setIsFalhaValetRecolha(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoValetRecolha(false);
                                    setIsLoadingValetRecolha(false);
                                    setIsAlertaValetRecolha(false);
                                    setIsFalhaValetRecolha(true);
                                    setPostCriadoValetRecolha(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoValetRecolha(false);
                            setIsLoadingValetRecolha(false);
                            setIsAlertaValetRecolha(false);
                            setIsFalhaValetRecolha(false);
                            setPostCriadoValetRecolha(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoValetRecolha(false);
                        setIsLoadingValetRecolha(false);
                        setIsAlertaValetRecolha(false);
                        setIsFalhaValetRecolha(false);
                        setPostCriadoValetRecolha(false);
                    })
            }
        }


    };


    const handleCancelValetRecolha = () => {
        setIsModalOpenValetRecolha(false);
    };






    // ALTERAR TRANSFER DE ENTREGA
    const handleEditarTransferdeEntrega = async () => {
        setIsModalOpenTransferEntrada(true);
    };



    const handleOkTransferEntrega = async () => {

        setPostCriadoTransferEntrada(true);

        setIsSucessoTransferEntrada(false);
        setIsLoadingTransferEntrada(true);
        setIsAlertaTransferEntrada(false);
        setIsFalhaTransferEntrada(false);


        if (!postcriadotransferentrada) {

            if (transferpessoasentreganovo === null || transferpessoasentreganovo === "") {
                setIsSucessoTransferEntrada(false);
                setIsLoadingTransferEntrada(false);
                setIsAlertaTransferEntrada(true);
                setIsFalhaTransferEntrada(false);
                setPostCriadoTransferEntrada(false);
                setAlertaMensagem("preencha os campos.");
                return
            } else if (transferentreganovo == 0 && transferpessoasentreganovo > 0) {
                setIsSucessoTransferEntrada(false);
                setIsLoadingTransferEntrada(false);
                setIsAlertaTransferEntrada(true);
                setIsFalhaTransferEntrada(false);
                setPostCriadoTransferEntrada(false);
                setAlertaMensagem("o tranfer tem número de pessoas.");
                return

            } else if (transferentreganovo == 1 && transferpessoasentreganovo == 0) {
                setIsSucessoTransferEntrada(false);
                setIsLoadingTransferEntrada(false);
                setIsAlertaTransferEntrada(true);
                setIsFalhaTransferEntrada(false);
                setPostCriadoTransferEntrada(false);
                setAlertaMensagem("o tranfer tem 0 pessoas.");
                return

            } else {

                let enviarnovotransferentrega

                enviarnovotransferentrega = {
                    transfer: transferpessoasentreganovo,
                    tipo: "entrada",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_transfer", enviarnovotransferentrega)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoTransferEntrada(true);
                            setIsLoadingTransferEntrada(false);
                            setIsAlertaTransferEntrada(false);
                            setIsFalhaTransferEntrada(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    // console.log(response)
                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenTransferEntrada(false);
                                        setPostCriadoTransferEntrada(false);
                                        setIsSucessoTransferEntrada(false);
                                        setIsLoadingTransferEntrada(false);
                                        setIsAlertaTransferEntrada(false);
                                        setIsFalhaTransferEntrada(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoTransferEntrada(false);
                                    setIsLoadingTransferEntrada(false);
                                    setIsAlertaTransferEntrada(false);
                                    setIsFalhaTransferEntrada(true);
                                    setPostCriadoTransferEntrada(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoTransferEntrada(false);
                            setIsLoadingTransferEntrada(false);
                            setIsAlertaTransferEntrada(false);
                            setIsFalhaTransferEntrada(true);
                            setPostCriadoTransferEntrada(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoTransferEntrada(false);
                        setIsLoadingTransferEntrada(false);
                        setIsAlertaTransferEntrada(false);
                        setIsFalhaTransferEntrada(true);
                        setPostCriadoTransferEntrada(false);
                    })
            }
        }


    };


    const handleCancelTransferEntrega = () => {
        setIsModalOpenTransferEntrada(false);
    };




    // ALTERAR TRANSFER DE RECOLHA
    const handleEditarTransferdeRecolha = async () => {
        setIsModalOpenTransferRecolha(true);
    };


    const handleOkTransferRecolha = async () => {

        setPostCriadoTransferRecolha(true);

        setIsSucessoTransferRecolha(false);
        setIsLoadingTransferRecolha(true);
        setIsAlertaTransferRecolha(false);
        setIsFalhaTransferRecolha(false);


        if (!postcriadotransferrecolha) {

            if (transferpessoasrecolhanovo === null || transferpessoasrecolhanovo === "") {
                setIsSucessoTransferRecolha(false);
                setIsLoadingTransferRecolha(false);
                setIsAlertaTransferRecolha(true);
                setIsFalhaTransferRecolha(false);
                setPostCriadoTransferRecolha(false);
                setAlertaMensagem("preencha os campos.");
                return

            } else if (transferrecolhanovo == 0 && transferpessoasrecolhanovo > 0) {
                setIsSucessoTransferRecolha(false);
                setIsLoadingTransferRecolha(false);
                setIsAlertaTransferRecolha(true);
                setIsFalhaTransferRecolha(false);
                setPostCriadoTransferRecolha(false);
                setAlertaMensagem("o tranfer tem número de pessoas.");
                return

            } else if (transferrecolhanovo == 1 && transferpessoasrecolhanovo == 0) {
                setIsSucessoTransferRecolha(false);
                setIsLoadingTransferRecolha(false);
                setIsAlertaTransferRecolha(true);
                setIsFalhaTransferRecolha(false);
                setPostCriadoTransferRecolha(false);
                setAlertaMensagem("o tranfer tem 0 pessoas.");
                return

            } else {

                let enviarnovotransferrecolha

                enviarnovotransferrecolha = {
                    transfer: transferpessoasrecolhanovo,
                    tipo: "saida",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_transfer", enviarnovotransferrecolha)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoTransferRecolha(true);
                            setIsLoadingTransferRecolha(false);
                            setIsAlertaTransferRecolha(false);
                            setIsFalhaTransferRecolha(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenTransferRecolha(false);
                                        setPostCriadoTransferRecolha(false);
                                        setIsSucessoTransferRecolha(false);
                                        setIsLoadingTransferRecolha(false);
                                        setIsAlertaTransferRecolha(false);
                                        setIsFalhaTransferRecolha(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoTransferRecolha(false);
                                    setIsLoadingTransferRecolha(false);
                                    setIsAlertaTransferRecolha(false);
                                    setIsFalhaTransferRecolha(true);
                                    setPostCriadoTransferRecolha(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoTransferRecolha(false);
                            setIsLoadingTransferRecolha(false);
                            setIsAlertaTransferRecolha(false);
                            setIsFalhaTransferRecolha(true);
                            setPostCriadoTransferRecolha(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoTransferRecolha(false);
                        setIsLoadingTransferRecolha(false);
                        setIsAlertaTransferRecolha(false);
                        setIsFalhaTransferRecolha(true);
                        setPostCriadoTransferRecolha(false);
                    })
            }
        }


    };


    const handleCancelTransferRecolha = () => {
        setIsModalOpenTransferRecolha(false);
    };








    // ALTERAR TRANSFER DE ENTREGA
    const handleEditarMatricula = async () => {
        setIsModalOpenMatricula(true);
    };


    // console.log('matriculaNovo',matriculaNovo)

    const handleOkMatricula = async () => {

        setPostCriadoMatricula(true);

        setIsSucessoMatricula(false);
        setIsLoadingMatricula(true);
        setIsAlertaMatricula(false);
        setIsFalhaMatricula(false);


        if (!postPriadoMatricula) {

            if (matriculaNovo === null || matriculaNovo === "") {
                setIsSucessoMatricula(false);
                setIsLoadingMatricula(false);
                setIsAlertaMatricula(true);
                setIsFalhaMatricula(false);
                setPostCriadoMatricula(false);
                return

            } else {

                let enviarnovamatricula

                enviarnovamatricula = {
                    campo: "matricula",
                    input1: matriculaNovo,
                    input2: "",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_info_cliente", enviarnovamatricula)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoMatricula(true);
                            setIsLoadingMatricula(false);
                            setIsAlertaMatricula(false);
                            setIsFalhaMatricula(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {


                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenMatricula(false);
                                        setPostCriadoMatricula(false);
                                        setIsSucessoMatricula(false);
                                        setIsLoadingMatricula(false);
                                        setIsAlertaMatricula(false);
                                        setIsFalhaMatricula(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoMatricula(false);
                                    setIsLoadingMatricula(false);
                                    setIsAlertaMatricula(false);
                                    setIsFalhaMatricula(true);
                                    setPostCriadoMatricula(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoMatricula(false);
                            setIsLoadingMatricula(false);
                            setIsAlertaMatricula(false);
                            setIsFalhaMatricula(true);
                            setPostCriadoMatricula(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoMatricula(false);
                        setIsLoadingMatricula(false);
                        setIsAlertaMatricula(false);
                        setIsFalhaMatricula(true);
                        setPostCriadoMatricula(false);
                    })
            }
        }


    };


    const handleCancelMatricula = () => {
        setIsModalOpenMatricula(false);
    };












    // ALTERAR VOO PARTIDA
    const handleEditarVooPartida = async () => {
        setIsModalOpenVooPartida(true);
    };


    // console.log('matriculaNovo',matriculaNovo)

    const handleOkVooPartida = async () => {

        setPostCriadoVooPartida(true);

        setIsSucessoVooPartida(false);
        setIsLoadingVooPartida(true);
        setIsAlertaVooPartida(false);
        setIsFalhaVooPartida(false);


        if (!postPriadoVooPartida) {

            if (vooPartidaNovo === null || vooPartidaNovo === "") {
                setIsSucessoVooPartida(false);
                setIsLoadingVooPartida(false);
                setIsAlertaVooPartida(true);
                setIsFalhaVooPartida(false);
                setPostCriadoVooPartida(false);
                return

            } else {

                let enviarnovovoopartida

                enviarnovovoopartida = {
                    campo: "n_voo_partida",
                    input1: vooPartidaNovo,
                    input2: "",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_info_cliente", enviarnovovoopartida)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoVooPartida(true);
                            setIsLoadingVooPartida(false);
                            setIsAlertaVooPartida(false);
                            setIsFalhaVooPartida(false);
                            // console.log(idDaReservaPost)
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {

                                    // console.log(id)
                                    // console.log('response', response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }


                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenVooPartida(false);
                                        setPostCriadoVooPartida(false);
                                        setIsSucessoVooPartida(false);
                                        setIsLoadingVooPartida(false);
                                        setIsAlertaVooPartida(false);
                                        setIsFalhaVooPartida(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoVooPartida(false);
                                    setIsLoadingVooPartida(false);
                                    setIsAlertaVooPartida(false);
                                    setIsFalhaVooPartida(true);
                                    setPostCriadoVooPartida(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoVooPartida(false);
                            setIsLoadingVooPartida(false);
                            setIsAlertaVooPartida(false);
                            setIsFalhaVooPartida(true);
                            setPostCriadoVooPartida(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoVooPartida(false);
                        setIsLoadingVooPartida(false);
                        setIsAlertaVooPartida(false);
                        setIsFalhaVooPartida(true);
                        setPostCriadoVooPartida(false);
                    })
            }
        }


    };


    const handleCancelVooPartida = () => {
        setIsModalOpenVooPartida(false);
    };












    // ALTERAR VOO PARTIDA
    const handleEditarVooChegada = async () => {
        setIsModalOpenVooChegada(true);
    };


    // console.log('matriculaNovo',matriculaNovo)

    const handleOkVooChegada = async () => {

        setPostCriadoVooChegada(true);

        setIsSucessoVooChegada(false);
        setIsLoadingVooChegada(true);
        setIsAlertaVooChegada(false);
        setIsFalhaVooChegada(false);


        if (!postCriadoVooChegada) {

            if (vooChegadaNovo === null || vooChegadaNovo === "") {
                setIsSucessoVooChegada(false);
                setIsLoadingVooChegada(false);
                setIsAlertaVooChegada(true);
                setIsFalhaVooChegada(false);
                setPostCriadoVooChegada(false);
                return

            } else {

                let enviarnovovoochegada

                enviarnovovoochegada = {
                    campo: "n_voo_chegada",
                    input1: vooChegadaNovo,
                    input2: "",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_info_cliente", enviarnovovoochegada)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoVooChegada(true);
                            setIsLoadingVooChegada(false);
                            setIsAlertaVooChegada(false);
                            setIsFalhaVooChegada(false);
                            // console.log(idDaReservaPost)
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {

                                    // console.log(id)
                                    // console.log('response', response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }


                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenVooChegada(false);
                                        setPostCriadoVooChegada(false);
                                        setIsSucessoVooChegada(false);
                                        setIsLoadingVooChegada(false);
                                        setIsAlertaVooChegada(false);
                                        setIsFalhaVooChegada(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoVooChegada(false);
                                    setIsLoadingVooChegada(false);
                                    setIsAlertaVooChegada(false);
                                    setIsFalhaVooChegada(true);
                                    setPostCriadoVooChegada(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoVooChegada(false);
                            setIsLoadingVooChegada(false);
                            setIsAlertaVooChegada(false);
                            setIsFalhaVooChegada(true);
                            setPostCriadoVooChegada(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoVooChegada(false);
                        setIsLoadingVooChegada(false);
                        setIsAlertaVooChegada(false);
                        setIsFalhaVooChegada(true);
                        setPostCriadoVooChegada(false);
                    })
            }
        }


    };


    const handleCancelVooChegada = () => {
        setIsModalOpenVooChegada(false);
    };









    const handleEditarTipodeReserva = async () => {
        setIsModalOpenTipodeReserva(true);
    };


    const handleOkTipodeReserva = async () => {

        setPostCriadoTipodeReserva(true);

        setIsSucessoTipodeReserva(false);
        setIsLoadingTipodeReserva(true);
        setIsAlertaTipodeReserva(false);
        setIsFalhaTipodeReserva(false);

        if (!postcriadoTipodeReserva) {

            if (tipodeReservaNovo === false || tipodeReservaNovo === "" || tipodeReservaNovo === null) {
                setIsSucessoTipodeReserva(false);
                setIsLoadingTipodeReserva(false);
                setIsAlertaTipodeReserva(true);
                setIsFalhaTipodeReserva(false);
                setPostCriadoTipodeReserva(false);
                return

            } else {

                let enviarnovoTipodeReserva

                enviarnovoTipodeReserva = {
                    campo: "tipodereserva",
                    input1: tipodeReservaNovo,
                    nome_campo: "Tipo de Reserva",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                // console.log('idDaReservaPost',idDaReservaPost)

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_info_reserva", enviarnovoTipodeReserva)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoTipodeReserva(true);
                            setIsLoadingTipodeReserva(false);
                            setIsAlertaTipodeReserva(false);
                            setIsFalhaTipodeReserva(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log('responseresponse',response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenTipodeReserva(false);
                                        setPostCriadoTipodeReserva(false);
                                        setIsSucessoTipodeReserva(false);
                                        setIsLoadingTipodeReserva(false);
                                        setIsAlertaTipodeReserva(false);
                                        setIsFalhaTipodeReserva(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoTipodeReserva(false);
                                    setIsLoadingTipodeReserva(false);
                                    setIsAlertaTipodeReserva(false);
                                    setIsFalhaTipodeReserva(true);
                                    setPostCriadoTipodeReserva(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoTipodeReserva(false);
                            setIsLoadingTipodeReserva(false);
                            setIsAlertaTipodeReserva(false);
                            setIsFalhaTipodeReserva(false);
                            setPostCriadoTipodeReserva(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoTipodeReserva(false);
                        setIsLoadingTipodeReserva(false);
                        setIsAlertaTipodeReserva(false);
                        setIsFalhaTipodeReserva(false);
                        setPostCriadoTipodeReserva(false);
                    })
            }
        }


    };


    const handleCancelTipodeReserva = () => {
        setIsModalOpenTipodeReserva(false);
    };












    const handleEditarOrigemdaReserva = async () => {
        setIsModalOpenOrigemdaReserva(true);
    };


    const handleOkOrigemdaReserva = async () => {

        setPostCriadoOrigemdaReserva(true);

        setIsSucessoOrigemdaReserva(false);
        setIsLoadingOrigemdaReserva(true);
        setIsAlertaOrigemdaReserva(false);
        setIsFalhaOrigemdaReserva(false);

        if (!postcriadoOrigemdaReserva) {
            //AQUI
            if (origemdaReservaNovo === false || origemdaReservaNovo === "" || origemdaReservaNovo === null) {
                setIsSucessoOrigemdaReserva(false);
                setIsLoadingOrigemdaReserva(false);
                setIsAlertaOrigemdaReserva(true);
                setIsFalhaOrigemdaReserva(false);
                setPostCriadoOrigemdaReserva(false);
                return

            } else {

                let enviarnovoOrigemdaReserva

                enviarnovoOrigemdaReserva = {
                    campo: "origemdareserva",
                    input1: origemdaReservaNovo,
                    nome_campo: "Origem da Reserva",
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_info_reserva", enviarnovoOrigemdaReserva)
                    .then(response => {

                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoOrigemdaReserva(true);
                            setIsLoadingOrigemdaReserva(false);
                            setIsAlertaOrigemdaReserva(false);
                            setIsFalhaOrigemdaReserva(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenOrigemdaReserva(false);
                                        setPostCriadoOrigemdaReserva(false);
                                        setIsSucessoOrigemdaReserva(false);
                                        setIsLoadingOrigemdaReserva(false);
                                        setIsAlertaOrigemdaReserva(false);
                                        setIsFalhaOrigemdaReserva(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoOrigemdaReserva(false);
                                    setIsLoadingOrigemdaReserva(false);
                                    setIsAlertaOrigemdaReserva(false);
                                    setIsFalhaOrigemdaReserva(true);
                                    setPostCriadoOrigemdaReserva(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoOrigemdaReserva(false);
                            setIsLoadingOrigemdaReserva(false);
                            setIsAlertaOrigemdaReserva(false);
                            setIsFalhaOrigemdaReserva(false);
                            setPostCriadoOrigemdaReserva(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoOrigemdaReserva(false);
                        setIsLoadingOrigemdaReserva(false);
                        setIsAlertaOrigemdaReserva(false);
                        setIsFalhaOrigemdaReserva(false);
                        setPostCriadoOrigemdaReserva(false);
                    })
            }
        }


    };


    const handleCancelOrigemdaReserva = () => {
        setIsModalOpenOrigemdaReserva(false);
    };













    const handleEditarMarcaEModelo = async () => {
        setIsModalOpenMarcaEModelo(true);
    };


    const handleOkMarcaEModelo = async () => {

        setPostCriadoMarcaEModelo(true);

        setIsSucessoMarcaEModelo(false);
        setIsLoadingMarcaEModelo(true);
        setIsAlertaMarcaEModelo(false);
        setIsFalhaMarcaEModelo(false);

        if (!postcriadoMarcaEModelo) {
            //AQUI
            if (marcaNovo === false || modeloNovo === false || marcaNovo === "" || modeloNovo === "" ||  marcaNovo === null ||   modeloNovo === null) {
                setIsSucessoMarcaEModelo(false);
                setIsLoadingMarcaEModelo(false);
                setIsAlertaMarcaEModelo(true);
                setIsFalhaMarcaEModelo(false);
                setPostCriadoMarcaEModelo(false);
                return

            } else {

                // console.log('correu')
                let enviarnovoMarcaEModelo

                enviarnovoMarcaEModelo = {
                    campo: "Marca e Modelo",
                    input1: marcaNovo,
                    input2: modeloNovo,
                    id_reserva: idDaReservaPost,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }

                axios
                    .post("https://app.indoorparkinglowcost.pt/wp-json/api/update_info_cliente", enviarnovoMarcaEModelo)
                    .then(response => {
                        // console.log(response)
                        const modeValidation = response.data.validation;

                        if (modeValidation === "valid") {
                            setIsSucessoMarcaEModelo(true);
                            setIsLoadingMarcaEModelo(false);
                            setIsAlertaMarcaEModelo(false);
                            setIsFalhaMarcaEModelo(false);
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)

                                    let obterdatareservas;
                                    obterdatareservas = {
                                        hash: id,
                                    }

                                    axios
                                        .post('https://app.indoorparkinglowcost.pt/wp-json/api/get_dados_reserva/', obterdatareservas)
                                        .then(response => {
                                            const detalhesDaReserva = response.data
                                            // console.log(detalhesDaReserva)
                                            setDataReserva(detalhesDaReserva)
                                        })
                                        .catch(error => {
                                            console.error('Erro:', error);
                                        })


                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                    setTimeout(() => {
                                        setIsModalOpenMarcaEModelo(false);
                                        setPostCriadoMarcaEModelo(false);
                                        setIsSucessoMarcaEModelo(false);
                                        setIsLoadingMarcaEModelo(false);
                                        setIsAlertaMarcaEModelo(false);
                                        setIsFalhaMarcaEModelo(false);
                                    }, 1000);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                    setIsSucessoMarcaEModelo(false);
                                    setIsLoadingMarcaEModelo(false);
                                    setIsAlertaMarcaEModelo(false);
                                    setIsFalhaMarcaEModelo(true);
                                    setPostCriadoMarcaEModelo(false);
                                });

                            // refetch()

                        } else {
                            console.error('Erro:');
                            setIsSucessoMarcaEModelo(false);
                            setIsLoadingMarcaEModelo(false);
                            setIsAlertaMarcaEModelo(false);
                            setIsFalhaMarcaEModelo(false);
                            setPostCriadoMarcaEModelo(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoMarcaEModelo(false);
                        setIsLoadingMarcaEModelo(false);
                        setIsAlertaMarcaEModelo(false);
                        setIsFalhaMarcaEModelo(false);
                        setPostCriadoMarcaEModelo(false);
                    })
            }
        }


    };


    const handleCancelMarcaEModelo = () => {
        setIsModalOpenMarcaEModelo(false);
    };


    const handleSearch = (value) => {
        setMarcaNovo(value);
    };

    const handleSelect = (value) => {
        setMarcaNovo(value);
    };

    // console.log(carrosModelos)
    const filteredOptions = carrosModelos?.filter(modelo =>
        modelo.toLowerCase().includes(marcaNovo?.toLowerCase())
    );


























    // console.log(dadodareserva)


    return (
        <div className="formulario-de-reservas">
            <div className="form main resumo-pedido-page">

                {isLoading ? (
                    <div className="loading-normal">
                        <Spin indicator={antIcon} />
                    </div>
                ) : (
                    <>
                        <div>
                            <Button type="primary" onClick={handleBacktoDashboard} className="back-form-button">
                                <MdKeyboardBackspace style={{ marginRight: '8px' }} /> Voltar
                            </Button>
                        </div>

                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                            <>
                                <AlterarEstadoDaReserva
                                    // refetch={refetch}
                                    idDaReservaPost={idDaReservaPost}
                                    alterarestadodareserva={alterarestadodareserva}
                                    setAlterarestadodareserva={setAlterarestadodareserva}
                                    motivocancelarestadodareserva={motivocancelarestadodareserva}
                                    setMotivocancelarestadodareserva={setMotivocancelarestadodareserva}
                                    setDesejaadicionarmotivo={setDesejaadicionarmotivo}
                                    desejaadicionarmotivo={desejaadicionarmotivo}
                                    setDesejaeditarmotivo={setDesejaeditarmotivo}
                                    desejaeditarmotivo={desejaeditarmotivo}
                                    userMain={userMain}
                                    setDataReserva={setDataReserva}
                                    setLogsData={setLogsData}
                                    extraDataServicosReserva={extraDataServicosReserva}
                                    id={id}
                                />

                            </>
                        ) : null}


                        {dadodareserva ? (
                            <div className="sec-formulario-registos" >
                                <div>
                                    <h2>Informações da Reserva:</h2>
                                    <br />
                                    <div className="informacoesdareserva-num-estado">
                                        {dadodareserva && (
                                            <span className="label-do-campor">Nº da Reserva: <b>#{idDaReservaPost}</b></span>
                                        )}
                                        {dadodareserva && (
                                            <span className="label-do-campor">Estado da Reserva: <b>{dadodareserva.estadodareserva}</b></span>
                                        )}
                                        {dadodareserva && (
                                            <span className="label-do-campor">Valor total: <b>{dadodareserva.preco_total_a_pagar} €</b></span>
                                        )}
                                        {dadodareserva && (
                                            // <span className="label-do-campor">Origem da Reserva: <b>{dadodareserva.origemdareserva}</b></span>
                                            <>
                                                <Modal title="Alterar Origem da Reserva" open={isModalOpenOrigemdaReserva} onOk={handleOkOrigemdaReserva} onCancel={handleCancelOrigemdaReserva} okText="Confirmar" cancelText="Cancelar">
                                                    <div className="modal-com-flex">
                                                        <div>
                                                            <Select
                                                                id="estados"
                                                                value={origemdaReservaNovo}
                                                                onChange={(value) => setOrigemdaReservaNovo(value)}
                                                                style={{
                                                                    marginLeft: '0px',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Option value="Plataformas">Plataformas</Option>
                                                                <Option value="Online">Online</Option>
                                                                <Option value="Telefone">Telefone</Option>
                                                                <Option value="Agências Viagens">Agências Viagens</Option>
                                                                <Option value="Aparcália">Aparcália</Option>
                                                                <Option value="Barbosa">Barbosa</Option>
                                                                <Option value="Email">Email</Option>
                                                                <Option value="Looking4 Parking">Looking4 Parking</Option>
                                                                <Option value="Pinheiro">Pinheiro</Option>
                                                                <Option value="Parque">Parque</Option>

                                                            </Select>
                                                        </div>
                                                        {isLoadingOrigemdaReserva ? (
                                                            <LoadingMsg mensagem="Aguarde..." />
                                                        ) : isAlertaOrigemdaReservaa ? (
                                                            <AlertaMsg mensagem="seleciona um campo." />
                                                        ) : isFalhaOrigemdaReserva ? (
                                                            <ErroMsg mensagem="tente novamente." />
                                                        ) : isSucessoTOrigemdaReserva ? (
                                                            <SucessoMsg mensagem="Orgiem da reserva alterada!" />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Modal>
                                                <div className="editar-dias-reserva">
                                                    <span className="label-do-campor">Origem da Reserva: <b>{dadodareserva.origemdareserva}</b></span>
                                                    {cancelamentodareserva === "Cancelado" ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                                <>
                                                                    <span className="icon-editar-dias-reserva" onClick={handleEditarOrigemdaReserva}><PiPencilBold /></span>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        {dadodareserva && (
                                            <>
                                                <Modal title="Alterar Tipo de Reserva" open={isModalOpenTipodeReserva} onOk={handleOkTipodeReserva} onCancel={handleCancelTipodeReserva} okText="Confirmar" cancelText="Cancelar">
                                                    <div className="modal-com-flex">
                                                        <div>
                                                            <Select
                                                                id="estados"
                                                                value={tipodeReservaNovo}
                                                                onChange={(value) => setTipodeReservaNovo(value)}
                                                                style={{
                                                                    marginLeft: '0px',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Option value="Normal">Normal</Option>
                                                                <Option value="Avença">Avença</Option>
                                                            </Select>
                                                        </div>
                                                        {isLoadingTipodeReserva ? (
                                                            <LoadingMsg mensagem="Aguarde..." />
                                                        ) : isAlertaTipodeReserva ? (
                                                            <AlertaMsg mensagem="seleciona um campo." />
                                                        ) : isFalhaTipodeReserva ? (
                                                            <ErroMsg mensagem="tente novamente." />
                                                        ) : isSucessoTipodeReserva ? (
                                                            <SucessoMsg mensagem="Tipo de Reserva alterada!" />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </Modal>
                                                <div className="editar-dias-reserva">
                                                    <span className="label-do-campor">Tipo de Reserva: <b>{dadodareserva.tipodereserva}</b></span>
                                                    {cancelamentodareserva === "Cancelado" ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                                <>
                                                                    <span className="icon-editar-dias-reserva" onClick={handleEditarTipodeReserva}><PiPencilBold /></span>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <br />
                                    <br />
                                    <div className="flex-resumo-page">
                                        <div className="flex-resumo-page-col">
                                            <a className="label-do-campo">Dados da Reserva</a>
                                            <hr className="linha-principal" />
                                            {/* <span><b>Data da Reserva:</b> {dadodareserva?.date.slice(0, 10) !== "" ? dadodareserva?.date.slice(0, 10) : "Sem informação"}</span> */}
                                            <span><b>Parque:</b> {dadodareserva.espacoparque !== "" ? dadodareserva.espacoparque : "Sem informação"}</span>
                                            {/* <span><b>Estado:</b> {estadopagamento !== "" ? estadopagamento : "Sem informação"}</span> */}
                                            {dadodareserva.estadodopagamento === 'Pago' ? (
                                                <span><b>Estado de Pagamento:</b> <span className="reserva-paga">Pago</span></span>
                                            ) : dadodareserva.estadodopagamento === 'Pago Saída' ? (
                                                <span><b>Estado de Pagamento:</b> <span className="reserva-paga">Pago Saída</span></span>
                                            ) : dadodareserva.estadodopagamento === 'Aguardar Pagamento' ? (
                                                    <span><b>Estado de Pagamento:</b> <span className="reserva-aguarda-paga">Aguardar Pagamento</span></span>
                                            ) : (
                                                <span><b>Estado de Pagamento:</b> <span className="reserva-nao-paga">Não Pago</span></span>
                                            )}
                                            <span><b>Data de Pagamento:</b> {dadodareserva.datadopagamento !== null ? dadodareserva.datadopagamento : "Sem informação..."}</span>
                                            <span><b>Meio de Pagamento:</b> {dadodareserva.meiodepagamento !== null ? dadodareserva.meiodepagamento : "Sem informação..."}</span>
                                        </div>
                                        <div className="flex-resumo-page-col">
                                            <a className="label-do-campo">Entrada</a>
                                            <hr className="linha-principal" />
                                            <div className="editar-dias-reserva">
                                                <span><b>Data de Entrega:</b> {dadodareserva.dia_de_entrada !== "" ? dadodareserva.dia_de_entrada : "Sem informação"}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarDiadeEntrega}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Dia de Entrega" open={isModalOpenEntrega} onOk={handleOkEntrega} onCancel={handleCancelEntrega} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="text"
                                                                className={`input-calendario`}
                                                                placeholder="dd/mm/aaaa"
                                                                value={dataentreganovo}
                                                                onChange={(e) => setDataentregaNovo(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <span>Formato: dd-mm-aaaa</span>
                                                    </div>
                                                    {isLoadingNovaDataEntrada ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaNovaDataEntrada ? (
                                                        <AlertaMsg mensagem="escreva uma data com o formato dd-mm-aaaa" />
                                                    ) : isFalhaNovaDataEntrada ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoNovaDataEntrada ? (
                                                        <SucessoMsg mensagem="data de entrega alterada!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>
                                            <div className="editar-dias-reserva">
                                                <span><b>Hora de Entrega:</b> {dadodareserva.horario_de_entrada}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarHoradeEntrega}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Hora de Entrega" open={isModalOpenHoraEntrega} onOk={handleOkHoraEntrega} onCancel={handleCancelHoraEntrega} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="text"
                                                                className={`input-calendario`}
                                                                placeholder="hh:mm"
                                                                value={horaentreganovo}
                                                                onChange={(e) => setHoraentregaNovo(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <span>Formato: hh:mm</span>
                                                    </div>
                                                    {isLoadingNovaHoraEntrada ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaNovaHoraEntrada ? (
                                                        <AlertaMsg mensagem="escreva uma hora com o formato hh:mm" />
                                                    ) : isFalhaNovaHoraEntrada ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoNovaHoraEntrada ? (
                                                        <SucessoMsg mensagem="hora de entrega alterada!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>
                                            <div className="editar-dias-reserva">
                                                <span><b>Valet: </b>{dadodareserva.valet_service_entrada === false ? "Não" : "Sim"}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarValetdeEntrega}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Valet de Entrada" open={isModalOpenValetEntrega} onOk={handleOkValetEntrega} onCancel={handleCancelValetEntrega} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <Select
                                                            id="estados"
                                                            value={valetentreganovo}
                                                            onChange={(value) => setValetentregaNovo(value)}
                                                            style={{
                                                                marginLeft: '0px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Option value="1">Sim</Option>
                                                            <Option value="0">Não</Option>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <span>Recolher o veículo no aeroporto na zona de Partida (Porta C).</span>
                                                    </div>
                                                    {isLoadingValetEntrada ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaValetEntrada ? (
                                                        <AlertaMsg mensagem="selecione uma opção" />
                                                    ) : isFalhaValetEntrada ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoValetEntrada ? (
                                                        <SucessoMsg mensagem="valet de entrada alterado!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>
                                            <div className="editar-dias-reserva">
                                                <span><b>Transfer: </b>{dadodareserva.transfer_entrada === "0" ? "Não" : `Sim (${dadodareserva.transfer_entrada} Pessoas)`}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarTransferdeEntrega}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Transfer de Entrega" open={isModalOpenTransferEntrega} onOk={handleOkTransferEntrega} onCancel={handleCancelTransferEntrega} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <span>Serviço de Transfer?</span>
                                                        <Select
                                                            id="estados"
                                                            value={transferentreganovo}
                                                            onChange={(value) => setTransferentregaNovo(value)}
                                                            style={{
                                                                marginLeft: '0px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Option value="1">Sim</Option>
                                                            <Option value="0">Não</Option>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <span>Número de Pessoas</span>
                                                        <label>
                                                            <input
                                                                type="text"
                                                                className={`input-calendario`}
                                                                value={transferpessoasentreganovo}
                                                                onChange={(e) => setTransferPessoasentregaNovo(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                    {isLoadingTransferEntrada ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaTransferEntrada ? (
                                                        <AlertaMsg mensagem={alertaMensagem} />
                                                    ) : isFalhaTransferEntrada ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoTransferEntrada ? (
                                                        <SucessoMsg mensagem="transfer de entrada alterado!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>

                                        </div>
                                        <div className="flex-resumo-page-col">
                                            <a className="label-do-campo">Saída</a>
                                            <hr className="linha-principal" />
                                            <div className="editar-dias-reserva">
                                                <span><b>Data de Saída:</b> {dadodareserva.dia_de_saida !== "" ? dadodareserva.dia_de_saida : "Sem informação"}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarDiadeRecolha}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Dia de Recolha" open={isModalOpenRecolha} onOk={handleOkRecolha} onCancel={handleCancelRecolha} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="text"
                                                                className={`input-calendario`}
                                                                placeholder="dd-mm-aaaa"
                                                                value={datarecolhanovo}
                                                                onChange={(e) => setDatarecolhaNovo(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <span>Formato: dd-mm-aaaa</span>
                                                    </div>
                                                    {isLoadingNovaDataRecolha ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaNovaDataRecolha ? (
                                                        <AlertaMsg mensagem="escreva uma data com o formato dd-mm-aaaa" />
                                                    ) : isFalhaNovaDataRecolha ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoNovaDataRecolha ? (
                                                        <SucessoMsg mensagem="data de recolha alterada!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>
                                            <div className="editar-dias-reserva">
                                                <span><b>Hora de Recolha:</b> {dadodareserva.horario_de_saida}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarHoradeRecolha}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Hora de Recolha" open={isModalOpenHoraRecolha} onOk={handleOkHoraRecolha} onCancel={handleCancelHoraRecolha} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="text"
                                                                className={`input-calendario`}
                                                                placeholder="h:mm"
                                                                value={horarecolhanovo}
                                                                onChange={(e) => setHorarecolhaNovo(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <span>Formato: hh:mm</span>
                                                    </div>
                                                    {isLoadingNovaHoraRecolha ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaNovaHoraRecolha ? (
                                                        <AlertaMsg mensagem="escreva uma hora com o formato hh:mm" />
                                                    ) : isFalhaNovaHoraRecolha ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoNovaHoraRecolha ? (
                                                        <SucessoMsg mensagem="hora de recolha alterada!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>
                                            <div className="editar-dias-reserva">
                                                <span><b>Valet: </b>{dadodareserva.valet_service_saida === false ? "Não" : "Sim"}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarValetRecolha}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Valet de Recolha" open={isModalOpenValetRecolha} onOk={handleOkValetRecolha} onCancel={handleCancelValetRecolha} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <Select
                                                            id="estados"
                                                            value={valetrecolhanovo}
                                                            onChange={(value) => setValetrecolhaNovo(value)}
                                                            style={{
                                                                marginLeft: '0px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Option value="1">Sim</Option>
                                                            <Option value="0">Não</Option>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <span>Entregar o veículo no aeroporto na zona de Partida (Porta C).</span>
                                                    </div>
                                                    {isLoadingValetRecolha ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaValetRecolha ? (
                                                        <AlertaMsg mensagem="selecione uma opção." />
                                                    ) : isFalhaValetRecolha ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoValetRecolha ? (
                                                        <SucessoMsg mensagem="valet de saída alterado!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>

                                            <div className="editar-dias-reserva">
                                                <span><b>Transfer: </b>{dadodareserva.transfer_saida === "0" ? "Não" : `Sim (${dadodareserva.transfer_saida} Pessoas)`}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarTransferdeRecolha}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Transfer de Recolha" open={isModalOpenTransferRecolha} onOk={handleOkTransferRecolha} onCancel={handleCancelTransferRecolha} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <span>Serviço de Transfer?</span>
                                                        <Select
                                                            id="estados"
                                                            value={transferrecolhanovo}
                                                            onChange={(value) => setTransferrecolhaNovo(value)}
                                                            style={{
                                                                marginLeft: '0px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Option value="1">Sim</Option>
                                                            <Option value="0">Não</Option>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <span>Número de Pessoas</span>
                                                        <label>
                                                            <input
                                                                type="text"
                                                                className={`input-calendario`}
                                                                value={transferpessoasrecolhanovo}
                                                                onChange={(e) => setTransferPessoasrecolhaNovo(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                    {isLoadingTransferRecolha ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaTransferRecolha ? (
                                                        <AlertaMsg mensagem={alertaMensagem} />
                                                    ) : isFalhaTransferRecolha ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoTransferRecolha ? (
                                                        <SucessoMsg mensagem="transfer de saída alterado!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <h2>Informações do Cliente:</h2>
                                    <br />
                                    <div className="flex-resumo-page">
                                        <div className="flex-resumo-page-col">
                                            <a className="label-do-campo">Dados do Cliente</a>
                                            <hr className="linha-principal" />
                                            <span><b> Nome:</b> {dadodareserva.primeiro_nome !== "" ? dadodareserva.primeiro_nome : "Sem informação"} {dadodareserva.ultimo_nome !== "" ? dadodareserva.ultimo_nome : "Sem informação"}</span>
                                            <span><b>Telemóvel:</b> {dadodareserva.telemovel !== "" ? dadodareserva.telemovel : "Sem informação"}</span>
                                            <span><b>Email:</b> {dadodareserva.email !== "" ? dadodareserva.email : "Sem informação"}</span>
                                        </div>
                                        <div className="flex-resumo-page-col">
                                            <a className="label-do-campo">Dados do Veículo</a>
                                            <hr className="linha-principal" />
                                            <div className="editar-dias-reserva">
                                                <span><b>Matrícula:</b> <span className="uppercase-element">{dadodareserva.matricula !== "" ? dadodareserva.matricula : "Sem informação"}</span></span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarMatricula}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Matrícula" open={isModalOpenMatricula} onOk={handleOkMatricula} onCancel={handleCancelMatricula} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className={`input-calendario uppercase-element`}
                                                            placeholder="XX - XX - XX"
                                                            value={matriculaNovo}
                                                            onChange={(e) => setMatriculaNovo(e.target.value)}
                                                        />
                                                    </div>
                                                    {isLoadingMatricula ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaMatricula ? (
                                                        <AlertaMsg mensagem="campo matrícula vazio." />
                                                    ) : isFalhaMatricula ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoMatricula ? (
                                                        <SucessoMsg mensagem="matrícula alterada!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>


                                            <div className="editar-dias-reserva">
                                                <span><b>Marca/Modelo:</b> {dadodareserva.marca !== "" ? dadodareserva.marca : "Sem informação"} {dadodareserva.modelo !== "" ? dadodareserva.modelo : "Sem informação"}</span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarMarcaEModelo}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Marca e Modelo" open={isModalOpenMarcaEModelo} onOk={handleOkMarcaEModelo} onCancel={handleCancelMarcaEModelo} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <a className="label-do-campo">Marca</a>
                                                        <br />
                                                        <AutoComplete
                                                            style={{
                                                                width: '100%',
                                                                height: 47,
                                                            }}
                                                            options={filteredOptions.map(modeloNovo => ({ value: modeloNovo }))}
                                                            // optionLabelProp="label"
                                                            value={marcaNovo}
                                                            placeholder="Marca"
                                                            filterOption={false}
                                                            onSearch={handleSearch}
                                                            onSelect={handleSelect}
                                                        />
                                                    </div>
                                                    <div>
                                                        <a className="label-do-campo">Modelo</a>
                                                        <br />
                                                        <input
                                                            type="text"
                                                            className="input-dados-reserva"
                                                            value={modeloNovo}
                                                            placeholder="Modelo"
                                                            onChange={(e) => setModeloNovo(e.target.value)}
                                                        />
                                                    </div>
                                                    {isLoadingMarcaEModelo ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaMarcaEModelo ? (
                                                        <AlertaMsg mensagem="campo marca ou modelo vazio." />
                                                    ) : isFalhaMarcaEModelo ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoTMarcaEModelo ? (
                                                        <SucessoMsg mensagem="marca e modelo alterado!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>


                                            <br />
                                        </div>
                                        <div className="flex-resumo-page-col">
                                            <a className="label-do-campo">Voo</a>
                                            <hr className="linha-principal" />
                                            <div className="editar-dias-reserva">
                                                <span className="numero-de-voo-func"><b>Nº Voo Partida:</b> <span className="">{dadodareserva.n_voo_partida !== null ? dadodareserva.n_voo_partida : "Sem informação..."}</span></span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarVooPartida}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Nº Voo Partida" open={isModalOpenVooPartida} onOk={handleOkVooPartida} onCancel={handleCancelVooPartida} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className={`input-calendario uppercase-element`}
                                                            placeholder="TP123"
                                                            value={vooPartidaNovo}
                                                            onChange={(e) => setVooPartidaNovo(e.target.value)}
                                                        />
                                                    </div>
                                                    {isLoadingVooPartida ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaVooPartida ? (
                                                        <AlertaMsg mensagem="campo nº de voo partida vazio." />
                                                    ) : isFalhaVooPartida ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoVooPartida ? (
                                                        <SucessoMsg mensagem="nº de voo partida alterado!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>


                                            <div className="editar-dias-reserva">
                                                <span className="numero-de-voo-func"><b>Nº Voo Chegada:</b> <span className="">{dadodareserva.n_voo_chegada !== null ? dadodareserva.n_voo_chegada : "Sem informação..."}</span></span>
                                                {cancelamentodareserva === "Cancelado" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                                                            <>
                                                                <span className="icon-editar-dias-reserva" onClick={handleEditarVooChegada}><PiPencilBold /></span>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                            <Modal title="Alterar Nº Voo Chegada" open={isModalOpenVooChegada} onOk={handleOkVooChegada} onCancel={handleCancelVooChegada} okText="Confirmar" cancelText="Cancelar">
                                                <div className="modal-com-flex">
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className={`input-calendario uppercase-element`}
                                                            placeholder="TP123"
                                                            value={vooChegadaNovo}
                                                            onChange={(e) => setVooChegadaNovo(e.target.value)}
                                                        />
                                                    </div>
                                                    {isLoadingVooChegada ? (
                                                        <LoadingMsg mensagem="Aguarde..." />
                                                    ) : isAlertaVooChegada ? (
                                                        <AlertaMsg mensagem="campo nº de voo chegada vazio." />
                                                    ) : isFalhaVooChegada ? (
                                                        <ErroMsg mensagem="tente novamente." />
                                                    ) : isSucessoVooChegada ? (
                                                        <SucessoMsg mensagem="nº de voo chegada alterado!" />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Modal>



                                            <br />
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <h2>SERVIÇOS DE LIMPEZA E EXTRAS:</h2>
                                    <br />
                                    <div className="flex-resumo-page">
                                        <div className="flex-resumo-page-col">
                                            <a className="label-do-campo">Serviços de Limpeza</a>
                                            <hr className="linha-principal" />
                                            {dadodareserva.servicosdelimpeza === "" ? (
                                                <><span>Sem serviços selecionados...</span></>
                                            ) : (

                                                <ul>
                                                    {dadodareserva.servicosdelimpeza?.map(extra => (
                                                        <li key={extra}>
                                                            <label>
                                                                {extra}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>

                                            )}
                                        </div>
                                        <div className="flex-resumo-page-col">
                                            <a className="label-do-campo">Serviços Extra</a>
                                            <hr className="linha-principal" />
                                            {dadodareserva.servicosextrasreserva === "" ? (
                                                <><span>Sem serviços selecionados...</span></>
                                            ) : (
                                                <ul>
                                                    {dadodareserva.extrasadic?.map(extramono => (
                                                        <li key={extramono}>
                                                            <label>
                                                                {extramono}
                                                            </label>
                                                        </li>
                                                    ))}
                                                    {dadodareserva.servicosextrasreserva?.map(extramono => (
                                                        <li key={extramono}>
                                                            <label>
                                                                {extramono}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>

                                    </div>
                                </div>


                                <div>
                                    <h2>Mensagem/Observações:</h2>
                                    <br />
                                    <span>{dadodareserva.mensagem === null || dadodareserva.mensagem === "" ? "Sem informação..." : dadodareserva.mensagem}</span>
                                </div>

                            </div>

                        ) : null}


                        <div>
                            <ServicosExtras id={id} setDataReserva={setDataReserva} isTabletMobile={isTabletMobile} extraDataServicosReserva={extraDataServicosReserva} setExtraDataServicosReserva={setExtraDataServicosReserva} setLogsData={setLogsData} userMain={userMain} cancelamentodareserva={cancelamentodareserva} userRole={userRole} idDaReservaPost={idDaReservaPost} dadodareserva={dadodareserva} />
                        </div>



                        {userRole === "administrator" || userRole === "shop_manager" || userRole === "contributor" ? (
                            <>
                                <div className="admin-lab">
                                    <AreaGestaoReservaAdmin
                                        setDataReserva={setDataReserva}
                                        localdeestacionamentodata={localdeestacionamentodata}
                                        fotografiassss={fotografiassss}
                                        idDaReservaPost={idDaReservaPost}
                                        dadodareserva={dadodareserva}
                                        isTabletMobile={isTabletMobile}
                                        userMain={userMain}
                                        id={id}
                                        userNameAccount={userNameAccount}
                                        setLogsData={setLogsData}
                                        logsData={logsData}
                                        videoooos={videoooos}
                                        cancelamentodareserva={cancelamentodareserva}
                                    />
                                </div>
                            </>
                        ) : null}
                    </>
                )}

            </div >
        </div >

    );
}

export default RegistodoPedido;
