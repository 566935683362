import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Collapse } from 'antd';
import { OBTERFAQS } from '../data/obterFaqs';

const { Panel } = Collapse;

const Faqs = () => {


    const { loading, data } = useQuery(OBTERFAQS);
    const page = data?.elementosWP.edges[0].node.faqs
    // console.log(page)


    if (loading) {
        return
    }


    function createMarkup(html) {
        return { __html: html };
    }



    return (
        <div id='faqs' className='section-main'>
            <div className='section-default-center'>
                <div className='faqs-main-sec'>
                    <div className='coluna-default-center'>
                        <h4>FAQ's</h4>
                        <h1>Perguntas Frequentes</h1>
                    </div>
                    <div className='coluna-sobre-nos-child sobre-nos-descricao'>
                        <Collapse defaultActiveKey={['1']}>
                            <Panel header={page.tituloFaq1} key="1">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq1)} />
                            </Panel>
                            <Panel header={page.tituloFaq2} key="2">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq2)} />
                            </Panel>
                            <Panel header={page.tituloFaq3} key="3">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq3)} />
                            </Panel>
                            <Panel header={page.tituloFaq4} key="4">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq4)} />
                            </Panel>
                            <Panel header={page.tituloFaq5} key="5">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq5)} />
                            </Panel>
                            <Panel header={page.tituloFaq6} key="6">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq6)} />
                            </Panel>
                            <Panel header={page.tituloFaq7} key="7">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq7)} />
                            </Panel>
                            <Panel header={page.tituloFaq8} key="8">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq8)} />
                            </Panel>
                            <Panel header={page.tituloFaq9} key="9">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq9)} />
                            </Panel>
                            <Panel header={page.tituloFaq10} key="10">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq10)} />
                            </Panel>
                            <Panel header={page.tituloFaq11} key="11">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq11)} />
                            </Panel>
                            <Panel header={page.tituloFaq12} key="12">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq12)} />
                            </Panel>
                            <Panel header={page.tituloFaq13} key="13">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq13)} />
                            </Panel>
                            <Panel header={page.tituloFaq14} key="14">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq14)} />
                            </Panel>
                            <Panel header={page.tituloFaq15} key="15">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq15)} />
                            </Panel>
                            <Panel header={page.tituloFaq16} key="16">
                                <p dangerouslySetInnerHTML={createMarkup(page.descricaoFaq16)} />
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Faqs;



