import React from 'react';
import { AutoComplete } from 'antd';
import { indicativosTelefone } from '../../data/indicativosTelefone.js';

const DadosPessoais = ({ erroNoInputTelemovelPais, setErroNoInputTelemovelPais, erroNoInputNome, setErroNoInputNome, setErroNoInputSobrenome, erroNoInputSobrenome, erroNoInputTelemovel, setErroNoInputTelemovel, erroNoInputEmail, setErroNoInputEmail, nome, setNome, sobrenome, setSobrenome, telemovel, setTelemovel, email, setEmail, telemovelPais, setTelemovelPais }) => {

    const handleSearch = (value) => {
        setTelemovelPais(value);
    };

    const handleSelect = (value) => {
        setTelemovelPais(value)
        setErroNoInputTelemovelPais(false)
    };

    const filteredOptions = indicativosTelefone.filter(option =>
        option.value.includes(telemovelPais)
    );


    return (
        <div className="sec-dados-pessoais">
            <div className="flex-col-titulo">
                <h2>Dados Pessoais</h2>
            </div>
            <hr className="linha-principal" />
            <div className='col-dados-pessoais'>
                <div className='subcol-dados-pessoais'>
                    <a className="label-do-campo">Nome *</a>
                    <br />
                    <input
                        type="text"
                        className={`input-calendario ${erroNoInputNome ? 'campo-com-erro' : ''}`}
                        value={nome}
                        placeholder="Primeiro nome"
                        onChange={(e) => {
                            const input = e.target.value;
                            const maxLength = 15;
                            const limitedValue = input.slice(0, maxLength);
                            setNome(limitedValue);
                            setErroNoInputNome(false)
                        }}
                    />
                </div>
                <div className='subcol-dados-pessoais'>
                    <a className="label-do-campo">Sobrenome *</a>
                    <br />
                    <input
                        type="text"
                        className={`input-calendario ${erroNoInputSobrenome ? 'campo-com-erro' : ''}`}
                        value={sobrenome}
                        placeholder="Último nome"
                        onChange={(e) => {
                            const input = e.target.value;
                            const maxLength = 15;
                            const limitedValue = input.slice(0, maxLength);
                            setErroNoInputSobrenome(false)
                            setSobrenome(limitedValue);
                        }}
                    />

                </div>
                <div className='subcol-dados-pessoais'>
                    <a className="label-do-campo">Telemóvel *</a>
                    <br />
                    <div className='flex-nogap input-telefone-indicativos'>
                        <AutoComplete
                            style={{
                                width: 100,
                                height: 47,
                            }}
                            className={`${erroNoInputTelemovelPais ? 'campo-com-erro-tel' : ''}`}
                            allowClear
                            options={filteredOptions}
                            optionLabelProp="label"
                            value={telemovelPais}
                            placeholder="+000"
                            filterOption={false}
                            onSearch={handleSearch}
                            onSelect={handleSelect}
                        />

                        <input
                            type="text"
                            className={`input-calendario input-telefone ${erroNoInputTelemovel ? 'campo-com-erro' : ''}`}
                            value={telemovel}
                            placeholder="000 000 000"
                            onChange={(e) => {
                                const input = e.target.value;
                                const onlyNumbers = input.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
                                setTelemovel(onlyNumbers); // Atualize o estado com o valor numérico corrigido
                                setErroNoInputTelemovel(false)
                            }}
                        />
                    </div>

                </div>
            </div>
            <div className='subcol-dados-pessoais col-email-dados-pessoais'>
                <a className="label-do-campo">Email *</a>
                <br />
                <input
                    type="email"
                    className={`input-calendario ${erroNoInputEmail ? 'campo-com-erro' : ''}`}
                    value={email}
                    placeholder="*******************@******"
                    onChange={(e) => {
                        setEmail(e.target.value)
                        setErroNoInputEmail(false)
                    }}
                    onInvalid={(e) => {
                        e.target.setCustomValidity('Insira um endereço de email válido');
                    }}
                    onInput={(e) => {
                        e.target.setCustomValidity('');
                    }}
                />

            </div>
        </div>
    );
};

export default DadosPessoais;
