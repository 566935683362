import React, { useEffect, useRef, useState } from 'react';
import Calendario from './calendariorapida';
import { AutoComplete, Input, Tooltip } from 'antd';
import { lugaresCarro } from '../../data/lugaresCarro';
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Select } from 'antd';
const { Option } = Select;

// import { Select } from 'antd';


const EntradaSaida = ({
    validarHorasForm,
    isTabletMobile,
    erroNoInputDataEntrada,
    setErroNoInputDataEntrada,
    erroNoInputDataRecolha,
    setErroNoInputDataRecolha,
    listadeHorasRecolha,
    setListadeHorasRecolha,
    listadeHorasEntrega,
    setListadeHorasEntrega,
    produtosSelecionadosIds,
    extrasSelecionados,
    listadeProdutos,
    espacoSelecionados,
    id,
    isValidId,
    setHoraEntrega,
    setMinutoEntrega,
    horaEntrega,
    setHoraRecolha,
    minutoEntrega,
    setMinutoRecolha,
    horaRecolha,
    minutoRecolha,
    setDataentrega,
    datarecolha,
    dataentrega,
    setDatarecolha,
    precototaldareserva,
    setPrecototaldareserva,
    setOpenresumodopedido,
    setOpenresumodopedidopreco,
    diaSelecionado,
    setDiaSelecionado,
    diaSelecionadoSaida,
    setDiaSelecionadoSaida,
    mesesentrada,
    anoentrada,
    setMesesEntrada,
    setAnoEntrada,
    setAnoSaida,
    setMesesSaida,
    mesessaida,
    anosaida,
    isValetCheckedentrada,
    setIsValetCheckedentrada,
    isValetCheckedsaida,
    setIsValetCheckedsaida,
    isValetCheckedsaidaTransfer,
    setIsValetCheckedsaidaTransfer,
    isValetCheckedentradaTransfer,
    setIsValetCheckedentradaTransfer,
    numpessoasentrada,
    setNumpessoasentrada,
    numpessoassaida,
    setNumpessoassaida,
    openCalendarioentrega,
    setOpenCalendarioEntrega,
    openCalendariosaida,
    setOpenCalendarioSaida,
}) => {

    const recolhaRef = useRef();

    // const horasentrega = Array.from({ length: 24 }, (_, index) => index); // Horas - Cria um array com os números de 0 a 23
    // console.log(horasentrega)
    // const horasrecolha = Array.from({ length: 24 }, (_, index) => index); // Horas - Cria um array com os números de 0 a 23

    const minutosentrega = Array.from({ length: 6 }, (_, index) => index * 10);
    const minutosrecolha = Array.from({ length: 6 }, (_, index) => index * 10); // Minutos - Cria um array com os números de 0 a 59

    // const pessoasentrega = Array.from({ length: 8 }, (_, index) => index + 1); // Pessoas - Cria um array com os números de 0 a 30
    // const pessoasrecolha = Array.from({ length: 8 }, (_, index) => index + 1); // Pessoas - Cria um array com os números de 0 a 30

    const [isSvgImportant, setIsSvgImportant] = useState(false);

    // const [isValetCheckedentrada, setIsValetCheckedentrada] = useState(false); // Check  no Valet Entrada para aparecer a section
    // const [isValetCheckedsaida, setIsValetCheckedsaida] = useState(false); // Check  no Valet para aparecer a section

    const [openCalendario, setOpenCalendario] = useState(false); //colocar a false quando finalizar ALERTA!
    const [numdePessoasTransferEntrada, setNumdePessoasTransferEntrada] = useState(false);
    const [numdePessoasTransferSaida, setNumdePessoasTransferSaida] = useState(false);
    const [calendarioData, setCalendarioData] = useState([]);
    const [precodiaSelecionado, setPrecoDiaSelecionado] = useState(null);
    const [precodiaSelecionadoSaida, setPrecoDiaSelecionadoSaida] = useState(null);

    const [horasEstaoDisponiveisEntrega, setHorasEstaoDisponiveisEntrega] = useState(false);
    const [horasEstaoDisponiveisRecolha, setHorasEstaoDisponiveisRecolha] = useState(false);



    const [mesSelecionadoParaHoras, setMesSelecionadoParaHoras] = useState('');
    const [anoSelecionadoEntradaParaHoras, setAnoSelecionadoEntradaParaHoras] = useState('');


    const handleCheckboxChangeentrada = () => {
        setIsValetCheckedentrada()
        setIsValetCheckedentrada(!isValetCheckedentrada);
        // console.log(isValetCheckedentrada)
    };

    const handleCheckboxChangeentradaTransfer = () => {
        setIsValetCheckedentradaTransfer()
        setIsValetCheckedentradaTransfer(!isValetCheckedentradaTransfer);
        setNumdePessoasTransferEntrada()
        setNumdePessoasTransferEntrada(!numdePessoasTransferEntrada);
    };

    const handleCheckboxChangesaida = () => {
        setIsValetCheckedsaida()
        setIsValetCheckedsaida(!isValetCheckedsaida);
        // console.log(isValetCheckedsaida)
    };

    const handleCheckboxChangesaidaTransfer = () => {
        setIsValetCheckedsaidaTransfer()
        setIsValetCheckedsaidaTransfer(!isValetCheckedsaidaTransfer);
        setNumdePessoasTransferSaida()
        setNumdePessoasTransferSaida(!numdePessoasTransferSaida)
    };

    const handleSelectClick = () => {
        setIsSvgImportant(true);
    };

    const handleDataEntrega = (e) => {
        setDataentrega(e.target.value);
    };

    const handleDateSelectEntrada = () => {
        setOpenCalendario(true);
        setOpenCalendarioEntrega(true);
        setOpenCalendarioSaida(false);
        setOpenresumodopedido(true);
        setOpenresumodopedidopreco(true)
    };

    const handleDateSelectSaida = () => {
        setOpenCalendario(true);

        if (diaSelecionado) {
            setOpenCalendarioSaida(true);
            setOpenCalendarioEntrega(false);
        } else {
            setOpenCalendarioSaida(true);
            setOpenCalendarioEntrega(false);
            setOpenresumodopedido(true);
            setOpenresumodopedidopreco(true)
        }

    };


    const handleHoraEntrega = (value) => {
        setHoraEntrega(value);
    };




    const handleMinutoEntrega = (value) => {
        setMinutoEntrega(value);
    };

    const handleHoraRecolha = (value) => {
        setHoraRecolha(value);
    };

    const handleMinutoRecolha = (value) => {
        setMinutoRecolha(value);
    };

    const handleSelectNumPessoasEntrada = (value) => {
        setNumpessoasentrada(value);
    };

    const handleSelectNumPessoasSaida = (value) => {
        setNumpessoassaida(value);
        // console.log('', numpessoassaida)
    }

    const filteredOptions = lugaresCarro.filter(lugar =>
        lugar.toLowerCase().includes(lugar.toLowerCase())
    );

    // Função para fechar o calendario
    const toggleMenu = () => {
        setOpenCalendario(false);
    };


    const horaEntregaAPI = listadeHorasEntrega
    const horaRecolhaAPI = listadeHorasRecolha


    const optionsentrega = Object.keys(horaEntregaAPI).map((hora) => ({
        value: hora,
        label: horaEntregaAPI[hora]
    }));

    const optionsrecolha = Object.keys(horaRecolhaAPI).map((hora) => ({
        value: hora,
        label: horaRecolhaAPI[hora]
    }));






    return (
        <>
            <div className={`${openCalendario ? 'overlay-bloco-calendario' : 'overlay-bloco-calendario-close'}`} onClick={toggleMenu}></div>
            <div className="sec-entrada-recolha">
                <div className="sec-entrada">
                    <h2>Entrada</h2>
                    <hr className="linha-principal" />
                    <div className={`col-sec-entrada-recolha ${openCalendarioentrega ? 'calendariosaida-open-col' : ''}`}>
                        <label>
                            <input
                                type="text"
                                className={`input-calendario ${erroNoInputDataEntrada ? 'campo-com-erro' : ''}`}
                                placeholder="dd-mm-aaaa"
                                value={dataentrega}
                                onChange={(e) => setDataentrega(e.target.value)}
                                onClick={handleDateSelectEntrada}
                                readOnly
                            />
                        </label>
                        <hr className="sub-linha" />
                        <label>
                            <a className="label-do-campo">Hora de entrada</a>
                            <br />
                            <div className="col-horas-de-entrada">
                                <svg className={`icons-web ${isSvgImportant ? 'svg-important' : ''}`} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35" >
                                    <path id="clock" d="M25.5,8A17.5,17.5,0,1,0,43,25.5,17.5,17.5,0,0,0,25.5,8Zm0,31.613A14.113,14.113,0,1,1,39.613,25.5,14.109,14.109,0,0,1,25.5,39.613Zm4.361-7.367L23.87,27.892a.852.852,0,0,1-.346-.684V15.621a.849.849,0,0,1,.847-.847h2.258a.849.849,0,0,1,.847.847v10L32.19,29.05a.846.846,0,0,1,.183,1.185l-1.327,1.828a.853.853,0,0,1-1.185.183Z" transform="translate(-8 -8)" />
                                </svg>
                                <label className={`${horasEstaoDisponiveisEntrega === true ? 'horasdisponiveisentrega' : 'horasindisponiveisentrega'}`}>

                                    <Select
                                        style={{
                                            width: 80,
                                            height: 47,
                                        }}
                                        onChange={handleHoraEntrega}
                                        options={optionsentrega}
                                        value={horaEntrega}
                                    />

                                </label>
                                <label className={`${horasEstaoDisponiveisEntrega === true ? 'horasdisponiveisentrega' : 'horasindisponiveisentrega'}`}>
                                    <Select
                                        style={{
                                            width: 80,
                                            height: 47,
                                        }}
                                        value={minutoEntrega}
                                        onChange={handleMinutoEntrega}
                                        placeholder="00"
                                    >
                                        {minutosentrega.map((minutoentrega) => (
                                            <Select.Option
                                                key={minutoentrega}
                                                value={minutoentrega.toString().padStart(2, '0')}
                                            >
                                                {minutoentrega.toString().padStart(2, '0')}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </label>
                            </div>
                        </label>
                        {isValetCheckedentradaTransfer ? (
                            <>
                            </>
                        ) : (
                            <>
                                <hr className="sub-linha" />
                                <div>
                                    <label>
                                        <input type="checkbox" className="all-checkbox" name="service" value="valet" checked={isValetCheckedentrada} onChange={handleCheckboxChangeentrada}></input>
                                        <a className="label-do-campo">Valet Service</a>
                                        <Tooltip title="Valet Service é um serviço onde um funcionário irá recolher ou entregar o seu carro ao aeroporto.">
                                            <AiOutlineQuestionCircle className='icon-question' />
                                        </Tooltip>
                                    </label>
                                    <p className="info-valet-service">VALET SERVICE:<br /><a className="info-valet-service-label">Recolhemos o seu veículo no aeroporto na zona de Partida (Porta C).</a></p>
                                </div>
                            </>
                        )}

                        <div>
                            {isValetCheckedentrada ? (
                                <>
                                </>
                            ) : (
                                <>
                                    <hr className="sub-linha" />
                                    <div className={`num-pessoas-entrada-recolha ${isValetCheckedentrada ? 'num-pessoas-visivel' : ''}`}>
                                        <label>
                                            <input type="checkbox" className="all-checkbox" value="transfer" checked={isValetCheckedentradaTransfer} onChange={handleCheckboxChangeentradaTransfer} ></input>
                                            <a className="label-do-campo">Transfer</a>
                                            <Tooltip title="Transfer é o transporte gratuito disponibilizado pela Indoor Parking Low Cost até ao aeroporto e vice-versa.">
                                                <AiOutlineQuestionCircle className='icon-question' />
                                            </Tooltip>
                                            <p className="info-valet-service">TRANSFER:<br /><a className="info-valet-service-label">Serviço de Transfer na zona de Partidas (Porta C).</a></p>

                                        </label>
                                        {numdePessoasTransferEntrada && (
                                            <div className="col-horas-de-entrada">
                                                <svg id="Grupo_236" data-name="Grupo 236" width="35" height="35" viewBox="0 0 50.268 36.664">
                                                    <defs>
                                                        <clipPath id="clip-path">
                                                            <rect id="Retângulo_499" data-name="Retângulo 499" width="50.268" height="36.664" fill="#abc132" />
                                                        </clipPath>
                                                    </defs>
                                                    <g id="Grupo_53" data-name="Grupo 53" clipPath="url(#clip-path)">
                                                        <path id="Caminho_100" data-name="Caminho 100" d="M67.207,38.147a9.735,9.735,0,0,0,3.744,7.714c-4.53,2.637-5.686,7.83-6.139,11.8a1.521,1.521,0,0,0,1.339,1.683,1.554,1.554,0,0,0,.174.01,1.521,1.521,0,0,0,1.509-1.349c.724-6.349,2.849-9.48,7.1-10.469a1.521,1.521,0,0,0,.1-2.937,6.748,6.748,0,1,1,3.924,0,1.521,1.521,0,0,0,.1,2.937c4.256.989,6.38,4.12,7.1,10.469a1.521,1.521,0,1,0,3.022-.344c-.453-3.975-1.609-9.168-6.139-11.8a9.794,9.794,0,1,0-15.835-7.714" transform="translate(-51.863 -22.695)" fill="#abc132" />
                                                        <path id="Caminho_101" data-name="Caminho 101" d="M155.095,5.246a6.749,6.749,0,1,1,6.951,11,1.521,1.521,0,0,0,.1,2.937c4.256.989,6.38,4.12,7.1,10.469a1.521,1.521,0,0,0,3.022-.345c-.453-3.975-1.609-9.168-6.139-11.8A9.8,9.8,0,1,0,152.847,3.2a1.521,1.521,0,0,0,2.248,2.049" transform="translate(-122.011)" fill="#abc132" />
                                                        <path id="Caminho_102" data-name="Caminho 102" d="M1.522,31a1.521,1.521,0,0,0,1.509-1.349c.724-6.349,2.849-9.48,7.1-10.469a1.521,1.521,0,0,0,.1-2.937,6.749,6.749,0,1,1,6.951-11A1.521,1.521,0,0,0,19.431,3.2,9.795,9.795,0,1,0,6.148,17.5c-4.53,2.637-5.686,7.83-6.139,11.8A1.522,1.522,0,0,0,1.522,31" transform="translate(0.001 0)" fill="#abc132" />
                                                    </g>
                                                </svg>
                                                {/* <AutoComplete
                                                    style={{
                                                        width: 72,
                                                        height: 48,
                                                    }}
                                                    options={filteredOptions.map(lugar => ({ value: lugar }))}
                                                    optionLabelProp="label"
                                                    value={numpessoasentrada}
                                                    placeholder="0"
                                                    onSelect={handleSelectNumPessoasEntrada}
                                                /> */}
                                                <Select
                                                    style={{
                                                        width: 80,
                                                        height: 47,
                                                    }}
                                                    placeholder="0"
                                                    onSelect={handleSelectNumPessoasEntrada}
                                                >
                                                    {filteredOptions.map((lugar) => (
                                                        <Select.Option
                                                            key={lugar}
                                                            value={lugar}
                                                        >
                                                            {lugar}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                                <a className="label-do-campo">Nº Pessoas</a>
                                            </div>
                                        )}
                                    </div>

                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="sec-recolha" ref={recolhaRef}>
                    <h2>Saída</h2>
                    <hr className="linha-principal" />
                    <div className={`col-sec-entrada-recolha ${openCalendariosaida ? 'calendariosaida-saida-col' : ''}`}>
                        <label>
                            <input
                                type="text"
                                className={`input-calendario ${erroNoInputDataRecolha ? 'campo-com-erro' : ''}`}
                                value={datarecolha}
                                placeholder="dd-mm-aaaa"
                                onChange={handleDataEntrega}
                                onClick={handleDateSelectSaida}
                                readOnly
                            />
                        </label>
                        <hr className="sub-linha" />
                        <label>
                            <a className="label-do-campo">Hora de saída</a>
                            <br />
                            <div className="col-horas-de-entrada">
                                <svg className={`icons-web ${isSvgImportant ? 'svg-important' : ''}`} width="30" height="30" viewBox="0 0 35 35" >
                                    <path id="clock" d="M25.5,8A17.5,17.5,0,1,0,43,25.5,17.5,17.5,0,0,0,25.5,8Zm0,31.613A14.113,14.113,0,1,1,39.613,25.5,14.109,14.109,0,0,1,25.5,39.613Zm4.361-7.367L23.87,27.892a.852.852,0,0,1-.346-.684V15.621a.849.849,0,0,1,.847-.847h2.258a.849.849,0,0,1,.847.847v10L32.19,29.05a.846.846,0,0,1,.183,1.185l-1.327,1.828a.853.853,0,0,1-1.185.183Z" transform="translate(-8 -8)" />
                                </svg>
                                <label className={`${horasEstaoDisponiveisRecolha === true ? 'horasdisponiveisrecolha' : 'horasindisponiveisrecolha'}`}>

                                    <Select
                                        style={{
                                            width: 80,
                                            height: 47,
                                        }}
                                        onChange={handleHoraRecolha}
                                        options={optionsrecolha}
                                        value={horaRecolha}
                                    />

                                </label>
                                <label className={`${horasEstaoDisponiveisRecolha === true ? 'horasdisponiveisrecolha' : 'horasindisponiveisrecolha'}`}>
                                    <Select
                                        style={{
                                            width: 80,
                                            height: 47,
                                        }}
                                        value={minutoRecolha}
                                        onChange={handleMinutoRecolha}
                                        placeholder="00"
                                    >
                                        {minutosrecolha.map((minutorecolha) => (
                                            <Select.Option
                                                key={minutorecolha}
                                                value={minutorecolha.toString().padStart(2, '0')}
                                            >
                                                {minutorecolha.toString().padStart(2, '0')}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </label>
                            </div>
                        </label>
                        {isValetCheckedsaidaTransfer ? (
                            <>
                            </>
                        ) : (
                            <>
                                <hr className="sub-linha" />
                                <div>
                                    <label>
                                        <input type="checkbox" className="all-checkbox" name="service" value="valet" checked={isValetCheckedsaida} onChange={handleCheckboxChangesaida}></input>
                                        <a className="label-do-campo">Valet Service</a>
                                        <Tooltip title="Valet Service é um serviço onde um funcionário irá recolher ou entregar o seu carro ao aeroporto.">
                                            <AiOutlineQuestionCircle className='icon-question' />
                                        </Tooltip>
                                    </label>
                                    <p className="info-valet-service">VALET SERVICE:<br /><a className="info-valet-service-label">Entregamos o seu veículo no aeroporto na zona de Partida (Porta C).</a></p>
                                </div>
                            </>
                        )}
                        <div>
                            {isValetCheckedsaida ? (
                                <>
                                </>
                            ) : (
                                <>
                                    <hr className="sub-linha" />
                                    <label>
                                        <input type="checkbox" className="all-checkbox" value="transfer" checked={isValetCheckedsaidaTransfer} onChange={handleCheckboxChangesaidaTransfer} ></input>
                                        <a className="label-do-campo">Transfer</a>
                                        <Tooltip title="Transfer é o transporte gratuito disponibilizado pela Indoor Parking Low Cost até ao aeroporto e vice-versa.">
                                            <AiOutlineQuestionCircle className='icon-question' />
                                        </Tooltip>
                                        <p className="info-valet-service">TRANSFER:<br /><a className="info-valet-service-label">Serviço de Transfer na zona de Partidas (Porta C).</a></p>
                                    </label>
                                    {numdePessoasTransferSaida && (
                                        <div className="col-horas-de-entrada">
                                            <svg id="Grupo_236" data-name="Grupo 236" width="35" height="35" viewBox="0 0 50.268 36.664">
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <rect id="Retângulo_499" data-name="Retângulo 499" width="50.268" height="36.664" fill="#abc132" />
                                                    </clipPath>
                                                </defs>
                                                <g id="Grupo_53" data-name="Grupo 53" clipPath="url(#clip-path)">
                                                    <path id="Caminho_100" data-name="Caminho 100" d="M67.207,38.147a9.735,9.735,0,0,0,3.744,7.714c-4.53,2.637-5.686,7.83-6.139,11.8a1.521,1.521,0,0,0,1.339,1.683,1.554,1.554,0,0,0,.174.01,1.521,1.521,0,0,0,1.509-1.349c.724-6.349,2.849-9.48,7.1-10.469a1.521,1.521,0,0,0,.1-2.937,6.748,6.748,0,1,1,3.924,0,1.521,1.521,0,0,0,.1,2.937c4.256.989,6.38,4.12,7.1,10.469a1.521,1.521,0,1,0,3.022-.344c-.453-3.975-1.609-9.168-6.139-11.8a9.794,9.794,0,1,0-15.835-7.714" transform="translate(-51.863 -22.695)" fill="#abc132" />
                                                    <path id="Caminho_101" data-name="Caminho 101" d="M155.095,5.246a6.749,6.749,0,1,1,6.951,11,1.521,1.521,0,0,0,.1,2.937c4.256.989,6.38,4.12,7.1,10.469a1.521,1.521,0,0,0,3.022-.345c-.453-3.975-1.609-9.168-6.139-11.8A9.8,9.8,0,1,0,152.847,3.2a1.521,1.521,0,0,0,2.248,2.049" transform="translate(-122.011)" fill="#abc132" />
                                                    <path id="Caminho_102" data-name="Caminho 102" d="M1.522,31a1.521,1.521,0,0,0,1.509-1.349c.724-6.349,2.849-9.48,7.1-10.469a1.521,1.521,0,0,0,.1-2.937,6.749,6.749,0,1,1,6.951-11A1.521,1.521,0,0,0,19.431,3.2,9.795,9.795,0,1,0,6.148,17.5c-4.53,2.637-5.686,7.83-6.139,11.8A1.522,1.522,0,0,0,1.522,31" transform="translate(0.001 0)" fill="#abc132" />
                                                </g>
                                            </svg>
                                            {/* <AutoComplete
                                                style={{
                                                    width: 72,
                                                    height: 48,
                                                }}
                                                options={filteredOptions.map(lugar => ({ value: lugar }))}
                                                optionLabelProp="label"
                                                value={numpessoassaida}
                                                placeholder="0"
                                                onSelect={handleSelectNumPessoasSaida}
                                            /> */}
                                            <Select
                                                style={{
                                                    width: 80,
                                                    height: 47,
                                                }}
                                                placeholder="0"
                                                onSelect={handleSelectNumPessoasSaida}
                                            >
                                                {filteredOptions.map((lugar) => (
                                                    <Select.Option
                                                        key={lugar}
                                                        value={lugar}
                                                    >
                                                        {lugar}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            <a className="label-do-campo">Nº Pessoas *</a>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`bloco-calendario-main-col`}>

                {openCalendario ? (
                    <>
                        <Calendario
                            validarHorasForm={validarHorasForm}
                            isTabletMobile={isTabletMobile}
                            erroNoInputDataEntrada={erroNoInputDataEntrada}
                            setErroNoInputDataEntrada={setErroNoInputDataEntrada}
                            listadeHorasRecolha={listadeHorasRecolha}
                            setListadeHorasRecolha={setListadeHorasRecolha}
                            listadeHorasEntrega={listadeHorasEntrega}
                            setListadeHorasEntrega={setListadeHorasEntrega}
                            setAnoSelecionadoEntradaParaHoras={setAnoSelecionadoEntradaParaHoras}
                            setMesSelecionadoParaHoras={setMesSelecionadoParaHoras}
                            setHorasEstaoDisponiveisRecolha={setHorasEstaoDisponiveisRecolha}
                            setHorasEstaoDisponiveisEntrega={setHorasEstaoDisponiveisEntrega}
                            setHoraRecolha={setHoraRecolha}
                            horaRecolha={horaRecolha}
                            setHoraEntrega={setHoraEntrega}
                            horaEntrega={horaEntrega}
                            produtosSelecionadosIds={produtosSelecionadosIds}
                            listadeProdutos={listadeProdutos}
                            espacoSelecionados={espacoSelecionados}
                            // isValetCheckedentrada={isValetCheckedentrada}
                            // isValetCheckedsaida={isValetCheckedsaida}
                            recolhaRef={recolhaRef}
                            calendarioData={calendarioData}
                            setOpenCalendario={setOpenCalendario}
                            openCalendarioentrega={openCalendarioentrega}
                            openCalendariosaida={openCalendariosaida}
                            setDataentrega={setDataentrega}
                            datarecolha={datarecolha}
                            setDatarecolha={setDatarecolha}
                            setOpenCalendarioEntrega={setOpenCalendarioEntrega}
                            setOpenCalendarioSaida={setOpenCalendarioSaida}
                            mesesentrada={mesesentrada}
                            setMesesEntrada={setMesesEntrada}
                            anoentrada={anoentrada}
                            setAnoEntrada={setAnoEntrada}
                            mesessaida={mesessaida}
                            setMesesSaida={setMesesSaida}
                            anosaida={anosaida}
                            setAnoSaida={setAnoSaida}
                            extrasSelecionados={extrasSelecionados}
                            precototaldareserva={precototaldareserva}
                            setPrecototaldareserva={setPrecototaldareserva}
                            diaSelecionado={diaSelecionado}
                            setDiaSelecionado={setDiaSelecionado}
                            diaSelecionadoSaida={diaSelecionadoSaida}
                            setDiaSelecionadoSaida={setDiaSelecionadoSaida}
                            dataentrega={dataentrega}
                            setPrecoDiaSelecionadoSaida={setPrecoDiaSelecionadoSaida}
                            setPrecoDiaSelecionado={setPrecoDiaSelecionado}
                            precodiaSelecionado={precodiaSelecionado}
                            precodiaSelecionadoSaida={precodiaSelecionadoSaida}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
        </>

    );
};

export default EntradaSaida;
