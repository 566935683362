import { gql } from '@apollo/client';

export const SLIDERDATA = gql`
  query {
    elementosWP(where: {id: 57}) {
      edges {
        node {
          sliderHome {
            imagemslider1 {
              altText
              sourceUrl
            }
            imagemslider2 {
              altText
              sourceUrl
            }
            imagemslider3 {
              altText
              sourceUrl
            }
            imagemslider1mobile {
              altText
              sourceUrl
            }
            imagemslider2mobile {
              altText
              sourceUrl
            }
            imagemslider3mobile {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

