
import { gql } from '@apollo/client';


export const DADOSPESSOAISID = gql`
query DADOSPESSOAIS($id: ID!) {
    reserva(id: $id, idType: DATABASE_ID) {
        reservaId
        date
        estadoDaReserva {
          estadodareserva
          motivoestadodareserva
          fieldGroupName
        }
        dadosDoCliente {
          primeiroNome
          ultimoNome
          email
          telemovel
          telemovelPais
        }
        espaco {
          espacoparque
        }
        detalhesDoVoo {
          nVooPartida
          nVooChegada
        }
        dadosViatura {
          matricula
          marca
          modelo
        }
        dadosdaEntradaeSaida {
          diaDeEntrada
          diaDeSaida
          horarioDeEntrada
          horarioDeSaida
          valetServiceEntrada
          valetServiceSaida
          transferEntrada
          transferSaida
        }
        extras {
          servicosdelimpeza
          extrasadic
          servicosextrasreserva
        }
        mensagem {
          mensagem
        }
        fotografiasdosCarros {
            fotografiascarros {
                mediaItemId
                sourceUrl
          }
            videocarros{
                mediaItemId
                mediaItemUrl
          }
        }
        localdeestacionamentodaviaturagroup {
            localdeestacionamentodaviatura
          }
        pagamentos {
            datadopagamento
            estadodopagamento
            meiodepagamento
          }
          dadoLogs {
            datadaultimaalteracao
          }
          descricaodoajustenareserva {
            descricaoDoAjusteNaReserva
          }
      }
  }
`;


