import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const TERMS = gql`
  query {
    pages(where: {id: 3}) {
        nodes {
          title
          content
        }
      }
  }
`;

const Terms = ({ }) => {
  const { loading, error, data } = useQuery(TERMS);

  const terms = data?.pages.nodes[0]


  if (loading) return
  if (error) return <p>Erro ao carregar: {error.message}</p>;

  const { menus } = data;


  return (
    <div className='pag-terms'>
      <h2>{terms.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: terms.content }} />
    </div>
  );
};

export default Terms;


