import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Button, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import { OBTERFAQS } from '../../data/obterFaqs';

const { Panel } = Collapse;

const Faqs = () => {

    const { loading, data } = useQuery(OBTERFAQS);
    const page = data?.elementosWP?.edges[0]?.node?.faqs
    // console.log(page)


    if (loading) {
        return
    }

    function createMarkup(html) {
        return { __html: html };
    }


    return (
        <div className='section-main faqs-main'>
            <div className='section-default-center'>
                <div className='coluna-default-left'>
                    <div className='coluna-sobre-nos-child'>
                        <h4 className='titulo-de-destaques'>FAQ's</h4>
                        <h1>Perguntas Frequentes</h1>
                        <Link className="menu-label" to="/faqs" onClick={() => smoothScrollToTop()}>
                            <Button type="primary" className="login-form-button-f-branco">
                                Ver mais
                            </Button>
                        </Link>
                    </div>
                    <div className='coluna-sobre-nos-child sobre-nos-descricao'>
                        <Collapse defaultActiveKey={['1']}>
                            <Panel header={page?.tituloFaq1} key="1">
                            <p dangerouslySetInnerHTML={createMarkup(page?.descricaoFaq1)} />
                            </Panel>
                            <Panel header={page?.tituloFaq2} key="2">
                            <p dangerouslySetInnerHTML={createMarkup(page?.descricaoFaq2)} />
                            </Panel>
                            <Panel header={page?.tituloFaq3} key="3">
                            <p dangerouslySetInnerHTML={createMarkup(page?.descricaoFaq3)} />
                            </Panel>
                            <Panel header={page?.tituloFaq4} key="4">
                            <p dangerouslySetInnerHTML={createMarkup(page?.descricaoFaq4)} />
                            </Panel>
                            <Panel header={page?.tituloFaq5} key="5">
                            <p dangerouslySetInnerHTML={createMarkup(page?.descricaoFaq5)} />
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Faqs;


function smoothScrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}