import React, { useEffect, useRef, useState } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { AuthProvider } from './context/authcontext';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './index.css';
import './style/elementos.css';
import './style/calendario.css';
import './style/slider.css';
import './style/loading.css';
import './style/responsive.css';
import Header from "./components/elementos-wp/header";
import Footer from './components/elementos-wp/footer';
import Form from "./pages/form";
import RegistodoPedido from "./pages/registodopedido";
import Home from "./pages/home";
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Pagamentos from './components/componente-pagamentos/pagamentos';
import ErroPagina from "./pages/erro404";
import Terms from './pages/terms';
import { useMediaQuery } from 'react-responsive'
import FAQsPage from './pages/faqs';
import FormMarcacaoRapida from './pages/formmarcacaorapida';


//Chamada à API GraphQL 
const client = new ApolloClient({
  uri: 'https://app.indoorparkinglowcost.pt/00fa8d6b-1a47-4f13-8a8d-3adcd9c4a42d', //URI (Uniform Resource Identifier) onde a API GraphQL está localizada
  cache: new InMemoryCache(),
});


// 00fa8d6b-1a47-4f13-8a8d-3adcd9c4a42d - NOME QUE ESCOLHI

// o uso do cache é uma prática recomendada ao utilizar o Apollo Client, 
// melhora o desempenho e reduz a carga no servidor GraphQL, permitindo que dados frequentemente acessados sejam recuperados localmente a partir do cache.


const App = () => {

  //Responsive
  const isTabletMobile = useMediaQuery({
    query: '(max-width: 1024px)'
  })

  const [domainOrigin, setDomainOrigin] = useState(window.location.origin);

  const [cliquenoParqueHome, setCliquenoParqueHome] = useState(false); // validar o clique na homepage no parque exterior fica com o parque exterior selecionado
  const [isAuthenticated, setIsAuthenticated] = useState(false); //Saber se está logado ou não
  // const [userName, setUserName] = useState(""); //Guarda o nome do user
  const [userData, setUserData] = useState(null);
  const [manterDadosPessoais, setManterDadosPessoais] = useState(false); //Check para passar os dados pessoais para os dados de Pagamento
  const [listadeProdutos, setListadeProdutos] = useState(
    cliquenoParqueHome ?
      [{
        product_id: 195,
        quantity: 1
      }] : [{
        product_id: 213,
        quantity: 1
      }]
  );//Para colocar a reserva a iniciar com o Parque Interior por defeito

  const [idDaOrderSave, SetIdDaOrderSave] = useState(''); //Guardar o id do checkout da reserva
  const [idDoPostSave, SetIdDoPostSave] = useState(''); //Guardar o id da reserva 



  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setIsAuthenticated(foundUser);
    }
  }, []);



  const aboutUsRef = useRef(null);
  const servicesRef = useRef(null);
  const howItWorksRef = useRef(null);
  const faqsRef = useRef(null);
  const testimonialsRef = useRef(null);


  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <div>
            {/* <Header setCliquenoParqueHome={setCliquenoParqueHome} domainOrigin={domainOrigin} isTabletMobile={isTabletMobile} isAuthenticated={isAuthenticated} userName={userName} userData={userData} /> */}
            <Header
              setCliquenoParqueHome={setCliquenoParqueHome}
              domainOrigin={domainOrigin}
              isAuthenticated={isAuthenticated}
              userData={userData}
              isTabletMobile={isTabletMobile}
              aboutUsRef={aboutUsRef}
              servicesRef={servicesRef}
              howItWorksRef={howItWorksRef}
              faqsRef={faqsRef}
              testimonialsRef={testimonialsRef}
            />
            <Routes>
              <Route exact path="/" element={
                <Home
                  isTabletMobile={isTabletMobile}
                  setCliquenoParqueHome={setCliquenoParqueHome}
                  aboutUsRef={aboutUsRef}
                  servicesRef={servicesRef}
                  howItWorksRef={howItWorksRef}
                  faqsRef={faqsRef}
                  testimonialsRef={testimonialsRef}
                />} />
              <Route exact path="/formulario" element={<Form isTabletMobile={isTabletMobile} cliquenoParqueHome={cliquenoParqueHome} domainOrigin={domainOrigin} SetIdDoPostSave={SetIdDoPostSave} idDoPostSave={idDoPostSave} SetIdDaOrderSave={SetIdDaOrderSave} idDaOrderSave={idDaOrderSave} manterDadosPessoais={manterDadosPessoais} setManterDadosPessoais={setManterDadosPessoais} listadeProdutos={listadeProdutos} setListadeProdutos={setListadeProdutos} />} />
              <Route exact path="/formulario/:id" element={<Form isTabletMobile={isTabletMobile} cliquenoParqueHome={cliquenoParqueHome} SetIdDoPostSave={SetIdDoPostSave} SetIdDaOrderSave={SetIdDaOrderSave} domainOrigin={domainOrigin} manterDadosPessoais={manterDadosPessoais} setManterDadosPessoais={setManterDadosPessoais} listadeProdutos={listadeProdutos} setListadeProdutos={setListadeProdutos} />} />
              <Route exact path="/dados-pagamento/reserva/:id" element={<Pagamentos idDoPostSave={idDoPostSave} idDaOrderSave={idDaOrderSave} listadeProdutos={listadeProdutos} manterDadosPessoais={manterDadosPessoais} setManterDadosPessoais={setManterDadosPessoais} />} />
              <Route exact path="/reserva/:id" element={<RegistodoPedido isTabletMobile={isTabletMobile} />} />
              <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/dashboard" element={<Dashboard isTabletMobile={isTabletMobile} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/registar" element={<Register />} />
              <Route path="/faqs" element={<FAQsPage />} />
              <Route path="*" element={<ErroPagina />} />
              <Route path="/termos-e-condicoes" element={<Terms />} />
              <Route path="/marcacao-rapida" element={<FormMarcacaoRapida isTabletMobile={isTabletMobile} cliquenoParqueHome={cliquenoParqueHome} domainOrigin={domainOrigin} SetIdDoPostSave={SetIdDoPostSave} idDoPostSave={idDoPostSave} SetIdDaOrderSave={SetIdDaOrderSave} idDaOrderSave={idDaOrderSave} manterDadosPessoais={manterDadosPessoais} setManterDadosPessoais={setManterDadosPessoais} listadeProdutos={listadeProdutos} setListadeProdutos={setListadeProdutos}  />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;


