import { gql } from '@apollo/client';

export const SERVICOSDATA = gql`
    query {
        elementosWP(where: {id: 52}) {
        edges {
            node {
                servicos {
                tituloServicos
                titulodestaqueServicos
                tituloServicosum
                descricaoServicosum
                imagemServicosum {
                    sourceUrl
                }
                }
            }
        }
    }
  }
`;
