import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const MensagemObservacao = ({ termoseCondicoes, mensagemobs, setMensagemObs, manterDadosPessoais, setManterDadosPessoais, setTermoseCondicoes }) => {


    const handleCheckboxDadosPessoais = (e) => {
        setManterDadosPessoais(e.target.checked);
    };

    const handleCheckboxTermoseCondicoes = (e) => {
        setTermoseCondicoes(!termoseCondicoes);
    };

    return (
        <div>
            <div className="flex-col-titulo">
                <h2>MENSAGEM <span>/</span> OBSERVAÇÕES</h2>
            </div>
            <hr className="linha-principal" />
            <textarea
                placeholder='Deixe aqui a sua mensagem ou observação…'
                rows="5"
                cols="33"
                value={mensagemobs}
                onChange={(e) => setMensagemObs(e.target.value)}
            ></textarea>
            <br />

            <div className='flex-col aceiteostermos'>
                <label>
                    <input type="checkbox" className="all-checkbox" value="Dados Pessoais" onChange={handleCheckboxDadosPessoais}></input>
                    Manter os Dados Pessoais nos Dados de Pagamento?
                </label>
            </div>

            <div>
                <label>
                    <input type="checkbox" className="all-checkbox" value="Termos e Condições" onChange={handleCheckboxTermoseCondicoes}></input>
                    Aceite os nossos <Link className="terms" to="/termos-e-condicoes" target="_blank">Termos e Condições</Link> *
                </label>
            </div>
        </div>
    );
};

export default MensagemObservacao;
