import { BiCheckCircle } from "react-icons/bi";


export function SucessoMsg(props) {
    return (
        <div className="loading-sec-baixo mensagem-de-sucesso fundo-branco msg-sec">
            <BiCheckCircle />
            <span> Sucesso, {props.mensagem}</span >
        </div>
    )

}
