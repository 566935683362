import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/less';

const VANTAGENS = gql`
  query {
    elementosWP(where: {id: 49}) {
      edges {
          node {
            servicos {
              tituloServicos
              titulodestaqueServicos
              tituloServicosum
              descricaoServicosum
              imagemServicosum {
                sourceUrl
              }
            }
            }
      }
    }
  }
`;

const Vantagens = () => {
  const { loading, error, data } = useQuery(VANTAGENS);
  const page = data?.page;

  const slidesPerView = window.innerWidth <= 1000 ? 2 : 3;


  if (loading) return
  if (error) return <p>Erro ao carregar: {error.message}</p>;

  return (
    <div className='vantagens-main'>
      <div className='section-default-center-dois'>

        <Swiper
          modules={[Autoplay, A11y]}
          spaceBetween={20}
          slidesPerView={slidesPerView}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          
        >
          <SwiperSlide>
            <div className='coluna-vantagens-child'>
              <h3>Maior comodidade</h3>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='coluna-vantagens-child'>
              <h3>Maior segurança</h3>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='coluna-vantagens-child'>
              <h3>Melhor preço</h3>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='coluna-vantagens-child'>
              <h3>Transfer gratuito</h3>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='coluna-vantagens-child'>
              <h3>Serviço valet</h3>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Vantagens;
