import React from 'react';
import { Table, Tooltip } from 'antd';
import { AiOutlineEuroCircle } from "react-icons/ai";


const ListadeServicosAdicionais = ({
    extraDataServicosReserva,
    isTabletMobile,
}) => {

    // console.log(extraDataServicosReserva)

    return (

        <div>
            <h2>SERVIÇOS ADICIONAIS À RESERVA:</h2>
            <br></br>
            <div className="flex-resumo-page">
                <div className="sec-estadopagamento-serextrares">
                    {isTabletMobile ? (
                        <>
                            <Table
                                dataSource={extraDataServicosReserva}
                                pagination={{
                                    pageSize: 3,
                                    position: ["bottomCenter"],
                                }}
                            >
                                <Table.Column
                                    title="Pago / Descrição"
                                    dataIndex="pago"
                                    key="pago"
                                    render={(pago, record) => (
                                        <>
                                            <div className='flex servicos-adicionais-reserva'>
                                                {pago === "0" ? (
                                                    <Tooltip className='flex label-servicos-adicionais-reserva' title="Serviço não pago">
                                                        <span className="extraservico-nao-pago"><AiOutlineEuroCircle /></span>Não Pago
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip className='flex label-servicos-adicionais-reserva' title="Serviço pago">
                                                        <span className="extraservico-pago"><AiOutlineEuroCircle /></span>Pago
                                                    </Tooltip>
                                                )}
                                                {record.description}
                                            </div>
                                        </>
                                    )}
                                />
                            </Table>
                        </>
                    ) : (
                        <>
                            <Table
                                dataSource={extraDataServicosReserva}
                                pagination={{
                                    pageSize: 3,
                                    position: ["bottomCenter"],
                                }}
                            >
                                <Table.Column
                                    title="Pago"
                                    dataIndex="pago"
                                    key="pago"
                                    render={(pago) => (
                                        <>
                                            {pago === "0" ? (
                                                <Tooltip className='flex' title="Serviço não pago">
                                                    <span className="extraservico-nao-pago"><AiOutlineEuroCircle /></span>Não Pago
                                                </Tooltip>
                                            ) : (
                                                <Tooltip className='flex'  title="Serviço pago">
                                                    <span className="extraservico-pago"><AiOutlineEuroCircle /></span>Pago
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                />
                                <Table.Column
                                    title="Descrição do Serviço adicional"
                                    dataIndex="description"
                                    key="description"
                                    render={(description) => (
                                        <>
                                            {description}
                                        </>
                                    )}
                                />
                            </Table>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListadeServicosAdicionais;


