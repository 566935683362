import React from 'react';
import { Tabs, Table } from 'antd';
import { Link } from 'react-router-dom';

const TabDashboardAdmin = ({ isTabletMobile, searchFilterDay, searchFilterSempre, searchQuery, handleInputChange, smoothScrollToTop }) => {

    

    return (
        <Tabs>


            <Tabs.TabPane tab="Reservas de Hoje" key="1">
                <div>
                    <input
                        type="text"
                        className="input-geral"
                        placeholder="Pesquisar por id da reserva, nome ou matrícula..."
                        value={searchQuery}
                        onChange={handleInputChange}
                    />
                </div>
                <br />

                {isTabletMobile ? (
                    <>
                        {searchFilterDay.length > 0 ? (
                            <Table
                                dataSource={searchFilterDay}
                                rowKey={(reservation) => reservation.ID}
                                pagination={{
                                    pageSize: 10,
                                    position: ["bottomCenter"],
                                }}
                            >
                                <Table.Column
                                    title="Dados da Viatura / ID / Estado / Nome / Contacto / Datas "
                                    dataIndex="id"
                                    key="id"
                                    render={(id, reservation) => (
                                        <>
                                            <Link className="link-label-dashboard" to={`/reserva/${reservation.post_name}`} onClick={() => smoothScrollToTop()}>
                                                <strong>Viatura:</strong> {reservation.acf.marca} {reservation.acf.modelo} | {reservation.acf.matricula}
                                                <br></br>
                                                <span className="link-label">#{reservation.ID}</span> - <span className={`estado-reserva ${reservation.acf.estadodareserva}`}>{reservation.acf.estadodareserva}</span>
                                                <br></br>
                                                <strong>Nome:</strong> {reservation.acf.primeiro_nome} {reservation.acf.ultimo_nome}
                                                <br></br>
                                            </Link>
                                            <strong>Contacto:</strong> <a className='link-label' href={`tel:${reservation.acf.telemovel}`}>{reservation.acf.telemovel}</a>
                                            <Link className="link-label-dashboard" to={`/reserva/${reservation.post_name}`} onClick={() => smoothScrollToTop()}>
                                                <br></br>
                                                <strong>Entrada:</strong> {reservation.acf.dia_de_entrada} - {reservation.acf.horario_de_entrada}
                                                <br></br>
                                                <strong>Recolha:</strong> {reservation.acf.dia_de_saida} - {reservation.acf.horario_de_saida}
                                            </Link>
                                        </>
                                    )}
                                />
                                {/* <Table.Column
                                    title="Nome"
                                    key="nome"
                                    render={(reservation) => (
                                        `${reservation.acf.primeiro_nome} ${reservation.acf.ultimo_nome}`
                                    )}
                                />
                                <Table.Column
                                    title="Entrada"
                                    key="dataentrada"
                                    render={(reservation) => (
                                        `${reservation.acf.dia_de_entrada} - ${reservation.acf.horario_de_entrada}`
                                    )}
                                />
                                <Table.Column
                                    title="Saída"
                                    key="datasaida"
                                    render={(reservation) => (
                                        `${reservation.acf.dia_de_saida} - ${reservation.acf.horario_de_saida}`
                                    )}
                                />
                                <Table.Column
                                    title="Carro"
                                    key="marcaematricula"
                                    render={(reservation) => (
                                        `${reservation.acf.marca} ${reservation.acf.modelo} | ${reservation.acf.matricula}`
                                    )}
                                />
                                <Table.Column
                                    title="Estado"
                                    key="estado"
                                    render={(reservation) => (
                                        <span className={`estado-reserva ${reservation.acf.estadodareserva.toLowerCase()}`}>
                                            {reservation.acf.estadodareserva}
                                        </span>
                                    )}
                                /> */}
                            </Table>
                        ) : (
                            <p>Nenhuma reserva encontrada.</p>
                        )}
                    </>
                ) : (
                    <>
                        {searchFilterDay.length > 0 ? (
                            <Table
                                dataSource={searchFilterDay}
                                rowKey={(reservation) => reservation.ID}
                                pagination={{
                                    pageSize: 10,
                                    position: ["bottomCenter"],
                                }}
                            >
                                <Table.Column
                                    title="ID"
                                    dataIndex="id"
                                    key="id"
                                    render={(ID, reservation) => (
                                        <Link className="link-label" to={`/reserva/${reservation.post_name}`} onClick={() => smoothScrollToTop()}>
                                            #{reservation.ID}
                                        </Link>
                                    )}
                                />
                                <Table.Column
                                    title="Nome"
                                    key="nome"
                                    render={(reservation) => (
                                        `${reservation.acf.primeiro_nome} ${reservation.acf.ultimo_nome}`
                                    )}
                                />
                                <Table.Column
                                    title="Entrada"
                                    key="dataentrada"
                                    render={(reservation) => (
                                        `${reservation.acf.dia_de_entrada} - ${reservation.acf.horario_de_entrada}`
                                    )}
                                />
                                <Table.Column
                                    title="Saída"
                                    key="datasaida"
                                    render={(reservation) => (
                                        `${reservation.acf.dia_de_saida} - ${reservation.acf.horario_de_saida}`
                                    )}
                                />
                                <Table.Column
                                    title="Carro"
                                    key="marcaematricula"
                                    render={(reservation) => (
                                        `${reservation.acf.marca} ${reservation.acf.modelo} | ${reservation.acf.matricula}`
                                    )}
                                />
                                <Table.Column
                                    title="Estado"
                                    key="estado"
                                    render={(reservation) => (
                                        <span className={`estado-reserva ${reservation.acf.estadodareserva}`}>
                                            {reservation.acf.estadodareserva}
                                        </span>
                                    )}
                                />
                            </Table>
                        ) : (
                            <p>Nenhuma reserva encontrada.</p>
                        )}
                    </>
                )}

            </Tabs.TabPane>



            <Tabs.TabPane tab="Todas as Reservas" key="2">
                <div>
                    <input
                        type="text"
                        className="input-geral"
                        placeholder="Pesquisar por id da reserva, nome ou matrícula..."
                        value={searchQuery}
                        onChange={handleInputChange}
                    />
                </div>
                <br />
                {isTabletMobile ? (
                    <>
                        {searchFilterSempre.length > 0 ? (

                            <Table
                                dataSource={searchFilterSempre}
                                rowKey={(reservation) => reservation.id}
                                pagination={{
                                    pageSize: 10,
                                    position: ["bottomCenter"],
                                }}
                            >
                                <Table.Column
                                    title="ID / Estado / Nome / Datas / Dados da Viatura"
                                    dataIndex="id"
                                    key="id"
                                    render={(id, reservation) => (
                                        <>
                                            <Link className="link-label-dashboard" to={`/reserva/${reservation.post_name}`} onClick={() => smoothScrollToTop()}>
                                                <span className="link-label">#{reservation.ID}</span> - <span className={`estado-reserva ${reservation.acf.estadodareserva}`}>{reservation.acf.estadodareserva}</span>
                                                <br></br>
                                                <strong>Nome:</strong> {reservation.acf.primeiro_nome} {reservation.acf.ultimo_nome}
                                                <br></br>
                                                <strong>Entrada:</strong> {reservation.acf.dia_de_entrada} - {reservation.acf.horario_de_entrada}
                                                <br></br>
                                                <strong>Recolha:</strong> {reservation.acf.dia_de_saida} - {reservation.acf.horario_de_saida}
                                                <br></br>
                                                <strong>Viatura:</strong> {reservation.acf.marca} {reservation.acf.modelo} | {reservation.acf.matricula}
                                            </Link>
                                        </>
                                    )}
                                />
                                {/* <Table.Column
                                    title="Nome"
                                    key="nome"
                                    render={(reservation) => (
                                        `${reservation.acf.primeiro_nome} ${reservation.acf.ultimo_nome}`
                                    )}
                                />
                                <Table.Column
                                    title="Entrada"
                                    key="dataentrada"
                                    render={(reservation) => (
                                        `${reservation.acf.dia_de_entrada} - ${reservation.acf.horario_de_entrada}`
                                    )}
                                />
                                <Table.Column
                                    title="Saída"
                                    key="datasaida"
                                    render={(reservation) => (
                                        `${reservation.acf.dia_de_saida} - ${reservation.acf.horario_de_saida}`
                                    )}
                                />
                                <Table.Column
                                    title="Carro"
                                    key="marcaematricula"
                                    render={(reservation) => (
                                        `${reservation.acf.marca} ${reservation.acf.modelo} | ${reservation.acf.matricula}`
                                    )}
                                />
                                <Table.Column
                                    title="Estado"
                                    key="estado"
                                    render={(reservation) => (
                                        <span className={`estado-reserva ${reservation.acf.estadodareserva.toLowerCase()}`}>
                                            {reservation.acf.estadodareserva}
                                        </span>
                                    )}
                                /> */}
                            </Table>
                        ) : (
                            <p>Nenhuma reserva encontrada.</p>
                        )}
                    </>
                ) : (
                    <>
                        {searchFilterSempre.length > 0 ? (

                            <Table
                                dataSource={searchFilterSempre}
                                rowKey={(reservation) => reservation.ID}
                                pagination={{
                                    pageSize: 10,
                                    position: ["bottomCenter"],
                                }}
                            >
                                <Table.Column
                                    title="ID"
                                    dataIndex="id"
                                    key="id"
                                    render={(ID, reservation) => (
                                        <Link className="link-label" to={`/reserva/${reservation.post_name}`} onClick={() => smoothScrollToTop()}>
                                            #{reservation.ID}
                                        </Link>
                                    )}
                                />
                                <Table.Column
                                    title="Nome"
                                    key="nome"
                                    render={(reservation) => (
                                        `${reservation.acf.primeiro_nome} ${reservation.acf.ultimo_nome}`
                                    )}
                                />
                                <Table.Column
                                    title="Entrada"
                                    key="dataentrada"
                                    render={(reservation) => (
                                        `${reservation.acf.dia_de_entrada} - ${reservation.acf.horario_de_entrada}`
                                    )}
                                />
                                <Table.Column
                                    title="Saída"
                                    key="datasaida"
                                    render={(reservation) => (
                                        `${reservation.acf.dia_de_saida} - ${reservation.acf.horario_de_saida}`
                                    )}
                                />
                                <Table.Column
                                    title="Carro"
                                    key="marcaematricula"
                                    render={(reservation) => (
                                        `${reservation.acf.marca} ${reservation.acf.modelo} | ${reservation.acf.matricula}`
                                    )}
                                />
                                <Table.Column
                                    title="Estado"
                                    key="estado"
                                    render={(reservation) => (
                                        <span className={`estado-reserva ${reservation.acf.estadodareserva}`}>
                                            {reservation.acf.estadodareserva}
                                        </span>
                                    )}
                                />
                            </Table>
                        ) : (
                            <p>Nenhuma reserva encontrada.</p>
                        )}
                    </>
                )}

            </Tabs.TabPane>


        </Tabs>
    );
};

export default TabDashboardAdmin;
