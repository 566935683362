
export const indicativosTelefone = [
    {
        value: '+351',
        label: '🇵🇹 +351',
    },
    {
        value: '+34',
        label: '🇪🇦 +34',
    },
    {
        value: '+33',
        label: '🇫🇷 +33',
    },
    {
        value: '+44',
        label: '🇬🇧 +44',
    }
];