import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Spin } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { LoadingOutlined, MailOutlined } from '@ant-design/icons';
import { BiError } from "react-icons/bi";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
    }}
    spin
  />
);

function Register({ }) {

  const navigate = useNavigate();


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [loginRealizado, setLoginRealizado] = useState(false);
  const [loginErro, setLoginErro] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);


  const handleRegister = async () => {

    setLoginLoading(true)

    try {
      const response = await axios.post(
        'https://app.indoorparkinglowcost.pt/wp-json/custom-login/register',
        {
          username,
          password,
          email,
        }
      );

      if (response.status === 200 && response.data.success) {

        setLoginRealizado(true)
        setLoginLoading(false)
        setLoginErro(false)
        setTimeout(() => {
          navigate("/login");
        }, 2500);
        // console.log(response)
      } else {
        setLoginErro(true)
        setLoginRealizado(false)
        // console.log(response)
      }
    } catch (error) {
      console.error('Erro', error.message);
      setLoginLoading(false)
      setLoginErro(true)
      setLoginRealizado(false)
    }
  };

  return (
    <>
      <div className="sec-geral-top login-section">
        <div className="form main">
          <div>
            <h1>Registar conta</h1>
            <br />
            <hr className="linha-principal" />
          </div>
          <div>
            <Form className="login-register" onFinish={handleRegister}>
              <p className='label-do-campo'>Utilizador</p>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, introduza o seu Nome de Utilizador!',
                  },
                ]}
              >
                <Input
                  className="input-geral"
                  value={username}
                  autoComplete="username"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Utilizador"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>
              <p className='label-do-campo'>Password</p>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, introduza a sua Password!',
                  },
                ]}
              >
                <Input.Password
                  className="input-geral"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  autoComplete="current-password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <p className='label-do-campo'>Email</p>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, introduza o seu e-mail!',
                  },
                  {
                    type: 'email',
                    message: 'Por favor, introduza um e-mail válido!',
                  },
                ]}
              >
                <Input
                  className="input-geral"
                  value={email}
                  placeholder="Email"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <br />
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Registar
                </Button>
              </Form.Item>
              <div className="link-registo">
                <p className='label-do-campo'>Já tenho conta Indoor. <Link className="label-link-verde" to="/login">Login!</Link></p>
              </div>
            </Form>
            {loginLoading &&
              <>
                <div className="mensagem-de-loading">
                  <p>A carregar...</p>
                  <Spin indicator={antIcon} />
                </div>
              </>
            }
            {loginRealizado ? (
              <>
                <div className="loading-sec-baixo mensagem-de-sucesso fundo-branco">
                  <p>Registo realizado com Sucesso! Aguarde...</p>
                  <Spin indicator={antIcon} />
                </div>
              </>
            ) : loginErro ? (
              <div className="mensagem-de-erro">
                <p>Ocorreu algum erro, tente novamente!</p>
                <BiError />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
