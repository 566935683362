import React from 'react';


const Espaco = ({ espacoSelecionados, setEspacoSelecionados, listadeProdutos, setListadeProdutos }) => {

    const handleCheckboxChange = (e) => {
        const { value } = e.target;
        const productId = parseInt(e.target.dataset.id);
    

        const updatedListadeProdutos = listadeProdutos.filter(
            (product) => product.product_id !== 489 && product.product_id !== 625
        );
    

        updatedListadeProdutos.push({ product_id: productId, quantity: 1 });
    
        setListadeProdutos(updatedListadeProdutos);
        setEspacoSelecionados(value);
        // console.log('espacoSelecionados',espacoSelecionados)
    };
 

    return (
        <div className="sec-espaco">
            <div className="flex-col-titulo">
                <h2>Espaço</h2>
                {/* <span className="label-info">(É obrigatório escolher uma das opções)</span> */}
            </div>
            <hr className="linha-principal" />
            <div className="flex-col-espaco">
                <div className="flex-col">
                    <label className="rad-label">
                        <input type="radio" id="parque-interior" data-id="489" className="rad-input" name="espaço" value="Parque Interior" onChange={handleCheckboxChange} checked={espacoSelecionados.includes("Parque Interior")} />
                        <div className="rad-design"></div>
                        <svg id="Grupo_231" data-name="Grupo 231" width="30" height="30" viewBox="0 0 34.491 35">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Retângulo_497" data-name="Retângulo 497" width="34.491" height="35" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                </clipPath>
                            </defs>
                            <g id="Grupo_36" data-name="Grupo 36" transform="translate(0 0)" clipPath="url(#clip-path)">
                                <path id="Caminho_95" data-name="Caminho 95" d="M31.159,39.068H25.78a2.066,2.066,0,0,1-2.064-2.062v-1.14H12.744V37a2.066,2.066,0,0,1-2.064,2.064H5.3A2.066,2.066,0,0,1,3.236,37V32.036a6.28,6.28,0,0,1,6.273-6.273H26.95a6.28,6.28,0,0,1,6.273,6.273V37a2.067,2.067,0,0,1-2.064,2.064M11,34.127h14.45v2.879a.324.324,0,0,0,.325.323h5.379A.325.325,0,0,0,31.484,37V32.036A4.539,4.539,0,0,0,26.95,27.5H9.509a4.539,4.539,0,0,0-4.534,4.534V37a.325.325,0,0,0,.324.325h5.38A.325.325,0,0,0,11,37Z" transform="translate(-0.984 -7.838)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <rect id="Retângulo_494" data-name="Retângulo 494" width="4.499" height="1.739" transform="translate(6.543 21.728)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <path id="Caminho_96" data-name="Caminho 96" d="M31.455,23.609V21.87a.669.669,0,1,0,0-1.339H27.8L26.359,16.41a3.528,3.528,0,0,0-3.326-2.36h-10.6a3.528,3.528,0,0,0-3.326,2.36L7.665,20.531H4.012a.669.669,0,0,0,0,1.339v1.739a2.409,2.409,0,0,1,0-4.818H6.43l1.035-2.957a5.268,5.268,0,0,1,4.967-3.525h10.6A5.268,5.268,0,0,1,28,15.835l1.035,2.957h2.419a2.409,2.409,0,0,1,0,4.818" transform="translate(-0.488 -3.745)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <rect id="Retângulo_495" data-name="Retângulo 495" width="4.499" height="1.739" transform="translate(23.448 21.728)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <path id="Caminho_97" data-name="Caminho 97" d="M33.649,11.061l-16.4-9.073L.843,11.061,0,9.539,17.246,0,34.491,9.539Z" transform="translate(0 0)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <rect id="Retângulo_496" data-name="Retângulo 496" width="33.648" height="1.739" transform="translate(0.422 33.261)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                            </g>
                        </svg>
                        <div className="rad-text label-do-campo">Parque Interior *</div>
                    </label>
                </div>
                <div className="flex-col">
                    <label className="rad-label">
                        <input type="radio" id="parque-exterior" data-id="625" className="rad-input" name="espaço" value="Parque Exterior" onChange={handleCheckboxChange} checked={espacoSelecionados.includes("Parque Exterior")} />
                        <div className="rad-design"></div>
                        <svg id="Grupo_232" data-name="Grupo 232" width="30" height="30" viewBox="0 0 34.491 35">
                            <defs>
                                <clipPath id="clip-path-2">
                                    <path id="Caminho_128" data-name="Caminho 128" d="M0,0H34.491V35H0Z" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                </clipPath>
                            </defs>
                            <g id="Grupo_de_máscara_1" data-name="Grupo de máscara 1" transform="translate(0 0)" clipPath="url(#clip-path-2)">
                                <path id="Caminho_95" data-name="Caminho 95" d="M31.158,39.068H25.78a2.066,2.066,0,0,1-2.064-2.062v-1.14H12.744V37a2.066,2.066,0,0,1-2.064,2.064H5.3A2.066,2.066,0,0,1,3.236,37V32.036a6.28,6.28,0,0,1,6.273-6.273H26.95a6.28,6.28,0,0,1,6.273,6.273V37a2.067,2.067,0,0,1-2.064,2.064M11,34.127h14.45v2.879a.324.324,0,0,0,.325.323h5.379A.325.325,0,0,0,31.483,37V32.036A4.539,4.539,0,0,0,26.95,27.5H9.509a4.539,4.539,0,0,0-4.534,4.534V37a.325.325,0,0,0,.324.325h5.38A.325.325,0,0,0,11,37Z" transform="translate(-0.985 -7.838)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <rect id="Retângulo_494" data-name="Retângulo 494" width="4.499" height="1.739" transform="translate(6.543 21.728)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <path id="Caminho_96" data-name="Caminho 96" d="M31.455,23.609V21.87a.669.669,0,0,0,0-1.339H27.8l-1.443-4.122a3.528,3.528,0,0,0-3.326-2.36h-10.6a3.528,3.528,0,0,0-3.326,2.36L7.665,20.531H4.012a.669.669,0,0,0,0,1.339v1.739a2.409,2.409,0,0,1,0-4.818H6.43l1.035-2.957a5.268,5.268,0,0,1,4.967-3.525h10.6A5.268,5.268,0,0,1,28,15.835l1.035,2.957h2.419a2.409,2.409,0,0,1,0,4.818" transform="translate(-0.488 -3.745)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <rect id="Retângulo_495" data-name="Retângulo 495" width="4.499" height="1.739" transform="translate(23.448 21.728)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                <rect id="Retângulo_496" data-name="Retângulo 496" width="33.648" height="1.739" transform="translate(0.422 33.261)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                            </g>
                        </svg>
                        <div className="rad-text label-do-campo">Parque Exterior *</div>
                    </label>
                </div>
            </div>


        </div>
    );
};

export default Espaco;
