import React, { useEffect, useState } from "react";
import { useQuery, gql } from '@apollo/client';
import { DADOSPESSOAIS } from '../data/obterReserva';
import EntradaSaida from "../components/componentes-calendario/entradasaida";
import Espaco from "../components/componentes-calendario/espaco";
import DadosPessoais from "../components/componentes-calendario/dadospessoais";
import DetalhesdoVoo from "../components/componentes-calendario/detalhesdovoo";
import DadosReserva from "../components/componentes-calendario/dadosreserva";
import Extras from "../components/componentes-calendario/extras";
import ServicosLimpeza from "../components/componentes-calendario/servicoslimpeza";
import MensagemObservacao from "../components/componentes-calendario/mensagemobservacao";
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { BiError, BiCheckCircle } from "react-icons/bi";
import axios from 'axios';
import { Button, Spin } from 'antd';
import { SucessoMsg } from "../components/mensagens-alertas/mensagemdesucesso";
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


const Form = ({ isTabletMobile, cliquenoParqueHome, domainOrigin, idDoPostSave, SetIdDoPostSave, idDaOrderSave, SetIdDaOrderSave, listadeProdutos = [], setListadeProdutos, userRole, userData, isAuthenticated, manterDadosPessoais, setManterDadosPessoais }) => {


    const { id } = useParams();
    const navigate = useNavigate();

    const [isValidId, setIsValidId] = useState(false); // Estado para verificar a validade do ID

    const { data } = useQuery(DADOSPESSOAIS, {
        variables: { id },
    });

    const dadodareserva = data?.reservas

    useEffect(() => {
        if (data && data.reservas) {
            setIsValidId(true);
            setNome(dadodareserva.primeiroNome)
            setSobrenome(dadodareserva.ultimoNome)
            setTelemovel(dadodareserva.telemovel)
            setTelemovelPais(dadodareserva.telemovelPais);
            setEmail(dadodareserva.email)
            setEspacoSelecionados(dadodareserva.espacoparque)
            setMatricula(dadodareserva.matricula)
            setMarcaDoCarro(dadodareserva.marca)
            setModelo(dadodareserva.modelo)
            setIsValetCheckedentrada(dadodareserva.valetServiceEntrada)
            setIsValetCheckedsaida(dadodareserva.valetServiceSaida)
            setNumpessoasentrada(dadodareserva.transferEntrada)
            setNumpessoassaida(dadodareserva.transferSaida)
        } else {
            setIsValidId(false);
        }
    }, [data]);


    const [openCalendarioentrega, setOpenCalendarioEntrega] = useState(false);
    const [openCalendariosaida, setOpenCalendarioSaida] = useState(false);
    const [nome, setNome] = useState(""); // Nome
    const [sobrenome, setSobrenome] = useState(""); // Sobrenome
    const [telemovel, setTelemovel] = useState(""); // Telemovel
    const [telemovelPais, setTelemovelPais] = useState(""); // Telemovel Pais
    const [email, setEmail] = useState(''); // Email  
    const [horaEntrega, setHoraEntrega] = useState('00'); //Hora de Entrada

    const [listadeHorasEntrega, setListadeHorasEntrega] = useState([]); //LISTA de Horas de Entrada
    const [listadeHorasRecolha, setListadeHorasRecolha] = useState([]); //LISTA de Horas de Entrada

    const [minutoEntrega, setMinutoEntrega] = useState('10'); //Minuto de Entrega
    const [horaRecolha, setHoraRecolha] = useState('00'); //Hora de Recolha
    const [minutoRecolha, setMinutoRecolha] = useState('10'); //Minuto de Recolha
    const [espacoSelecionados, setEspacoSelecionados] = useState(
        cliquenoParqueHome ? 'Parque Exterior' : 'Parque Interior'
    );

    const [matricula, setMatricula] = useState([]); // Matricula
    const [marcadocarro, setMarcaDoCarro] = useState(''); // Marca do Carro
    const [modelo, setModelo] = useState([]); // Modelo
    const [voopartida, setVoopartida] = useState(''); // Nº do Voo de partida
    const [voochegada, setVoochegada] = useState(''); // Nº do Voo de Chegada
    const [extrasSelecionados, setExtrasSelecionados] = useState([]); // Extras
    const [produtosSelecionadosIds, setProdutosSelecionadosIds] = useState([]);
    const [servicoslimpezaSelecionados, setServicosLimpezaSelecionados] = useState([]); // Extras monovolantes
    const [mensagemobs, setMensagemObs] = useState(""); // Mensagem observacao
    const [openResumodoPedido, setOpenresumodopedido] = useState(false); //colocar a false quando finalizar ALERTA!
    const [openResumodoPedidoPreco, setOpenresumodopedidopreco] = useState(false); //colocar a false quando finalizar ALERTA!
    const [diaSelecionado, setDiaSelecionado] = useState(0);
    const [diaSelecionadoSaida, setDiaSelecionadoSaida] = useState(0);
    const [mesesentrada, setMesesEntrada] = useState("");
    const [anoentrada, setAnoEntrada] = useState("");
    const [mesessaida, setMesesSaida] = useState("");
    const [anosaida, setAnoSaida] = useState("");
    const [dataentrega, setDataentrega] = useState(""); // Data de Entrega
    const [datarecolha, setDatarecolha] = useState(""); // Data de Entrega
    const [precototaldareserva, setPrecototaldareserva] = useState(null);
    const [isValetCheckedentrada, setIsValetCheckedentrada] = useState(false); // Check  no Valet Entrada para aparecer a section
    const [isValetCheckedsaida, setIsValetCheckedsaida] = useState(false); // Check  no Valet para aparecer a section
    const [isValetCheckedentradaTransfer, setIsValetCheckedentradaTransfer] = useState(false);
    const [isValetCheckedsaidaTransfer, setIsValetCheckedsaidaTransfer] = useState(false);
    const [numpessoasentrada, setNumpessoasentrada] = useState("0"); //Numero de Pessoas Entrada
    const [numpessoassaida, setNumpessoassaida] = useState("0"); //Numero de Pessoas Saida

    const [termoseCondicoes, setTermoseCondicoes] = useState(false); //Numero de Pessoas Saida


    const [postcriado, setPostCriado] = useState(false);
    const [formularioenviado, setFormularioenviado] = useState(false);
    const [formularioerro, setFormularioErro] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    //ERROS
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [motivodoErro, setMotivodoErro] = useState('');

    //ERROS CAMPOS OBRIGATORIOS
    const [erroNoInputDataEntrada, setErroNoInputDataEntrada] = useState(false)
    const [erroNoInputDataRecolha, setErroNoInputDataRecolha] = useState(false)
    const [erroNoInputNome, setErroNoInputNome] = useState(false)
    const [erroNoInputSobrenome, setErroNoInputSobrenome] = useState(false)
    const [erroNoInputTelemovelPais, setErroNoInputTelemovelPais] = useState(false)
    const [erroNoInputTelemovel, setErroNoInputTelemovel] = useState(false)
    const [erroNoInputEmail, setErroNoInputEmail] = useState(false)

    //ERROS ESPECIAIS
    const [erroNoInputHoraEntrada, setErroNoInputHoraEntrada] = useState(false)

    const [ErroNoInputTransferEntrada, setErroNoInputTransferEntrada] = useState(false)
    const [ErroNoInputTransferSaida, setErroNoInputTransferSaida] = useState(false)

    

    const [validarHorasForm, setValidarHorasForm] = useState("0");


    //PARA ENVIAR A RESERVA
    const enviarReserva = {
        dia_de_entrada: dataentrega,
        dia_de_saida: datarecolha,
        horario_de_entrada: `${horaEntrega}:${minutoEntrega}`,
        horario_de_saida: `${horaRecolha}:${minutoRecolha}`,

        valet_service_entrada: isValetCheckedentrada,
        valet_service_saida: isValetCheckedsaida,
        transfer_entrada_checked: isValetCheckedentradaTransfer,
        transfer_entrada: numpessoasentrada,
        trasnfer_saida_checked: isValetCheckedsaidaTransfer,
        transfer_saida: numpessoassaida,

        espacoparque: espacoSelecionados,

        primeiro_nome: nome,
        ultimo_nome: sobrenome,
        telemovel: telemovel,
        telemovel_pais: telemovelPais,
        email: email,

        estadodareserva: "Reserva",

        n_voo_partida: voopartida,
        n_voo_chegada: voochegada,

        matricula: matricula,
        marca: marcadocarro,
        modelo: modelo,

        mensagem: mensagemobs,

        servicosdelimpeza: servicoslimpezaSelecionados,
        extrasadic: extrasSelecionados,
        // linkdareservarnovamente: "https://indoorpwa.nw.r.appspot.com",
        linkdareservarnovamente: window.location.origin,
        
        valid_horas:validarHorasForm
    }



    //ENVIAR FORMULÁRIO DE RESERVA
    const handleEnviarForm = () => {
        // console.log('clique')

        setFormularioErro(false);
        setPostCriado(true);
        setTermoseCondicoes(true)
        setErroNoInputDataEntrada(false);
        setErroNoInputDataRecolha(false);
        setErroNoInputNome(false);
        setErroNoInputSobrenome(false);
        setErroNoInputTelemovel(false);
        setErroNoInputEmail(false);
        setIsLoading(true);
        setOpenCalendarioEntrega(false);
        setOpenCalendarioSaida(false);

        setErroNoInputHoraEntrada(false);
        setErroNoInputTransferEntrada(false);
        setErroNoInputTransferSaida(false);

        if (!postcriado) {
            // console.log('corre')
            axios.post('https://app.indoorparkinglowcost.pt/wp-json/api/submit_reserva', enviarReserva)
                .then(response => {

                    // console.log('responde')
                    const modeValidation = response.data.validation;
                    // console.log(isValetCheckedentradaTransfer)
                    const modeReason = response.data.reason;
                    // console.log(modeReason)


                    if (modeValidation === "valid") {
                        // console.log('valido')
                        const postreservaId = response.data.post_id
                        const orderreservaId = response.data.order_id
                        SetIdDoPostSave(postreservaId);
                        SetIdDaOrderSave(orderreservaId)

                        setIsLoading(false)
                        setFormularioenviado(true);
                        setFormularioErro(false);

                        setTimeout(() => {
                            smoothScrollToTop();
                            navigate(`/dados-pagamento/reserva/${postreservaId}`);
                        }, 1000);

                    } else {
                        // console.log('nao valido')
                        setIsLoading(false)
                        setFormularioErro(true);
                        setFormularioenviado(false);
                        setPostCriado(false);


                        if (dataentrega === '') {
                            setErroNoInputDataEntrada(true)
                        } else {
                            setErroNoInputDataEntrada(false)
                        }

                        if (datarecolha === '') {
                            setErroNoInputDataRecolha(true)
                        } else {
                            setErroNoInputDataRecolha(false)
                        }

                        if(isValetCheckedentradaTransfer == true){
                            if (numpessoasentrada === '0') {
                                setErroNoInputTransferEntrada(true)
                            } else {
                                setErroNoInputTransferEntrada(false)
                            }
                        }

                        if(isValetCheckedsaidaTransfer == true){
                            if (numpessoassaida === '0') {
                                setErroNoInputTransferSaida(true)
                            } else {
                                setErroNoInputTransferSaida(false)
                            }
                        }

                        if (nome === '') {
                            setErroNoInputNome(true)
                        } else {
                            setErroNoInputNome(false)
                        }

                        if (sobrenome === '') {
                            setErroNoInputSobrenome(true)
                        } else {
                            setErroNoInputSobrenome(false)
                        }

                        if (telemovelPais === '') {
                            setErroNoInputTelemovelPais(true)
                        } else {
                            setErroNoInputTelemovelPais(false)
                        }

                        if (telemovel === '') {
                            setErroNoInputTelemovel(true)
                        } else {
                            setErroNoInputTelemovel(false)
                        }

                        if (email === '') {
                            setErroNoInputEmail(true)
                        } else {
                            setErroNoInputEmail(false)
                        }

                        if (modeReason === 'Hora de entrada incorreta!') {
                            setErroNoInputHoraEntrada(true)
                        } else {
                            setErroNoInputHoraEntrada(false)
                        }

                    }


                })
                .catch(error => {
                    setIsLoading(false)
                    setFormularioErro(true);
                    setFormularioenviado(false);
                    setPostCriado(false);
                    console.error('Erro:', error);
                });

        } else {
            setFormularioErro(true);
            setPostCriado(false);
            console.error('Erro:');
        }
    };



    //Atualiza a Lista de Produtos para não haver falhas
    useEffect(() => {
    }, [listadeProdutos]);




    useEffect(() => {

        if (dataentrega !== '' && datarecolha !== '') {
            //  PARA CALCULAR O PRECO
            const calcPreco = {
                data_inicio: dataentrega,
                data_fim: datarecolha,
                parque: espacoSelecionados,
                valet_inicio: isValetCheckedentrada,
                valet_fim: isValetCheckedsaida,
                horario_inicio: `${horaEntrega}:${minutoEntrega}`,
                horario_fim: `${horaRecolha}:${minutoRecolha}`,
                extras: produtosSelecionadosIds
            }

            setIsLoadingForm(true)

            axios.post('https://app.indoorparkinglowcost.pt/wp-json/api/calculate_price', calcPreco)
                .then(response => {
                    setIsLoadingForm(false)
                    setPrecototaldareserva(response.data.price)
                })
                .catch(error => {
                    setIsLoadingForm(false)
                    console.error('Erro:', error);
                });
        }


    }, [datarecolha, isValetCheckedentrada, isValetCheckedsaida, espacoSelecionados, produtosSelecionadosIds]);


    useEffect(() => {

        if (dataentrega !== '' && datarecolha !== '') {
            //  PARA CALCULAR O PRECO
            const calcPreco = {
                data_inicio: dataentrega,
                data_fim: datarecolha,
                parque: espacoSelecionados,
                valet_inicio: isValetCheckedentrada,
                valet_fim: isValetCheckedsaida,
                horario_inicio: `${horaEntrega}:${minutoEntrega}`,
                horario_fim: `${horaRecolha}:${minutoRecolha}`,
                extras: produtosSelecionadosIds
            }

            setIsLoadingForm(true)

            axios.post('https://app.indoorparkinglowcost.pt/wp-json/api/calculate_price', calcPreco)
                .then(response => {
                    setIsLoadingForm(false)
                    setPrecototaldareserva(response.data.price)
                })
                .catch(error => {
                    setIsLoadingForm(false)
                    console.error('Erro:', error);
                });
        }


    }, [horaRecolha, horaEntrega, minutoEntrega, minutoRecolha]);






    //PARA CONSTRUIR A MENSAGEM DE ERRO COM CAMPOS EM FALTA
    const errorMessage = [];

    if (erroNoInputDataEntrada) errorMessage.push('Data de Entrega');
    if (erroNoInputDataRecolha) errorMessage.push('Data de Recolha');
    if (erroNoInputNome) errorMessage.push('Nome');
    if (erroNoInputSobrenome) errorMessage.push('Sobrenome');
    if (erroNoInputTelemovelPais) errorMessage.push('Indicativo do País');
    if (erroNoInputTelemovel) errorMessage.push('Telemóvel');
    if (erroNoInputEmail) errorMessage.push('Email');

    if (ErroNoInputTransferEntrada) errorMessage.push('Transfer Entrada');
    if (ErroNoInputTransferSaida) errorMessage.push('Transfer Saída');

    //PARA CONSTRUIR A MENSAGEM DE ERRO COM CAMPOS EM FALTA
    const errorMessageSpecial = [];

    if (erroNoInputHoraEntrada) errorMessageSpecial.push('A reserva tem que ser realizada, no mínimo, com 6 horas de antecedência da hora de entrada.');



    return (
        <div className="formulario-de-reservas">
            {/* Mobile */}

            {openResumodoPedidoPreco ? (
                <>

                    <div className="resumo-pedido-main-sec-m">
                        <div className="resumodopedio-bem-vindo-preco-m">
                            <div className="totaldareserva-m">
                                <div className="totaldareserva-m-col1">
                                    <h2>Total da Reserva:</h2>
                                </div>
                                <div className="totaldareserva-m-col2">
                                    {isLoadingForm ? (
                                        <>
                                            <div className="loading-calendar">
                                                <Spin indicator={antIcon} />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <span className="precototaldareserva">{precototaldareserva}€</span>

                                        </>
                                    )}
                                </div>
                            </div>
                            <p className="precototaldareserva-info">* IVA incluído à taxa em vigor</p>
                            {precototaldareserva === 10 ? (
                                <p className="precototaldareserva-info">Valor mínimo de reserva ( 10€ )</p>
                            ) : null}

                        </div>
                    </div>

                </>

            ) : (
                <>

                </>
            )}

            {/* Desktop */}
            <div className="ticket-resumodopedido">
                {openResumodoPedido ? (
                    <>
                        {diaSelecionadoSaida > 0 ? (
                            <>
                                <div className="resumo-pedido-main-sec">
                                    <div className="infos-resumodopedido">
                                        <div className="infos-resumodopedido-col4">
                                            <h3>Resumo do Pedido</h3>
                                        </div>
                                        <div className="infos-resumodopedido-col4">
                                            <h2>Data da entrada:</h2>
                                            <span>{dataentrega}</span>
                                        </div>

                                        <div className="infos-resumodopedido-col4">
                                            <h2>Data da saída:</h2>
                                            <span>{datarecolha}</span>

                                        </div>

                                        <div className="infos-resumodopedido-col4">
                                            <h2>Total da Reserva:</h2>
                                            {isLoadingForm ? (
                                                <>
                                                    <div className="loading-calendar">
                                                        <Spin indicator={antIcon} />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="precototaldareserva">{precototaldareserva}€ *</span>
                                                    <p className="precototaldareserva-info">* IVA incluído à taxa em vigor</p>
                                                    {precototaldareserva === 10 ? (
                                                        <p className="precototaldareserva-info">Valor mínimo de reserva ( 10€ )</p>
                                                    ) : null}

                                                </>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </>
                ) : (
                    <>
                    </>
                )}
            </div>

            <div className="form main">
                <div>
                    <h1>Pedido de reserva</h1>
                    <h3>Viaje com tranquilidade. Garanta o seu lugar agora!</h3>
                </div>
                <form>
                    <div className="sec-formulario">
                        <Espaco
                            espacoSelecionados={espacoSelecionados}
                            setEspacoSelecionados={setEspacoSelecionados}
                            listadeProdutos={listadeProdutos}
                            setListadeProdutos={setListadeProdutos}
                        />
                        <EntradaSaida
                            validarHorasForm={validarHorasForm}
                            isTabletMobile={isTabletMobile}
                            setOpenCalendarioSaida={setOpenCalendarioSaida}
                            openCalendariosaida={openCalendariosaida}
                            setOpenCalendarioEntrega={setOpenCalendarioEntrega}
                            openCalendarioentrega={openCalendarioentrega}
                            erroNoInputDataEntrada={erroNoInputDataEntrada}
                            setErroNoInputDataEntrada={setErroNoInputDataEntrada}
                            erroNoInputDataRecolha={erroNoInputDataRecolha}
                            setErroNoInputDataRecolha={setErroNoInputDataRecolha}
                            listadeHorasRecolha={listadeHorasRecolha}
                            setListadeHorasRecolha={setListadeHorasRecolha}
                            listadeHorasEntrega={listadeHorasEntrega}
                            setListadeHorasEntrega={setListadeHorasEntrega}
                            isValidId={isValidId}
                            horaEntrega={horaEntrega}
                            setHoraEntrega={setHoraEntrega}
                            minutoEntrega={minutoEntrega}
                            setMinutoEntrega={setMinutoEntrega}
                            horaRecolha={horaRecolha}
                            setHoraRecolha={setHoraRecolha}
                            minutoRecolha={minutoRecolha}
                            setMinutoRecolha={setMinutoRecolha}
                            dataentrega={dataentrega}
                            setDataentrega={setDataentrega}
                            setDatarecolha={setDatarecolha}
                            datarecolha={datarecolha}
                            precototaldareserva={precototaldareserva}
                            setPrecototaldareserva={setPrecototaldareserva}
                            setOpenresumodopedido={setOpenresumodopedido}
                            setOpenresumodopedidopreco={setOpenresumodopedidopreco}
                            diaSelecionado={diaSelecionado}
                            setDiaSelecionado={setDiaSelecionado}
                            diaSelecionadoSaida={diaSelecionadoSaida}
                            setDiaSelecionadoSaida={setDiaSelecionadoSaida}
                            mesesentrada={mesesentrada}
                            setMesesEntrada={setMesesEntrada}
                            anoentrada={anoentrada}
                            setAnoEntrada={setAnoEntrada}
                            mesessaida={mesessaida}
                            setMesesSaida={setMesesSaida}
                            anosaida={anosaida}
                            setAnoSaida={setAnoSaida}

                            isValetCheckedentrada={isValetCheckedentrada}
                            setIsValetCheckedentrada={setIsValetCheckedentrada}
                            isValetCheckedsaida={isValetCheckedsaida}
                            setIsValetCheckedsaida={setIsValetCheckedsaida}
                            isValetCheckedsaidaTransfer={isValetCheckedsaidaTransfer}
                            setIsValetCheckedsaidaTransfer={setIsValetCheckedsaidaTransfer}
                            isValetCheckedentradaTransfer={isValetCheckedentradaTransfer}
                            setIsValetCheckedentradaTransfer={setIsValetCheckedentradaTransfer}

                            numpessoasentrada={numpessoasentrada}
                            setNumpessoasentrada={setNumpessoasentrada}
                            numpessoassaida={numpessoassaida}
                            setNumpessoassaida={setNumpessoassaida}
                            listadeProdutos={listadeProdutos}
                            espacoSelecionados={espacoSelecionados}
                            extrasSelecionados={extrasSelecionados}
                            produtosSelecionadosIds={produtosSelecionadosIds}

                        />
                        <DadosPessoais
                            erroNoInputTelemovelPais={erroNoInputTelemovelPais}
                            setErroNoInputTelemovelPais={setErroNoInputTelemovelPais}
                            erroNoInputNome={erroNoInputNome}
                            setErroNoInputNome={setErroNoInputNome}
                            erroNoInputSobrenome={erroNoInputSobrenome}
                            setErroNoInputSobrenome={setErroNoInputSobrenome}
                            erroNoInputTelemovel={erroNoInputTelemovel}
                            setErroNoInputTelemovel={setErroNoInputTelemovel}
                            erroNoInputEmail={erroNoInputEmail}
                            setErroNoInputEmail={setErroNoInputEmail}
                            nome={nome}
                            setNome={setNome}
                            sobrenome={sobrenome}
                            setSobrenome={setSobrenome}
                            telemovel={telemovel}
                            setTelemovel={setTelemovel}
                            telemovelPais={telemovelPais}
                            setTelemovelPais={setTelemovelPais}
                            email={email}
                            setEmail={setEmail}
                        />
                        <DetalhesdoVoo
                            voopartida={voopartida}
                            setVoopartida={setVoopartida}
                            voochegada={voochegada}
                            setVoochegada={setVoochegada}
                        />
                        <DadosReserva
                            matricula={matricula}
                            setMatricula={setMatricula}
                            modelo={modelo}
                            setModelo={setModelo}
                            marcadocarro={marcadocarro}
                            setMarcaDoCarro={setMarcaDoCarro}
                        />
                        <ServicosLimpeza
                            servicoslimpezaSelecionados={servicoslimpezaSelecionados}
                            setServicosLimpezaSelecionados={setServicosLimpezaSelecionados}
                            listadeProdutos={listadeProdutos}
                            setListadeProdutos={setListadeProdutos}
                            produtosSelecionadosIds={produtosSelecionadosIds}
                            setProdutosSelecionadosIds={setProdutosSelecionadosIds}
                        />
                        <Extras
                            extrasSelecionados={extrasSelecionados}
                            setExtrasSelecionados={setExtrasSelecionados}
                            listadeProdutos={listadeProdutos}
                            setListadeProdutos={setListadeProdutos}
                            produtosSelecionadosIds={produtosSelecionadosIds}
                            setProdutosSelecionadosIds={setProdutosSelecionadosIds}
                        />
                        <MensagemObservacao
                            termoseCondicoes={termoseCondicoes}
                            setTermoseCondicoes={setTermoseCondicoes}
                            mensagemobs={mensagemobs}
                            setMensagemObs={setMensagemObs}
                            manterDadosPessoais={manterDadosPessoais}
                            setManterDadosPessoais={setManterDadosPessoais}
                        />
                    </div>
                </form>

                {/* <button onClick={handleEnviarForm} className="submeterreserva" type="submit" form="formpedidoreserva" value="Submit">Submeter reserva</button> */}

                <Button
                    type="primary"
                    htmlType="submit"
                    className={`enviar-form-button ${termoseCondicoes ? 'pronto-a-enviar-form' : 'form-nao-esta-pronto'}`}
                    form="formpedidoreserva"
                    onClick={handleEnviarForm}>
                    Submeter reserva
                </Button>

                <p>* campos de preenchimento obrigatório.</p>

                {isLoading ? (
                    <>
                        <div className="loading-sec-baixo mensagem-de-loading fundo-branco">
                            <p>Aguarde...</p>
                            <Spin indicator={antIcon} />
                        </div>
                    </>
                ) : null}

                {formularioenviado ? (
                    <>
                        <SucessoMsg mensagem="Reserva realizada com Sucesso!" />
                    </>
                ) : formularioerro ? (

                    <>
                        <div className="mensagem-de-erro">
                            <p>Ocorreu algum erro, tente novamente!</p>
                            <BiError />
                        </div>
                        {errorMessage.length > 0 && (
                            <>
                            <div className="mensagem-de-erro">
                                <p>
                                    Os campos de preenchimento obrigatório estão vazios. ({errorMessage.join(', ')})
                                </p>
                            </div>
                            </>
                         )}

                         {errorMessageSpecial.length > 0 && (
                            <>
                            <div className="mensagem-de-erro">
                                <p>
                                    {errorMessageSpecial}
                                </p>
                            </div>
                            </>
                         )}
                    </>

                ) : null}

            </div>
        </div >

    );
}

export default Form;


function smoothScrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}