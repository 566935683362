import React from 'react';
import iconeChegadas from '../../assets/imagens/icone-partidas.png';
import iconePartidas from '../../assets/imagens/icone-partidas.png';



const DetalhesdoVoo = ({ voochegada, setVoochegada, voopartida, setVoopartida }) => {


    return (
        <div className="sec-detalhes-do-voo">
            <div className="flex-col-titulo">
                <h2>Detalhes do Voo</h2>
            </div>
            <hr className="linha-principal" />
            <div className='col-detalhes-do-voo'>
                <div className='subcol-detalhes-voo'>
                    <a className="label-do-campo">Nº Voo Partida</a>
                    <br />
                    <div className='flex-col'>
                        <img src={iconePartidas} alt="Descrição da imagem" />
                        <input
                            type="text"
                            className="input-detalhe-voo"
                            value={voopartida}
                            placeholder="TP 1066"
                            onChange={(e) => {
                                const input = e.target.value;
                                const maxLength = 8;
                                const limitedValue = input.slice(0, maxLength); 
                                setVoopartida(limitedValue);
                            }}
                        />
                    </div>
                </div>
                <div className='subcol-detalhes-voo'>
                    <a className="label-do-campo">Nº Voo Chegada</a>
                    <br />
                    <div className='flex-col'>
                        <img src={iconeChegadas} alt="Descrição da imagem" />
                        <input
                            type="text"
                            className="input-detalhe-voo"
                            value={voochegada}
                            placeholder="TP 674"
                            onChange={(e) => {
                                const input = e.target.value;
                                const maxLength = 8;
                                const limitedValue = input.slice(0, maxLength); 
                                setVoochegada(limitedValue);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default DetalhesdoVoo;