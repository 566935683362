import axios from 'axios';
import React, { useEffect, useState } from 'react';



const Extras = ({ produtosSelecionadosIds, setProdutosSelecionadosIds, extrasSelecionados, setExtrasSelecionados, listadeProdutos, setListadeProdutos }) => {

    const [precoDosServicos, setprecoDosServicos] = useState(''); //guardar os valores preco base

    //GET AOS PRECOS ATUALIZADOS
    useEffect(() => {
        axios.get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_all_product_prices?timestamp=${new Date().getTime()}`)
        .then(response => {
            setprecoDosServicos(response.data)
        })
        .catch(error => {
            console.error('Erro:', error);
        });
    }, []);

    if (precoDosServicos !== undefined && precoDosServicos !== ""){
        var precoDosServicos_187 = precoDosServicos[187].product_price;
        var precoDosServicos_188 = precoDosServicos[188].product_price;
    }else{
        var precoDosServicos_187 = "";
        var precoDosServicos_188 = "";
    }

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        const productId = parseInt(e.target.dataset.id);

        // Verifica se o produto já está na lista antes de adicionar ou remover
        const isProductAlreadyAdded = listadeProdutos.some((product) => product.product_id === productId);
        // console.log(isProductAlreadyAdded)

        if (checked && !isProductAlreadyAdded) {
            // Adiciona o produto à lista de produtos selecionados
            setListadeProdutos([...listadeProdutos, { product_id: productId, quantity: 1 }]);
            // console.log('adiciona', listadeProdutos)

            setProdutosSelecionadosIds([...produtosSelecionadosIds, productId]);

        } else if (!checked && isProductAlreadyAdded) {
            // Remove o produto da lista de produtos selecionados
            const updatedListadeProdutos = listadeProdutos.filter(
                (product) => product.product_id !== productId
            );

            setListadeProdutos(updatedListadeProdutos);
            // console.log('remove', listadeProdutos)


            // Remove o ID do produto da lista de IDs selecionados
            const updatedProdutosSelecionadosIds = produtosSelecionadosIds.filter(
                (id) => id !== productId
            );
            setProdutosSelecionadosIds(updatedProdutosSelecionadosIds);
            // console.log('remove', produtosSelecionadosIds)

        }




        // Atualiza o estado extrasSelecionados como antes
        setExtrasSelecionados((prevExtrasSelecionados) => {
            if (checked) {
                return [...prevExtrasSelecionados, value];
            } else {
                return prevExtrasSelecionados.filter((extra) => extra !== value);
            }
        });
    };


    return (
        <div className='sec-extras'>
            <div className="flex-col-titulo">
                <h2>EXTRAS</h2>
            </div>
            <hr className="linha-principal" />
            <div className='col-extras'>
                <div>
                    <label>
                        <input type="checkbox" data-id="188" className="all-checkbox" value="Inspeção" onChange={handleCheckboxChange} checked={extrasSelecionados.includes("Inspeção")} />
                        <a className="label-do-campo">Inspeção <span>({precoDosServicos_188}€)</span></a>
                    </label>
                </div>
                <div>
                    <label>
                        <input type="checkbox" data-id="185" className="all-checkbox" value="Lavagem de Estofos" onChange={handleCheckboxChange} checked={extrasSelecionados.includes("Lavagem de Estofos")}></input>
                        <a className="label-do-campo">Lavagem de Estofos <span>(**)</span></a>
                    </label>
                </div>

                <div>
                    <label>
                        <input type="checkbox" data-id="186" className="all-checkbox" value="Polimento de Viaturas" onChange={handleCheckboxChange} checked={extrasSelecionados.includes("Polimento de Viaturas")}></input>
                        <a className="label-do-campo">Polimento de Viaturas <span>(**)</span></a>
                    </label>
                </div>

                <div>
                    <label>
                        <input type="checkbox" data-id="187" className="all-checkbox" value="Carregamento elétrico" onChange={handleCheckboxChange} checked={extrasSelecionados.includes("Carregamento elétrico")}></input>
                        <a className="label-do-campo">Carregamento elétrico<span>(Taxa {precoDosServicos_187}€*)</span></a>
                    </label>
                </div>
            </div>
            <a className='label-info'><span>*</span>Acresce o valor do carregamento</a>
            <br />
            <a className='label-info'><span>**</span>Valor requer pedido orçamento. Entre em contacto.</a>
            <p>Para serviços de Mecânica, Pneus e Carregamento do Ar Condicionado, entre em contacto e saiba mais.</p>
        </div>
    );

};

export default Extras;
