import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal, Select } from 'antd';
import { LoadingMsg } from '../../mensagens-alertas/loading';
import { AlertaMsg } from '../../mensagens-alertas/mensagemdealerta';
import { ErroMsg } from '../../mensagens-alertas/mensagemdeerro';
import { SucessoMsg } from '../../mensagens-alertas/mensagemdesucesso';
import { FaPlusCircle } from 'react-icons/fa';

const { Option } = Select;

const PagamentoServiçosExtradaReserva = ({
    dadodareserva,
    idDaReservaPost,
    userMain,
    setLogsData,
    extraDataServicosReserva,
    setExtraDataServicosReserva,
    servicosExtraPagoReservaSelecionados,
    setServicosExtraPagoReservaSelecionados
}) => {


    //loadings de pagamento do servico extra da reserva
    const [isLoadingPagamentoServicoExtradaReserva, setIsLoadingPagamentoServicoExtradaReserva] = useState(false);
    const [isSucessoPagamentoServicoExtradaReserva, setIsSucessoPagamentoServicoExtradaReserva] = useState(false);
    const [isAlertaPagamentoServicoExtradaReserva, setIsAlertaPagamentoServicoExtradaReserva] = useState(false);
    const [isFalhaPagamentoServicoExtradaReserva, setIsFalhaPagamentoServicoExtradaReserva] = useState(false);

    const [postcriadopagamemto, setPostCriadoPagamento] = useState(false);

    const [ajustesNaRecolha, setAjustesNaRecolha] = useState("");
    const [isModalOpenAdicionarPagamentoServicoAdicional, setIsModalOpenAdicionarPagamentoServicoAdicional] = useState(false);


    const [alertaMensagem, setAlertaMensagem] = useState("");

    //obter dados do funionario
    const dadosdofuncionarioparalogs = userMain?.data || {};



    //OBTER TODOS OS SERVICOS/PRODUTOS QUE ESTAO DENTRO DA RESERVA
    useEffect(() => {

        axios
            .get(`https://app.indoorparkinglowcost.pt/wp-json/api/servicos_extra_reserva/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
            .then((response) => {

                const servicosData = response.data;
                // console.log('setExtraDataServicosReserva', servicosData)
                setExtraDataServicosReserva(servicosData);

            })
            .catch((error) => {
                console.error('Erro:', error);
            });

    }, [dadodareserva])




    // const handlePagarReservasSelecionados = () => {
    //     setPostCriadoPagamento(true);

    //     setIsSucessoPagamentoServicoExtradaReserva(false);
    //     setIsLoadingPagamentoServicoExtradaReserva(true);
    //     setIsAlertaPagamentoServicoExtradaReserva(false);
    //     setIsFalhaPagamentoServicoExtradaReserva(false);

    //     if (!postcriadopagamemto) {
    //         if (servicosExtraPagoReservaSelecionados.length === 0) {
    //             setIsSucessoPagamentoServicoExtradaReserva(false);
    //             setIsLoadingPagamentoServicoExtradaReserva(false);
    //             setIsAlertaPagamentoServicoExtradaReserva(true);
    //             setIsFalhaPagamentoServicoExtradaReserva(true);
    //             console.log('Erro');
    //         } else {

    //             let enviarservicopagossadicionais;

    //             if (servicosExtraPagoReservaSelecionados.length > 1) {
    //                 enviarservicopagossadicionais = servicosExtraPagoReservaSelecionados.map(produto => ({
    //                     tipo: "pago",
    //                     id_reserva: idDaReservaPost,
    //                     produto_id: produto.produto_id,
    //                     description: produto.description,
    //                     id: produto.id,
    //                     pago: 1,
    //                     user_id: dadosdofuncionarioparalogs.ID,
    //                     user_name: dadosdofuncionarioparalogs.display_name,
    //                 }));
    //             } else {
    //                 enviarservicopagossadicionais = [
    //                     {
    //                         tipo: "pago",
    //                         id_reserva: idDaReservaPost,
    //                         produto_id: servicosExtraPagoReservaSelecionados[0].produto_id,
    //                         description: servicosExtraPagoReservaSelecionados[0].description,
    //                         pago: 1,
    //                         id: servicosExtraPagoReservaSelecionados[0].id,
    //                         user_id: dadosdofuncionarioparalogs.ID,
    //                         user_name: dadosdofuncionarioparalogs.display_name,
    //                     }
    //                 ];
    //             }

    //             axios
    //                 .post('https://app.indoorparkinglowcost.pt/wp-json/api/update_servicos_extra_reserva_pagar', enviarservicopagossadicionais)
    //                 .then(response => {
    //                     const modeValidation = response.data.validation;
    //                     if (modeValidation === "valid") {
    //                         setIsSucessoPagamentoServicoExtradaReserva(true)
    //                         setIsLoadingPagamentoServicoExtradaReserva(false)
    //                         setIsAlertaPagamentoServicoExtradaReserva(false)
    //                         setIsFalhaPagamentoServicoExtradaReserva(false)
    //                         axios
    //                             .get(`https://app.indoorparkinglowcost.pt/wp-json/api/servicos_extra_reserva/${idDaReservaPost}`)
    //                             .then((response) => {
    //                                 // console.log(response)
    //                                 const servicosData = response.data;
    //                                 setExtraDataServicosReserva(servicosData);
    //                             })
    //                             .catch((error) => {
    //                                 console.error('Erro:', error);
    //                             });

    //                         axios
    //                             .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}`)
    //                             .then((response) => {
    //                                 // console.log(response)
    //                                 const alllogsData = response.data;
    //                                 setLogsData(alllogsData);
    //                             })
    //                             .catch((error) => {
    //                                 console.error('Erro:', error);
    //                             });

    //                     } else {
    //                         setIsSucessoPagamentoServicoExtradaReserva(false)
    //                         setIsLoadingPagamentoServicoExtradaReserva(false)
    //                         setIsAlertaPagamentoServicoExtradaReserva(false)
    //                         setIsFalhaPagamentoServicoExtradaReserva(true)
    //                         setPostCriadoPagamento(false);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     console.error('Erro:', error);
    //                     setIsSucessoPagamentoServicoExtradaReserva(false)
    //                     setIsLoadingPagamentoServicoExtradaReserva(false)
    //                     setIsAlertaPagamentoServicoExtradaReserva(false)
    //                     setIsFalhaPagamentoServicoExtradaReserva(true)
    //                     setPostCriadoPagamento(false);
    //                 })
    //         }
    //     } else {
    //         setIsSucessoPagamentoServicoExtradaReserva(false);
    //         setIsLoadingPagamentoServicoExtradaReserva(false);
    //         setIsAlertaPagamentoServicoExtradaReserva(true);
    //         setIsFalhaPagamentoServicoExtradaReserva(false);
    //         setPostCriadoPagamento(false);
    //     }
    // }





    const handleAjustedaReserva = (e) => {
        setAjustesNaRecolha(e.target.value);
    };


    // useEffect(() => {
    //     console.log(ajustesNaRecolha);
    // }, [ajustesNaRecolha]);


    const handleOpenModalAdicionarPagamentoServicoAdicional = async () => {
        setIsModalOpenAdicionarPagamentoServicoAdicional(true);
        setIsSucessoPagamentoServicoExtradaReserva(false);
        setIsLoadingPagamentoServicoExtradaReserva(false);
        setIsAlertaPagamentoServicoExtradaReserva(false);
        setIsFalhaPagamentoServicoExtradaReserva(false);
        setServicosExtraPagoReservaSelecionados("");
        setAjustesNaRecolha("");
    };


    const handleOkAdicionarPagamentoServicoAdicionarl = () => {

        setPostCriadoPagamento(true);

        setIsSucessoPagamentoServicoExtradaReserva(false);
        setIsLoadingPagamentoServicoExtradaReserva(true);
        setIsAlertaPagamentoServicoExtradaReserva(false);
        setIsFalhaPagamentoServicoExtradaReserva(false);

        // console.log(ajustesNaRecolha)

        if (!postcriadopagamemto) {
            if (servicosExtraPagoReservaSelecionados.length === 0 && ajustesNaRecolha === "" || ajustesNaRecolha === null) {
                setIsSucessoPagamentoServicoExtradaReserva(false);
                setIsLoadingPagamentoServicoExtradaReserva(false);
                setIsAlertaPagamentoServicoExtradaReserva(true);
                setIsFalhaPagamentoServicoExtradaReserva(true);
                setPostCriadoPagamento(false);
                setAlertaMensagem("campos estão vazios.");
                return

            } else if (servicosExtraPagoReservaSelecionados.length !== 0 && ajustesNaRecolha === "" || ajustesNaRecolha === null) {
                setIsSucessoPagamentoServicoExtradaReserva(false);
                setIsLoadingPagamentoServicoExtradaReserva(false);
                setIsAlertaPagamentoServicoExtradaReserva(true);
                setIsFalhaPagamentoServicoExtradaReserva(true);
                setPostCriadoPagamento(false);
                setAlertaMensagem("campo descrição vazio.");
                return

            } else if (servicosExtraPagoReservaSelecionados.length === 0 && ajustesNaRecolha !== ""){
                setIsSucessoPagamentoServicoExtradaReserva(false);
                setIsLoadingPagamentoServicoExtradaReserva(false);
                setIsAlertaPagamentoServicoExtradaReserva(true);
                setIsFalhaPagamentoServicoExtradaReserva(true);
                setPostCriadoPagamento(false);
                setAlertaMensagem("seleciona um serviço.");
                return

            }  else {

                // console.log(ajustesNaRecolha)
                const { produto_id, id } = servicosExtraPagoReservaSelecionados;

                let enviarservicopagossadicionais

                const ajusteDaReservaLabel = `${servicosExtraPagoReservaSelecionados.description} - ${ajustesNaRecolha}`;

                enviarservicopagossadicionais = [{
                    tipo: "pago",
                    id_reserva: idDaReservaPost,
                    produto_id: produto_id,
                    description: ajusteDaReservaLabel,
                    pago: 1,
                    id: id,
                    user_id: dadosdofuncionarioparalogs.ID,
                    user_name: dadosdofuncionarioparalogs.display_name,
                }];

                axios
                    .post('https://app.indoorparkinglowcost.pt/wp-json/api/update_servicos_extra_reserva_pagar', enviarservicopagossadicionais)
                    .then(response => {
                        const modeValidation = response.data.validation;
                        if (modeValidation === "valid") {
                            setIsSucessoPagamentoServicoExtradaReserva(true)
                            setIsLoadingPagamentoServicoExtradaReserva(false)
                            setIsAlertaPagamentoServicoExtradaReserva(false)
                            setIsFalhaPagamentoServicoExtradaReserva(false)
                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/servicos_extra_reserva/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)
                                    const servicosData = response.data;
                                    setExtraDataServicosReserva(servicosData);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                });

                            axios
                                .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_logs/${idDaReservaPost}?timestamp=${new Date().getTime()}`)
                                .then((response) => {
                                    // console.log(response)
                                    const alllogsData = response.data;
                                    setLogsData(alllogsData);
                                })
                                .catch((error) => {
                                    console.error('Erro:', error);
                                });


                            setTimeout(() => {
                                setIsModalOpenAdicionarPagamentoServicoAdicional(false);
                                setPostCriadoPagamento(false);
                            }, 1500);

                        } else {
                            setIsSucessoPagamentoServicoExtradaReserva(false)
                            setIsLoadingPagamentoServicoExtradaReserva(false)
                            setIsAlertaPagamentoServicoExtradaReserva(false)
                            setIsFalhaPagamentoServicoExtradaReserva(true)
                            setPostCriadoPagamento(false);
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                        setIsSucessoPagamentoServicoExtradaReserva(false)
                        setIsLoadingPagamentoServicoExtradaReserva(false)
                        setIsAlertaPagamentoServicoExtradaReserva(false)
                        setIsFalhaPagamentoServicoExtradaReserva(true)
                        setPostCriadoPagamento(false);
                    })
            }
        } else {
            setIsSucessoPagamentoServicoExtradaReserva(false);
            setIsLoadingPagamentoServicoExtradaReserva(false);
            setIsAlertaPagamentoServicoExtradaReserva(true);
            setIsFalhaPagamentoServicoExtradaReserva(false);
            setPostCriadoPagamento(false);
        }



    };


    const handleCancelAdicionarPagamentoServicoAdicionarl = () => {
        setIsModalOpenAdicionarPagamentoServicoAdicional(false);
    };


    return (

        <div className='admin-lab'>
            <div className='sec-servicosextrareserva'>
                <h3>Pagamento Serviços Extra:</h3>
                <Button className="admin-form-button-n" type="primary" onClick={handleOpenModalAdicionarPagamentoServicoAdicional}>
                    <FaPlusCircle style={{ marginRight: '8px' }} /> Adicionar Pagamento
                </Button>
                <Modal title="Adicionar Serviços Extra à Reserva:" open={isModalOpenAdicionarPagamentoServicoAdicional} onOk={handleOkAdicionarPagamentoServicoAdicionarl} onCancel={handleCancelAdicionarPagamentoServicoAdicionarl} okText="Confirmar" cancelText="Cancelar">
                    <div className="modal-com-flex">
                        <div>
                            <span>Lista de Serviços</span>
                        </div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selecione os serviços"
                            value={servicosExtraPagoReservaSelecionados ? servicosExtraPagoReservaSelecionados.description : null}
                            onChange={(value) => {
                                // console.log(selectedOptions);
                                const selectedService = extraDataServicosReserva.find(servico => servico.description === value);
                                setServicosExtraPagoReservaSelecionados(selectedService);
                            }}
                        >
                            {extraDataServicosReserva
                                .filter((servico) => servico.pago === '0')
                                .map((servico, index) => (
                                    <Option key={index} value={servico.description}>
                                        {servico.description}
                                    </Option>
                                ))}
                        </Select>
                        <div>
                            <span>Adicionar uma breve descrição e método de pagamento</span>
                            <input
                                type="input"
                                placeholder='Descreve o serviço...'
                                className="input-calendario ajustar-reserva-input"
                                value={ajustesNaRecolha}
                                onChange={handleAjustedaReserva}
                            />
                        </div>
                        {isLoadingPagamentoServicoExtradaReserva ? (
                            <>
                                <LoadingMsg mensagem="Aguarde..." />
                            </>
                        ) : isAlertaPagamentoServicoExtradaReserva ? (
                            <>
                                <AlertaMsg mensagem={alertaMensagem} />
                            </>
                        ) : isFalhaPagamentoServicoExtradaReserva ? (
                            <>
                                <ErroMsg mensagem="tente novamente." />
                            </>
                        ) : isSucessoPagamentoServicoExtradaReserva ? (
                            <>
                                <SucessoMsg mensagem="serviço/os atualizado/os" />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>

                {/* <div>
                    {extraDataServicosReserva.filter(servico => servico.pago === "0").map((servico, index) => (
                        <tr key={index}>
                            <td>
                                <label>
                                    <input
                                        type="checkbox"
                                        className="all-checkbox"
                                        value={servico.description}
                                        checked={servicosExtraPagoReservaSelecionados.includes(servico)}
                                    />
                                    <span>{servico.description}</span>
                                </label>
                            </td>
                        </tr>
                    ))}
                    <br></br>
                   <div>
                        <Button className="carrinho-form-button" type="primary" onClick={handlePagarReservasSelecionados}>
                            Adicionar Pagamento
                        </Button>
                    </div>
                </div> */}
            </div>
        </div>

    );
};

export default PagamentoServiçosExtradaReserva;


