import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/less';


const TESTEMUNHOS = gql`
query {
  elementosWP(where: {id: 53}) {
      edges {
          node {
            testemunhos {
              testemunhosnome1
              testemunhostexto1
              testemunhoimg1 {
                sourceUrl
              }
              testemunhosnome2
              testemunhostexto2
              testemunhoimg2 {
                sourceUrl
              }
              testemunhosnome3
              testemunhostexto3
              testemunhoimg3 {
                sourceUrl
              }
            }
            }
      }
    }
}
`;



const Testemunhos = () => {
  const { loading, error, data } = useQuery(TESTEMUNHOS);
  const testemunhos = data?.elementosWP?.edges[0]?.node?.testemunhos

  const slidesPerView = window.innerWidth <= 1000 ? 1 : 1;




  if (loading) return
  if (error) return <p>Erro ao carregar: {error.message}</p>;

  return (
    <div className='section-main testemunhos-main-sec'>
      <div className='section-default-center-dois'>
        <div className='testemunhos-sec'>
          <div className='coluna-default-center coluna-servicos-center'>
            <h4 className='titulo-de-destaques'>TESTEMUNHOS</h4>
            <h1>O que dizem sobre nós</h1>
          </div>
          <div className='slider'>
            <Swiper
              modules={[Autoplay, Navigation, A11y]}
              spaceBetween={50}
              slidesPerView={slidesPerView}
              navigation
            >
              <SwiperSlide>
                {testemunhos.testemunhoimg1?.sourceUrl && (
                  <img className='testemunhos-imagem' src={testemunhos.testemunhoimg1.sourceUrl} />
                )}
                {/* <img className='testemunhos-imagem'
                  src={testemunhos.testemunhoimg1.sourceUrl}
                /> */}
                <div className='testemunhos-col'>
                  <p className='testemunhos-nome'>{testemunhos.testemunhosnome1}</p>
                  <p className='testemunhos-descricao'>{testemunhos.testemunhostexto1}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {testemunhos.testemunhoimg2?.sourceUrl && (
                  <img className='testemunhos-imagem' src={testemunhos.testemunhoimg2.sourceUrl} />
                )}
                {/* <img className='testemunhos-imagem'
                  src={testemunhos.testemunhoimg2.sourceUrl}
                /> */}
                <div className='testemunhos-col'>
                  <p className='testemunhos-nome'>{testemunhos.testemunhosnome2}</p>
                  <p className='testemunhos-descricao'>{testemunhos.testemunhostexto2}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {testemunhos.testemunhoimg3?.sourceUrl && (
                  <img className='testemunhos-imagem' src={testemunhos.testemunhoimg3.sourceUrl} />
                )}
                {/* <img className='testemunhos-imagem'
                  src={testemunhos.testemunhoimg3.sourceUrl}
                /> */}
                <div className='testemunhos-col'>
                  <p className='testemunhos-nome'>{testemunhos.testemunhosnome3}</p>
                  <p className='testemunhos-descricao'>{testemunhos.testemunhostexto3}</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testemunhos;
