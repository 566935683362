import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BiError } from "react-icons/bi";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 30,
    }}
    spin
  />
);

function Login({ setIsAuthenticated }) {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [response, setResponse] = useState(null);
  const [loginRealizado, setLoginRealizado] = useState(false);
  const [loginErro, setLoginErro] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const handleLogin = async () => {

    setLoginLoading(true)

    try {
      const response = await axios.post(
        'https://app.indoorparkinglowcost.pt/wp-json/custom-login/login',
        {
          username,
          password,
        }
      );

      if (response.data.user) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        setLoginLoading(false)
        setIsAuthenticated(true);
        setLoginRealizado(true);
        setLoginErro(false);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2500);
        // console.log(response);
      } else {
        setLoginLoading(false)
        setLoginRealizado(false);
        setLoginErro(true);
        // console.log(response);
      }
    } catch (error) {
      setLoginLoading(false)
      setLoginRealizado(false);
      setLoginErro(true);
      console.error('Login error:', error.message);
    }
  };

  return (
    <div className="sec-geral-top login-section">
      <div className="form main">
        <div>
          <h1>Login</h1>
          <br />
          <hr className="linha-principal" />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={() => handleLogin()}

        >
          <p className='label-do-campo'>Utilizador</p>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Por favor, coloque o seu nome de Utilizador!',
              },
            ]}
          >
            <Input
              className="input-geral"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Utilizador" />
          </Form.Item>
          <p className='label-do-campo'>Password</p>
          <Form.Item
            name="password"
            value={password}
            rules={[
              {
                required: true,
                message: 'Por favor, coloque a sua password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-geral"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              autoComplete="current-password"
              placeholder="Password"
            />
          </Form.Item>
          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Entrar
            </Button>
          </Form.Item>
          <div className="link-registo">
            <p className='label-do-campo'>Não tenho conta Indoor. <Link className="label-link-verde" to="/registar">Registar!</Link></p>
          </div>
        </Form>
        {loginLoading &&
          <>
            <div className="mensagem-de-loading">
              <p>A carregar...</p>
              <Spin indicator={antIcon} />
            </div>
          </>
        }
        {loginRealizado ? (
          <>
            <div className="loading-sec-baixo mensagem-de-sucesso fundo-branco">
              <p>Login realizado com Sucesso! Aguarde...</p>
              <Spin indicator={antIcon} />
            </div>
          </>
        ) : loginErro ? (
          <div className="mensagem-de-erro">
            <p>Ocorreu algum erro, tente novamente!</p>
            <BiError />
          </div>
        ) : null}
      </div>

    </div>

  );
}

export default Login;


