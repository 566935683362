import React from 'react';
import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/less';


const Slider = ({ sliderData, isTabletMobile }) => {

    return (
        <div className='slider slider-home-main'>
            {isTabletMobile ? (
                //  Responsive
                <>
                    <Swiper
                        modules={[Autoplay, Navigation, Pagination, A11y]}
                        spaceBetween={5}
                        slidesPerView={1}
                        navigation
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                    >
                        <SwiperSlide>
                            {sliderData && sliderData.imagemslider1mobile && (
                                <img
                                    src={sliderData.imagemslider1mobile.sourceUrl}
                                    alt={sliderData.imagemslider1mobile.altText}
                                />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {sliderData && sliderData.imagemslider2mobile && (
                                <img
                                    src={sliderData.imagemslider2mobile.sourceUrl}
                                    alt={sliderData.imagemslider2mobile.altText}
                                />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {sliderData && sliderData.imagemslider3mobile && (
                                <img
                                    src={sliderData.imagemslider3mobile.sourceUrl}
                                    alt={sliderData.imagemslider3mobile.altText}
                                />
                            )}
                        </SwiperSlide>
                    </Swiper>
                </>

            ) : (
                <>
                    <Swiper
                        modules={[Autoplay, Navigation, Pagination, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                    >
                        <SwiperSlide>
                            {sliderData && sliderData.imagemslider1 && (
                                <img
                                    src={sliderData.imagemslider1.sourceUrl}
                                    alt={sliderData.imagemslider1.altText}
                                />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {sliderData && sliderData.imagemslider2 && (
                                <img
                                    src={sliderData.imagemslider2.sourceUrl}
                                    alt={sliderData.imagemslider2.altText}
                                />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {sliderData && sliderData.imagemslider3 && (
                                <img
                                    src={sliderData.imagemslider3.sourceUrl}
                                    alt={sliderData.imagemslider3.altText}
                                />
                            )}
                        </SwiperSlide>
                    </Swiper>
                </>
            )}
        </div>
    );
};

export default Slider;
