import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export function LoadingMsg(props) {
    return (
        <div className="mensagem-de-sucesso msg-sec">
            <Spin indicator={antIcon} /><span>{props.mensagem}</span>
        </div>
    )

}
