import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';


const DestaqueReserva = () => {

    return (
        <div className='destaque-reserva-main'>
            <div className='section-main destaque-reserva-col'>
                <div className='col-default col-frase-destaque'>
                    <h1>Viaje com tranquilidade. <br />Garanta o seu lugar agora!</h1>
                </div>
                <div className='col-default col-cta'>
                    <Link to="/formulario" onClick={() => smoothScrollToTop()}>
                        <Button type="primary" className="login-form-button-f-verde">
                            Reservar
                        </Button>
                    </Link>
                </div>

            </div>
        </div>
    );
};

export default DestaqueReserva;


function smoothScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  