import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import DadosPessoais from "../components/componentes-calendario/dadospessoais";
import DetalhesdoVoo from "../components/componentes-calendario/detalhesdovoo";
import DadosReserva from "../components/componentes-calendario/dadosreserva";
import Extras from "../components/componentes-calendario/extras";
import ServicosLimpeza from "../components/componentes-calendario/servicoslimpeza";
import axios from 'axios';
import { Button, Spin } from 'antd';
// import EntradaSaidaMarcacaoRapida from "../components/componentes-calendario/entradasaidamarcaorapida";
import { LoadingOutlined } from '@ant-design/icons';
import { SucessoMsg } from "../components/mensagens-alertas/mensagemdesucesso";
import { ErroMsg } from "../components/mensagens-alertas/mensagemdeerro";
import { AlertaMsg } from "../components/mensagens-alertas/mensagemdealerta";
import { LoadingMsg } from "../components/mensagens-alertas/loading";
import EntradaSaida from "../components/componentes-calendario/entradasaidarapida";
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


const FormMarcacaoRapida = ({ isTabletMobile, listadeProdutos = [], setListadeProdutos }) => {

    const navigate = useNavigate();


    const [openCalendarioentrega, setOpenCalendarioEntrega] = useState(false);
    const [openCalendariosaida, setOpenCalendarioSaida] = useState(false);

    // Add Overwrite price value
    const [valorDiaExtra, setvalorDiaExtra] = useState(0); // valor dia
    const [valorValetExtra, setvalorValetExtra] = useState(0); // valor extra


    const [nome, setNome] = useState(""); // Nome
    const [sobrenome, setSobrenome] = useState(""); // Sobrenome
    const [telemovel, setTelemovel] = useState(""); // Telemovel
    const [telemovelPais, setTelemovelPais] = useState(""); // Telemovel Pais
    const [email, setEmail] = useState(''); // Email  
    const [horaEntrega, setHoraEntrega] = useState('00'); //Hora de Entrada

    const [listadeHorasEntrega, setListadeHorasEntrega] = useState([]); //LISTA de Horas de Entrada
    const [listadeHorasRecolha, setListadeHorasRecolha] = useState([]); //LISTA de Horas de Entrada

    const [minutoEntrega, setMinutoEntrega] = useState('00'); //Minuto de Entrega
    const [horaRecolha, setHoraRecolha] = useState('00'); //Hora de Recolha
    const [minutoRecolha, setMinutoRecolha] = useState('00'); //Minuto de Recolha
    const [espacoSelecionados, setEspacoSelecionados] = useState('Parque Interior');

    const [matricula, setMatricula] = useState(''); // Matricula
    const [marcadocarro, setMarcaDoCarro] = useState(''); // Marca do Carro
    const [modelo, setModelo] = useState([]); // Modelo

    const [voopartida, setVoopartida] = useState(''); // Nº do Voo de partida
    const [voochegada, setVoochegada] = useState(''); // Nº do Voo de Chegada

    const [extrasSelecionados, setExtrasSelecionados] = useState([]); // Extras
    const [produtosSelecionadosIds, setProdutosSelecionadosIds] = useState([]);
    const [servicoslimpezaSelecionados, setServicosLimpezaSelecionados] = useState([]); // Extras monovolantes
    const [mensagemobs, setMensagemObs] = useState(""); // Mensagem observacao
    const [openResumodoPedido, setOpenresumodopedido] = useState(false); //colocar a false quando finalizar ALERTA!
    const [openResumodoPedidoPreco, setOpenresumodopedidopreco] = useState(false); //colocar a false quando finalizar ALERTA!
    const [diaSelecionado, setDiaSelecionado] = useState(0);
    const [diaSelecionadoSaida, setDiaSelecionadoSaida] = useState(0);
    const [mesesentrada, setMesesEntrada] = useState("");
    const [anoentrada, setAnoEntrada] = useState("");
    const [mesessaida, setMesesSaida] = useState("");
    const [anosaida, setAnoSaida] = useState("");
    const [dataentrega, setDataentrega] = useState(""); // Data de Entrega
    const [datarecolha, setDatarecolha] = useState(""); // Data de Entrega
    const [precototaldareserva, setPrecototaldareserva] = useState(null);
    const [isValetCheckedentrada, setIsValetCheckedentrada] = useState(false); // Check  no Valet Entrada para aparecer a section
    const [isValetCheckedsaida, setIsValetCheckedsaida] = useState(false); // Check  no Valet para aparecer a section
    const [isValetCheckedentradaTransfer, setIsValetCheckedentradaTransfer] = useState(false);
    const [isValetCheckedsaidaTransfer, setIsValetCheckedsaidaTransfer] = useState(false);
    const [numpessoasentrada, setNumpessoasentrada] = useState("0"); //Numero de Pessoas Entrada
    const [numpessoassaida, setNumpessoassaida] = useState("0"); //Numero de Pessoas Saida

    const [termoseCondicoes, setTermoseCondicoes] = useState(false); //Numero de Pessoas Saida


    const [postcriado, setPostCriado] = useState(false);


    const [isLoadingEnviodeFormMA, setIsLoadingEnviodeFormMA] = useState(false)
    const [isAlertaEnviodeFormMA, setIsAlertaEnviodeFormMA] = useState(false)
    const [isFalhaEnviodeFormMA, setIsFalhaEnviodeFormMA] = useState(false)
    const [isSucessoEnviodeFormMA, setIsSucessoEnviodeFormMA] = useState(false)


    const [validarHorasForm, setValidarHorasForm] = useState("1");



    //ERROS
    const [isLoadingForm, setIsLoadingForm] = useState(false);


    //PARA ENVIAR A RESERVA
    const enviarReserva = {
        dia_de_entrada: dataentrega,
        dia_de_saida: datarecolha,
        horario_de_entrada: `${horaEntrega}:${minutoEntrega}`,
        horario_de_saida: `${horaRecolha}:${minutoRecolha}`,

        valet_service_entrada: isValetCheckedentrada,
        valet_service_saida: isValetCheckedsaida,
        transfer_entrada: numpessoasentrada,
        transfer_saida: numpessoassaida,

        espacoparque: espacoSelecionados,

        // valor dia e valet especial a fazer para reserva
        valorDiaExtra: valorDiaExtra,
        valorValetExtra: valorValetExtra,

        primeiro_nome: nome,
        ultimo_nome: sobrenome,
        telemovel: telemovel,
        telemovel_pais: telemovelPais,
        email: email,

        estadodareserva: "Reserva",

        n_voo_partida: voopartida,
        n_voo_chegada: voochegada,

        matricula: matricula,
        marca: marcadocarro,
        modelo: modelo,

        mensagem: mensagemobs,

        servicosdelimpeza: servicoslimpezaSelecionados,
        extrasadic: extrasSelecionados,
        // linkdareservarnovamente: "https://indoorpwa.nw.r.appspot.com",
        linkdareservarnovamente: window.location.origin,

        valid_horas:validarHorasForm
    }

    // console.log(enviarReserva)




    //ERROS CAMPOS OBRIGATORIOS
    const [erroNoInputDataEntrada, setErroNoInputDataEntrada] = useState(false)
    const [erroNoInputDataRecolha, setErroNoInputDataRecolha] = useState(false)
    const [erroNoInputHoraEntrada, setErroNoInputHoraEntrada] = useState(false)
    const [erroNoInputHoraRecolha, setErroNoInputHoraRecolha] = useState(false)
    const [erroNoInputNome, setErroNoInputNome] = useState(false)
    const [erroNoInputSobrenome, setErroNoInputSobrenome] = useState(false)
    const [erroNoInputTelemovelPais, setErroNoInputTelemovelPais] = useState(false)
    const [erroNoInputTelemovel, setErroNoInputTelemovel] = useState(false)
    const [erroNoInputEmail, setErroNoInputEmail] = useState(false)




    //ENVIAR FORMULÁRIO DE RESERVA
    const handleEnviarForm = () => {

        setPostCriado(true);

        setIsLoadingEnviodeFormMA(true);
        setIsAlertaEnviodeFormMA(false);
        setIsFalhaEnviodeFormMA(false);
        setIsSucessoEnviodeFormMA(false);

        if (!postcriado) {

            axios.post('https://app.indoorparkinglowcost.pt/wp-json/api/submit_reserva', enviarReserva)
                .then(response => {

                    const modeValidation = response.data.validation;


                    if (modeValidation === "valid") {

                        setIsLoadingEnviodeFormMA(false);
                        setIsAlertaEnviodeFormMA(false);
                        setIsFalhaEnviodeFormMA(false);
                        setIsSucessoEnviodeFormMA(true);

                        setTimeout(() => {
                            navigate("/dashboard");
                        }, 1000);

                    } else {
                        setPostCriado(false);
                        // console.log('erro')


                        if (dataentrega === '') {
                            setErroNoInputDataEntrada(true)
                        } else {
                            setErroNoInputDataEntrada(false)
                        }

                        if (datarecolha === '') {
                            setErroNoInputDataRecolha(true)
                        } else {
                            setErroNoInputDataRecolha(false)
                        }

                        if (horaEntrega === '00' && minutoEntrega === '00') {
                            setErroNoInputHoraEntrada(true)
                        } else {
                            setErroNoInputHoraEntrada(false)
                        }

                        if (horaRecolha === '00' && minutoRecolha === '00') {
                            setErroNoInputHoraRecolha(true)
                        } else {
                            setErroNoInputHoraRecolha(false)
                        }

                        if (nome === '') {
                            setErroNoInputNome(true)
                        } else {
                            setErroNoInputNome(false)
                        }

                        if (sobrenome === '') {
                            setErroNoInputSobrenome(true)
                        } else {
                            setErroNoInputSobrenome(false)
                        }

                        if (telemovelPais === '') {
                            setErroNoInputTelemovelPais(true)
                        } else {
                            setErroNoInputTelemovelPais(false)
                        }

                        if (telemovel === '') {
                            setErroNoInputTelemovel(true)
                        } else {
                            setErroNoInputTelemovel(false)
                        }

                        if (email === '') {
                            setErroNoInputEmail(true)
                        } else {
                            setErroNoInputEmail(false)
                        }


                    }

                })
                .catch(error => {
                    setPostCriado(false);
                    setIsLoadingEnviodeFormMA(false);
                    setIsAlertaEnviodeFormMA(false);
                    setIsFalhaEnviodeFormMA(true);
                    setIsSucessoEnviodeFormMA(false);
                    console.error('Erro:', error);
                });

        } else {
            setPostCriado(false);
            setIsLoadingEnviodeFormMA(false);
            setIsAlertaEnviodeFormMA(false);
            setIsFalhaEnviodeFormMA(true);
            setIsSucessoEnviodeFormMA(false);
            console.error('Erro:');
        }
    };



    //Atualiza a Lista de Produtos para não haver falhas
    useEffect(() => {
    }, [listadeProdutos]);




    useEffect(() => {

        if (dataentrega !== '' && datarecolha !== '') {

            const calcPreco = {
                data_inicio: dataentrega,
                data_fim: datarecolha,
                parque: espacoSelecionados,
                valet_inicio: isValetCheckedentrada,
                valet_fim: isValetCheckedsaida,
                horario_inicio: `${horaEntrega}:${minutoEntrega}`,
                horario_fim: `${horaRecolha}:${minutoRecolha}`,
                extras: produtosSelecionadosIds,
                valorDiaExtra: valorDiaExtra,
                valorValetExtra: valorValetExtra,
            }

            axios.post('https://app.indoorparkinglowcost.pt/wp-json/api/calculate_price', calcPreco)
                .then(response => {
                    setPrecototaldareserva(response.data.price)
                    setIsLoadingForm(false)
                })
                .catch(error => {
                    console.error('Erro:', error);
                });
        }


    }, [dataentrega, datarecolha, isValetCheckedentrada, isValetCheckedsaida, espacoSelecionados, produtosSelecionadosIds,valorDiaExtra,valorValetExtra]);


    useEffect(() => {


        if (dataentrega !== '' && datarecolha !== '') {

            const calcPreco = {
                data_inicio: dataentrega,
                data_fim: datarecolha,
                parque: espacoSelecionados,
                valet_inicio: isValetCheckedentrada,
                valet_fim: isValetCheckedsaida,
                horario_inicio: `${horaEntrega}:${minutoEntrega}`,
                horario_fim: `${horaRecolha}:${minutoRecolha}`,
                extras: produtosSelecionadosIds,
                valorDiaExtra: valorDiaExtra,
                valorValetExtra: valorValetExtra,
            }

            axios.post('https://app.indoorparkinglowcost.pt/wp-json/api/calculate_price', calcPreco)
                .then(response => {
                    setPrecototaldareserva(response.data.price)

                    setIsLoadingForm(false)
                })
                .catch(error => {
                    console.error('Erro:', error);
                });
        }


    }, [horaRecolha, horaEntrega, minutoEntrega, minutoRecolha]);



    const handleCheckboxTermoseCondicoes = (e) => {
        setTermoseCondicoes(!termoseCondicoes);
    };


    const handleCheckboxChange = (e) => {
        const { value } = e.target;
        const productId = parseInt(e.target.dataset.id);

        const updatedListadeProdutos = listadeProdutos.filter(
            (product) => product.product_id !== 489 && product.product_id !== 625
        );

        updatedListadeProdutos.push({ product_id: productId, quantity: 1 });

        setListadeProdutos(updatedListadeProdutos);
        setEspacoSelecionados(value);
    };



    //PARA CONSTRUIR A MENSAGEM DE ERRO COM CAMPOS EM FALTA
    const errorMessage = [];

    if (erroNoInputDataEntrada) errorMessage.push('Data de Entrega');
    if (erroNoInputDataRecolha) errorMessage.push('Data de Recolha');
    if (erroNoInputHoraEntrada) errorMessage.push('Horario de Entrega');
    if (erroNoInputHoraRecolha) errorMessage.push('Horario de Recolha');
    if (erroNoInputNome) errorMessage.push('Nome');
    if (erroNoInputSobrenome) errorMessage.push('Sobrenome');
    if (erroNoInputTelemovelPais) errorMessage.push('Indicativo do País');
    if (erroNoInputTelemovel) errorMessage.push('Telemóvel');
    if (erroNoInputEmail) errorMessage.push('Email');


    return (
        <>
            <div className="formulario-de-reservas marcacao-rapida">
                {(precototaldareserva === 0 || precototaldareserva === null || precototaldareserva === "") ? (
                    <>
                    </>
                ) : (
                    <>
                        <div className="admin-lab total-da-reserva-rapida">
                            <div className="totaldareserva-marcacao">
                                <div className="totaldareserva-m-col1">
                                    <h2>Total da Reserva:</h2>
                                </div>
                                <div className="totaldareserva-m-col2">
                                    {isLoadingForm ? (
                                        <>
                                            <div className="loading-calendar">
                                                <Spin indicator={antIcon} />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <span className="precototaldareserva-marcacaorapida">{precototaldareserva}€</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <br></br>
                    </>
                )}

                <div className="form main">

                    <div>
                        <h1>Marcação Rápida</h1>
                    </div>

                    <form>
                        <div className="sec-formulario">
                            <div className="sec-espaco">
                                <div className="flex-col-titulo">
                                    <h2>Espaço</h2>
                                </div>
                                <hr className="linha-principal" />
                                <div className="flex-col-espaco">
                                    <div className="flex-col">
                                        <label className="rad-label">
                                            <input type="radio" id="parque-interior" data-id="489" className="rad-input" name="espaço" value="Parque Interior" onChange={handleCheckboxChange} checked={espacoSelecionados.includes("Parque Interior")} />
                                            <div className="rad-design"></div>
                                            <svg id="Grupo_231" data-name="Grupo 231" width="30" height="30" viewBox="0 0 34.491 35">
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <rect id="Retângulo_497" data-name="Retângulo 497" width="34.491" height="35" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    </clipPath>
                                                </defs>
                                                <g id="Grupo_36" data-name="Grupo 36" transform="translate(0 0)" clipPath="url(#clip-path)">
                                                    <path id="Caminho_95" data-name="Caminho 95" d="M31.159,39.068H25.78a2.066,2.066,0,0,1-2.064-2.062v-1.14H12.744V37a2.066,2.066,0,0,1-2.064,2.064H5.3A2.066,2.066,0,0,1,3.236,37V32.036a6.28,6.28,0,0,1,6.273-6.273H26.95a6.28,6.28,0,0,1,6.273,6.273V37a2.067,2.067,0,0,1-2.064,2.064M11,34.127h14.45v2.879a.324.324,0,0,0,.325.323h5.379A.325.325,0,0,0,31.484,37V32.036A4.539,4.539,0,0,0,26.95,27.5H9.509a4.539,4.539,0,0,0-4.534,4.534V37a.325.325,0,0,0,.324.325h5.38A.325.325,0,0,0,11,37Z" transform="translate(-0.984 -7.838)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <rect id="Retângulo_494" data-name="Retângulo 494" width="4.499" height="1.739" transform="translate(6.543 21.728)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <path id="Caminho_96" data-name="Caminho 96" d="M31.455,23.609V21.87a.669.669,0,1,0,0-1.339H27.8L26.359,16.41a3.528,3.528,0,0,0-3.326-2.36h-10.6a3.528,3.528,0,0,0-3.326,2.36L7.665,20.531H4.012a.669.669,0,0,0,0,1.339v1.739a2.409,2.409,0,0,1,0-4.818H6.43l1.035-2.957a5.268,5.268,0,0,1,4.967-3.525h10.6A5.268,5.268,0,0,1,28,15.835l1.035,2.957h2.419a2.409,2.409,0,0,1,0,4.818" transform="translate(-0.488 -3.745)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <rect id="Retângulo_495" data-name="Retângulo 495" width="4.499" height="1.739" transform="translate(23.448 21.728)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <path id="Caminho_97" data-name="Caminho 97" d="M33.649,11.061l-16.4-9.073L.843,11.061,0,9.539,17.246,0,34.491,9.539Z" transform="translate(0 0)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <rect id="Retângulo_496" data-name="Retângulo 496" width="33.648" height="1.739" transform="translate(0.422 33.261)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                </g>
                                            </svg>
                                            <div className="rad-text label-do-campo">Parque Interior *</div>
                                        </label>
                                    </div>
                                    <div className="flex-col">
                                        <label className="rad-label">
                                            <input type="radio" id="parque-exterior" data-id="625" className="rad-input" name="espaço" value="Parque Exterior" onChange={handleCheckboxChange} checked={espacoSelecionados.includes("Parque Exterior")} />
                                            <div className="rad-design"></div>
                                            <svg id="Grupo_232" data-name="Grupo 232" width="30" height="30" viewBox="0 0 34.491 35">
                                                <defs>
                                                    <clipPath id="clip-path-2">
                                                        <path id="Caminho_128" data-name="Caminho 128" d="M0,0H34.491V35H0Z" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    </clipPath>
                                                </defs>
                                                <g id="Grupo_de_máscara_1" data-name="Grupo de máscara 1" transform="translate(0 0)" clipPath="url(#clip-path-2)">
                                                    <path id="Caminho_95" data-name="Caminho 95" d="M31.158,39.068H25.78a2.066,2.066,0,0,1-2.064-2.062v-1.14H12.744V37a2.066,2.066,0,0,1-2.064,2.064H5.3A2.066,2.066,0,0,1,3.236,37V32.036a6.28,6.28,0,0,1,6.273-6.273H26.95a6.28,6.28,0,0,1,6.273,6.273V37a2.067,2.067,0,0,1-2.064,2.064M11,34.127h14.45v2.879a.324.324,0,0,0,.325.323h5.379A.325.325,0,0,0,31.483,37V32.036A4.539,4.539,0,0,0,26.95,27.5H9.509a4.539,4.539,0,0,0-4.534,4.534V37a.325.325,0,0,0,.324.325h5.38A.325.325,0,0,0,11,37Z" transform="translate(-0.985 -7.838)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <rect id="Retângulo_494" data-name="Retângulo 494" width="4.499" height="1.739" transform="translate(6.543 21.728)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <path id="Caminho_96" data-name="Caminho 96" d="M31.455,23.609V21.87a.669.669,0,0,0,0-1.339H27.8l-1.443-4.122a3.528,3.528,0,0,0-3.326-2.36h-10.6a3.528,3.528,0,0,0-3.326,2.36L7.665,20.531H4.012a.669.669,0,0,0,0,1.339v1.739a2.409,2.409,0,0,1,0-4.818H6.43l1.035-2.957a5.268,5.268,0,0,1,4.967-3.525h10.6A5.268,5.268,0,0,1,28,15.835l1.035,2.957h2.419a2.409,2.409,0,0,1,0,4.818" transform="translate(-0.488 -3.745)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <rect id="Retângulo_495" data-name="Retângulo 495" width="4.499" height="1.739" transform="translate(23.448 21.728)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                    <rect id="Retângulo_496" data-name="Retângulo 496" width="33.648" height="1.739" transform="translate(0.422 33.261)" fill="#abc132" stroke="#abc132" strokeWidth="0.5" />
                                                </g>
                                            </svg>
                                            <div className="rad-text label-do-campo">Parque Exterior *</div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="sec-espaco">
                                <div className="flex-col-titulo">
                                    <h2>Valor dia & Valor Valet</h2>
                                </div>
                                <hr className="linha-principal" />
                                <div className='col-dados-pessoais-rapida'>
                                    <div className='subcol-dados-pessoais'>
                                        <a className="label-do-campo">Valor Dia</a>
                                        <br />
                                        <input
                                            type="number"
                                            className={`input-calendario`}
                                            value={valorDiaExtra}
                                            placeholder="Valor dia"
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                setvalorDiaExtra(input);
                                            }}
                                        />
                                    </div>
                                    <div className='subcol-dados-pessoais'>
                                        <a className="label-do-campo">Valor Valet</a>
                                        <br />
                                        <input
                                            type="number"
                                            className={`input-calendario`}
                                            value={valorValetExtra}
                                            placeholder="Valor Valet"
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                setvalorValetExtra(input);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <EntradaSaida
                                validarHorasForm={validarHorasForm}
                                isTabletMobile={isTabletMobile}
                                setOpenCalendarioSaida={setOpenCalendarioSaida}
                                openCalendariosaida={openCalendariosaida}
                                setOpenCalendarioEntrega={setOpenCalendarioEntrega}
                                openCalendarioentrega={openCalendarioentrega}
                                erroNoInputDataEntrada={erroNoInputDataEntrada}
                                setErroNoInputDataEntrada={setErroNoInputDataEntrada}
                                erroNoInputDataRecolha={erroNoInputDataRecolha}
                                setErroNoInputDataRecolha={setErroNoInputDataRecolha}
                                listadeHorasRecolha={listadeHorasRecolha}
                                setListadeHorasRecolha={setListadeHorasRecolha}
                                listadeHorasEntrega={listadeHorasEntrega}
                                setListadeHorasEntrega={setListadeHorasEntrega}
                                horaEntrega={horaEntrega}
                                setHoraEntrega={setHoraEntrega}
                                minutoEntrega={minutoEntrega}
                                setMinutoEntrega={setMinutoEntrega}
                                horaRecolha={horaRecolha}
                                setHoraRecolha={setHoraRecolha}
                                minutoRecolha={minutoRecolha}
                                setMinutoRecolha={setMinutoRecolha}
                                dataentrega={dataentrega}
                                setDataentrega={setDataentrega}
                                setDatarecolha={setDatarecolha}
                                datarecolha={datarecolha}
                                precototaldareserva={precototaldareserva}
                                setPrecototaldareserva={setPrecototaldareserva}
                                setOpenresumodopedido={setOpenresumodopedido}
                                setOpenresumodopedidopreco={setOpenresumodopedidopreco}
                                diaSelecionado={diaSelecionado}
                                setDiaSelecionado={setDiaSelecionado}
                                diaSelecionadoSaida={diaSelecionadoSaida}
                                setDiaSelecionadoSaida={setDiaSelecionadoSaida}
                                mesesentrada={mesesentrada}
                                setMesesEntrada={setMesesEntrada}
                                anoentrada={anoentrada}
                                setAnoEntrada={setAnoEntrada}
                                mesessaida={mesessaida}
                                setMesesSaida={setMesesSaida}
                                anosaida={anosaida}
                                setAnoSaida={setAnoSaida}

                                isValetCheckedentrada={isValetCheckedentrada}
                                setIsValetCheckedentrada={setIsValetCheckedentrada}
                                isValetCheckedsaida={isValetCheckedsaida}
                                setIsValetCheckedsaida={setIsValetCheckedsaida}
                                isValetCheckedsaidaTransfer={isValetCheckedsaidaTransfer}
                                setIsValetCheckedsaidaTransfer={setIsValetCheckedsaidaTransfer}
                                isValetCheckedentradaTransfer={isValetCheckedentradaTransfer}
                                setIsValetCheckedentradaTransfer={setIsValetCheckedentradaTransfer}

                                numpessoasentrada={numpessoasentrada}
                                setNumpessoasentrada={setNumpessoasentrada}
                                numpessoassaida={numpessoassaida}
                                setNumpessoassaida={setNumpessoassaida}
                                listadeProdutos={listadeProdutos}
                                espacoSelecionados={espacoSelecionados}
                                extrasSelecionados={extrasSelecionados}
                                produtosSelecionadosIds={produtosSelecionadosIds}

                            />

                            <DadosPessoais
                                erroNoInputTelemovelPais={erroNoInputTelemovelPais}
                                setErroNoInputTelemovelPais={setErroNoInputTelemovelPais}
                                erroNoInputNome={erroNoInputNome}
                                setErroNoInputNome={setErroNoInputNome}
                                erroNoInputSobrenome={erroNoInputSobrenome}
                                setErroNoInputSobrenome={setErroNoInputSobrenome}
                                erroNoInputTelemovel={erroNoInputTelemovel}
                                setErroNoInputTelemovel={setErroNoInputTelemovel}
                                erroNoInputEmail={erroNoInputEmail}
                                setErroNoInputEmail={setErroNoInputEmail}
                                nome={nome}
                                setNome={setNome}
                                sobrenome={sobrenome}
                                setSobrenome={setSobrenome}
                                telemovel={telemovel}
                                setTelemovel={setTelemovel}
                                telemovelPais={telemovelPais}
                                setTelemovelPais={setTelemovelPais}
                                email={email}
                                setEmail={setEmail}

                            />
                            <DetalhesdoVoo
                                voopartida={voopartida}
                                setVoopartida={setVoopartida}
                                voochegada={voochegada}
                                setVoochegada={setVoochegada}
                            />
                            <DadosReserva
                                matricula={matricula}
                                setMatricula={setMatricula}
                                modelo={modelo}
                                setModelo={setModelo}
                                marcadocarro={marcadocarro}
                                setMarcaDoCarro={setMarcaDoCarro}
                            />
                            <ServicosLimpeza
                                servicoslimpezaSelecionados={servicoslimpezaSelecionados}
                                setServicosLimpezaSelecionados={setServicosLimpezaSelecionados}
                                listadeProdutos={listadeProdutos}
                                setListadeProdutos={setListadeProdutos}
                                produtosSelecionadosIds={produtosSelecionadosIds}
                                setProdutosSelecionadosIds={setProdutosSelecionadosIds}
                            />
                            <Extras
                                extrasSelecionados={extrasSelecionados}
                                setExtrasSelecionados={setExtrasSelecionados}
                                listadeProdutos={listadeProdutos}
                                setListadeProdutos={setListadeProdutos}
                                produtosSelecionadosIds={produtosSelecionadosIds}
                                setProdutosSelecionadosIds={setProdutosSelecionadosIds}
                            />
                            <div>
                                <div className="flex-col-titulo">
                                    <h2>MENSAGEM <span>/</span> OBSERVAÇÕES</h2>
                                </div>
                                <hr className="linha-principal" />
                                <textarea
                                    placeholder='Deixe aqui a sua mensagem ou observação…'
                                    rows="5"
                                    cols="33"
                                    value={mensagemobs}
                                    onChange={(e) => setMensagemObs(e.target.value)}
                                ></textarea>
                                <br />
                                <br />
                                <div>
                                    <label>
                                        <input type="checkbox" className="all-checkbox" value="Termos e Condições" onChange={handleCheckboxTermoseCondicoes}></input>
                                        Aceite os nossos <Link className="terms" to="/termos-e-condicoes" target="_blank">Termos e Condições</Link> *
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>


                    <Button
                        type="primary"
                        htmlType="submit"
                        className={`enviar-form-button ${termoseCondicoes ? 'pronto-a-enviar-form' : 'form-nao-esta-pronto'}`}
                        form="formpedidoreserva"
                        onClick={handleEnviarForm}>
                        Submeter reserva
                    </Button>

                    <p>* campos de preenchimento obrigatório.</p>


                    {errorMessage.length > 0 ? (
                        <>
                            <div className="mensagem-de-erro">
                                <p>
                                    Os campos de preenchimento obrigatório estão vazios. ({errorMessage.join(', ')})
                                </p>
                            </div>
                        </>
                    ) : isLoadingEnviodeFormMA ? (
                        <>
                            <LoadingMsg mensagem="Aguarde..." />
                        </>
                    ) : isAlertaEnviodeFormMA ? (
                        <>
                            <AlertaMsg mensagem="verifique o formulário." />
                        </>
                    ) : isFalhaEnviodeFormMA ? (
                        <>
                            <ErroMsg mensagem="tente novamente." />
                        </>
                    ) : isSucessoEnviodeFormMA ? (
                        <>
                            <SucessoMsg mensagem="reserva adicionada." />
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>
            </div >
        </>
    );
}

export default FormMarcacaoRapida;
