import axios from 'axios';
import React, { useEffect, useState } from 'react';


const ServicosLimpeza = ({ produtosSelecionadosIds, setProdutosSelecionadosIds, servicoslimpezaSelecionados, setServicosLimpezaSelecionados, listadeProdutos, setListadeProdutos }) => {

  const [precoDosServicos, setprecoDosServicos] = useState(''); //guardar os valores preco base

  //GET AOS PRECOS ATUALIZADOS
  useEffect(() => {
    axios.get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_all_product_prices?timestamp=${new Date().getTime()}`)
      .then(response => {
        setprecoDosServicos(response.data)
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  if (precoDosServicos !== undefined && precoDosServicos !== ""){
    var precoDosServicos_192 = precoDosServicos[192].product_price;
    var precoDosServicos_193 = precoDosServicos[193].product_price;
    var precoDosServicos_194 = precoDosServicos[194].product_price;
    var precoDosServicos_189 = precoDosServicos[189].product_price;
    var precoDosServicos_190 = precoDosServicos[190].product_price;
    var precoDosServicos_191 = precoDosServicos[191].product_price;
  }else{
    var precoDosServicos_192 = "";
    var precoDosServicos_193 = "";
    var precoDosServicos_194 = "";
    var precoDosServicos_189 = "";
    var precoDosServicos_190 = "";
    var precoDosServicos_191 = "";
  }

  useEffect(() => {

    // console.log('listadeProdutos',listadeProdutos)
    // console.log('produtosSelecionadosIds', produtosSelecionadosIds)

  }, [listadeProdutos, produtosSelecionadosIds]);



  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let productId = parseInt(e.target.dataset.id);


    // verificar se algum dos monovolumes estiver selecionado nao permitir a selecao em cima e vice versa
    if (
      ((produtosSelecionadosIds.some((product) => product == '194') || 
      produtosSelecionadosIds.some((product) => product == '193') || 
      produtosSelecionadosIds.some((product) => product == '192')) && (productId == '191' || productId == '190' || productId == '189'))
    ) {
      // console.log('return');
      return;
    }

    if (
      ((produtosSelecionadosIds.some((product) => product == '191') || 
      produtosSelecionadosIds.some((product) => product == '190') || 
      produtosSelecionadosIds.some((product) => product == '189')) && (productId == '194' || productId == '193' || productId == '192'))
    ) {
      // console.log('return');
      return;
    }


    if (
      (produtosSelecionadosIds.some((product) => product == '191') && (productId == '189' || productId == '190'))
    ) {
      // console.log('return');
      return;
    }


    if (
      (produtosSelecionadosIds.some((product) => product == '194') && (productId == '193' || productId == '192'))
    ) {
      // console.log('return');
      return;
    }

    const isProductAlreadyAdded = listadeProdutos.some((product) => product.product_id === productId);

    // console.log(productId);

    if (checked && !isProductAlreadyAdded) {


      if (
        (produtosSelecionadosIds.some((product) => product == '190') && productId == '189') ||
        (produtosSelecionadosIds.some((product) => product == '189') && productId == '190')
      ) {

        produtosSelecionadosIds = produtosSelecionadosIds.filter((product) => product != '190');
        produtosSelecionadosIds = produtosSelecionadosIds.filter((product) => product != '189');
        // console.log('entra aqui 1');
        productId = 191;
      }

      if (
        (produtosSelecionadosIds.some((product) => product == '193') && productId == '192') ||
        (produtosSelecionadosIds.some((product) => product == '192') && productId == '193')
      ) {

        produtosSelecionadosIds = produtosSelecionadosIds.filter((product) => product != '193');
        produtosSelecionadosIds = produtosSelecionadosIds.filter((product) => product != '192');
        // console.log('entra aqui 1');
        productId = 194;
      }

      if (
        ((produtosSelecionadosIds.some((product) => product == '190') || produtosSelecionadosIds.some((product) => product == '189')) && productId == '191')
      ) {

        produtosSelecionadosIds = produtosSelecionadosIds.filter((product) => product != '190');
        produtosSelecionadosIds = produtosSelecionadosIds.filter((product) => product != '189');
        productId = 191;
      }

      if (
        ((produtosSelecionadosIds.some((product) => product == '193') || produtosSelecionadosIds.some((product) => product == '192')) && productId == '194')
      ) {

        produtosSelecionadosIds = produtosSelecionadosIds.filter((product) => product != '193');
        produtosSelecionadosIds = produtosSelecionadosIds.filter((product) => product != '192');
        productId = 194;
      }





      setListadeProdutos([...listadeProdutos, { product_id: productId, quantity: 1 }]);
      // console.log(listadeProdutos)
      setProdutosSelecionadosIds([...produtosSelecionadosIds, productId]);
      // console.log(produtosSelecionadosIds)


    }

    else if (!checked && isProductAlreadyAdded) {


      const updatedListadeProdutos = listadeProdutos.filter(
        (product) => product.product_id !== productId
      );

      if (productId == 191 || productId == 194) {
        setListadeProdutos([]);
        setProdutosSelecionadosIds([]);
      } else {

        setListadeProdutos(updatedListadeProdutos);

        const updatedProdutosSelecionadosIds = produtosSelecionadosIds.filter(
          (id) => id !== productId
        );

        setProdutosSelecionadosIds(updatedProdutosSelecionadosIds);

      }



    }

    setServicosLimpezaSelecionados((prevExtrasSelecionados) => {

      if (checked) {
        let extras_atualizados = [...prevExtrasSelecionados, value];

        //NORMAIS
        if (value === 'Limpeza interior' || value === 'Lavagem exterior') {

          if (extras_atualizados.some((product) => product === 'Lavagem completa')) {
            return extras_atualizados.filter((product) => product !== value);
          }

          // Verificar se o value é um dele
          // Verificar se existem ambos (Limpeza interiore e Lavagem exterior) - Se sim, remove-los e colocar o Lavagem completa
          if (extras_atualizados.some((product) => product === 'Limpeza interior') && extras_atualizados.some((product) => product === 'Lavagem exterior')) {
            extras_atualizados = extras_atualizados.filter((product) => product !== 'Limpeza interior');
            extras_atualizados = extras_atualizados.filter((product) => product !== 'Lavagem exterior');

            extras_atualizados = [...extras_atualizados, 'Lavagem completa'];
            // console.log('extras_atualizados', extras_atualizados);
          }

        }

        //NORMAIS
        if (value === 'Lavagem completa') {
          extras_atualizados = extras_atualizados.filter((product) => product !== 'Limpeza interior');
          extras_atualizados = extras_atualizados.filter((product) => product !== 'Lavagem exterior');
          extras_atualizados = [...extras_atualizados];
        }


        ////--------------------------////





        //MONOVOLUMES E PICKUPS
        if (value === 'Limpeza interior XL' || value === 'Lavagem exterior XL') {

          if (extras_atualizados.some((product) => product === 'Lavagem completa XL')) {
            return extras_atualizados.filter((product) => product !== value);
          }
          // Verificar se o value é um dele
          // Verificar se existem ambos (Limpeza interiore e Lavagem exterior) - Se sim, remove-los e colocar o Lavagem completa
          if (extras_atualizados.some((product) => product === 'Limpeza interior XL') && extras_atualizados.some((product) => product === 'Lavagem exterior XL')) {
            extras_atualizados = extras_atualizados.filter((product) => product !== 'Limpeza interior XL');
            extras_atualizados = extras_atualizados.filter((product) => product !== 'Lavagem exterior XL');

            extras_atualizados = [...extras_atualizados, 'Lavagem completa XL'];
            // console.log('extras_atualizados XL', extras_atualizados);
          }
        }

        //MONOVOLUMES E PICKUPS
        if (value === 'Lavagem completa XL') {
          extras_atualizados = extras_atualizados.filter((product) => product !== 'Limpeza interior XL');
          extras_atualizados = extras_atualizados.filter((product) => product !== 'Lavagem exterior XL');
          extras_atualizados = [...extras_atualizados];
        }




        return extras_atualizados;

      } else {

        if (value === 'Lavagem completa' || value === 'Lavagem completa XL') {
          return [];
        }

        return prevExtrasSelecionados.filter((product) => product !== value);

      }
    });

    // console.log('listadeProdutos',listadeProdutos)

  }

  return (
    <div className='sec-extraxmonopicks'>
      <div className="flex-col-titulo">
        <h2>SERVIÇOS DE LIMPEZA</h2>
      </div>
      <hr className="linha-principal" />
      <div className='col-extras'>
        <div>
          <label onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              data-id="191"
              className="all-checkbox"
              value="Lavagem completa"
              checked={servicoslimpezaSelecionados.includes("Lavagem completa")}
              onChange={handleCheckboxChange}
            />
            Lavagem completa <span>({precoDosServicos_191}€)</span>
          </label>
        </div>

        <div>
          <label onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              data-id="190"
              className="all-checkbox"
              value="Lavagem exterior"
              checked={servicoslimpezaSelecionados.includes("Lavagem exterior")}
              onChange={handleCheckboxChange}
            />
            Lavagem exterior <span>({precoDosServicos_190}€)</span>
          </label>
        </div>

        <div>
          <label onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              data-id="189"
              className="all-checkbox"
              value="Limpeza interior"
              checked={servicoslimpezaSelecionados.includes("Limpeza interior")}
              onChange={handleCheckboxChange}
            />
            Limpeza interior <span>({precoDosServicos_189}€)</span>
          </label>
        </div>

        <div>
          <label onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              data-id="194"
              className="all-checkbox"
              name="lavagemcompleta"
              value="Lavagem completa XL"
              checked={servicoslimpezaSelecionados.includes("Lavagem completa XL")}
              onChange={handleCheckboxChange}
            />
            Lavagem completa * <span>({precoDosServicos_194}€)</span>
          </label>
        </div>

        <div>
          <label onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              data-id="193"
              className="all-checkbox"
              name="lavagemexterior"
              value="Lavagem exterior XL"
              checked={servicoslimpezaSelecionados.includes("Lavagem exterior XL")}
              onChange={handleCheckboxChange}
            />
            Lavagem exterior * <span>({precoDosServicos_193}€)</span>
          </label>
        </div>

        <div>
          <label onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              data-id="192"
              className="all-checkbox"
              name="limpezainterior"
              value="Limpeza interior XL"
              checked={servicoslimpezaSelecionados.includes("Limpeza interior XL")}
              onChange={handleCheckboxChange}
            />
            Limpeza interior * <span>({precoDosServicos_192}€)</span>
            
          </label>
        </div>
      </div>
      <a className='label-info'><span>*</span>Serviços de Limpeza só para Monovolumes e Pick Ups</a>
      <br></br>
      <a className='label-info'><span>*</span>O valor da lavagem poderá alterar mediante o estado do veiculo.</a>
    </div>
  );
};

export default ServicosLimpeza;

