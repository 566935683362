import React from "react";
import { Link } from 'react-router-dom';
import { BiError } from "react-icons/bi";
import { Button } from 'antd';

function ErroPagina() {

    return (
        <div className="formulario-de-reservas erropagina-section">
            <div className="form main">
                <div className="page-error404">
                    <div className="ups-erro">
                        <div className="acesso-negado">
                            <BiError />
                            <div className="flex-nogap">
                                <h1>UPS...</h1>
                                <p className="texto-centro">Não conseguimos encontrar o que procuras.</p>
                            </div>
                            <Link to="/">
                                <Button type="primary" className="login-form-button-f-branco">
                                    Voltar ao Início
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErroPagina