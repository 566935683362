import { Button } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const Servicos = ({ servicosData, setCliquenoParqueHome }) => {


  const [precoporBase, setPrecoPorBase] = useState(''); //guardar os valores preco base


  //GET AOS PRECOS ATUALIZADOS
  useEffect(() => {
    axios.get(`https://app.indoorparkinglowcost.pt/wp-json/api/preco_base?timestamp=${new Date().getTime()}`)
      .then(response => {
        setPrecoPorBase(response.data)
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);


  if (!servicosData) {
    return null;
  }

  return (
    <div className='section-main servicos-main'>
      <div className='section-default-center servicos-section'>
        <div className='coluna-default-center coluna-servicos-center'>
          <h4 className='titulo-de-destaques'>{servicosData.tituloServicos}</h4>
          <h1>{servicosData.titulodestaqueServicos}</h1>
        </div>
        <div className='coluna-servicos'>
          <div className='coluna-servicos-child'>
            <div>
              <h3>Parque Interior</h3>
            </div>
            <h1><span>desde</span>{precoporBase.valor_de_parque_interior_base}€<span>/dia*</span></h1>
            <Link className="menu-label" to="/formulario" onClick={() => {
              setCliquenoParqueHome(false)
              smoothScrollToTop()
            }}>
              <Button type="primary" className="login-form-button-f-branco">
                Reservar
              </Button>
            </Link>
          </div>
          <div className='coluna-servicos-child coluna-servico-exterior'>
            <div>
              <h3>Parque Exterior</h3>
            </div>
            <h1><span>desde</span>{precoporBase.valor_de_parque_exterior_base}€<span>/dia*</span></h1>
            <Link className="menu-label" to="/formulario" onClick={() => {
              setCliquenoParqueHome(true)
              smoothScrollToTop()
            }}>
              <Button type="primary" className="login-form-button-f-branco">
                Reservar
              </Button>
            </Link>
          </div>
          <div className='coluna-servicos-child'>
            <div>
              <h3>Valet Service</h3>
            </div>
            <h1><span>desde</span>{precoporBase.valor_de_valet_base}€<span>/dia*</span></h1>
            <Link className="menu-label" to="/formulario" onClick={() => {
              setCliquenoParqueHome(false)
              smoothScrollToTop()
            }}>
              <Button type="primary" className="login-form-button-f-branco">
                Reservar
              </Button>
            </Link>
          </div>
        </div>
        <p className='fraseinformativa'>*Preços sujeitos a alteração em datas especiais (Carnaval, Páscoa, Natal e Passagem de Ano);</p>
      </div>
    </div>

  );
};

export default Servicos;


function smoothScrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}