import React from 'react';
import { useQuery, gql } from '@apollo/client';
import funcionainicio from '../../assets/imagens/como-funca-bullet-1.png';
import funcionainicioum from '../../assets/imagens/como-funca-bullet-2.png';
import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/less';


const COMOFUNCIONA = gql`
  query {
    elementosWP(where: {id: 55}) {
      edges {
        node {
          comofunciona {
            comoFuncionaDestaqueDestUm
            comoFuncionaTituloDestUm
            comofunciona1 {
              descricaocomofunciona1
              imagemcomofunciona1 {
                sourceUrl
              }
            }
            comofunciona2 {
              descricaocomofunciona2
              imagemcomofunciona2 {
                sourceUrl
              }
            }
            comofunciona3 {
              descricaocomofunciona3
              imagemcomofunciona3 {
                sourceUrl
              }
            }
            comofunciona4 {
              descricaocomofunciona4
              imagemcomofunciona4 {
                sourceUrl
              }
            }
            comofunciona5 {
              descricaocomofunciona5
              imagemcomofunciona5 {
                sourceUrl
              }
            }
            comofunciona6 {
              descricaocomofunciona6
              imagemcomofunciona6 {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

const ComoFunciona = () => {
  const { loading, error, data } = useQuery(COMOFUNCIONA);
  const comoFunciona = data?.elementosWP.edges[0].node.comofunciona

  const slidesPerView = window.innerWidth <= 1000 ? 1 : 4;

  if (loading) return
  if (error) return <p>Erro ao carregar: {error.message}</p>;


  // className='section-main comofunciona-main'
  // <div className='section-default-center comofunciona-section'>


  return (
    <div className='comofunciona-main'>
      <div className='section-default-center-dois'>


        <div className='comofunciona-section'>
          <div className='coluna-comofunciona-title'>
            <h4>{comoFunciona.comoFuncionaTituloDestUm}</h4>
            <h1>{comoFunciona.comoFuncionaDestaqueDestUm}</h1>
          </div>
          <div className='sec-slider-comofunciona'>
            <Swiper
              modules={[Autoplay, A11y]}
              spaceBetween={20}
              slidesPerView={slidesPerView}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <div className='coluna-comofunciona'>
                  <img className='comofunciona-img' src={funcionainicio} alt="ComoFunciona" />
                  <img className='imagem-default'
                    src={comoFunciona.comofunciona1.imagemcomofunciona1.sourceUrl}
                  />
                  <p>{comoFunciona.comofunciona1.descricaocomofunciona1}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='coluna-comofunciona'>
                  <img className='comofunciona-img' src={funcionainicio} alt="ComoFunciona" />
                  <img className='imagem-default'
                    src={comoFunciona.comofunciona2.imagemcomofunciona2.sourceUrl}
                  />
                  <p>{comoFunciona.comofunciona2.descricaocomofunciona2}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='coluna-comofunciona'>
                  <img className='comofunciona-img' src={funcionainicio} alt="ComoFunciona" />
                  <img className='imagem-default'
                    src={comoFunciona.comofunciona3.imagemcomofunciona3.sourceUrl}
                  />
                  <p>{comoFunciona.comofunciona3.descricaocomofunciona3}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='coluna-comofunciona'>
                  <img className='comofunciona-img' src={funcionainicio} alt="ComoFunciona" />
                  <img className='imagem-default'
                    src={comoFunciona.comofunciona4.imagemcomofunciona4.sourceUrl}
                  />
                  <p>{comoFunciona.comofunciona4.descricaocomofunciona4}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='coluna-comofunciona'>
                  <img className='comofunciona-img' src={funcionainicio} alt="ComoFunciona" />
                  <img className='imagem-default'
                    src={comoFunciona.comofunciona5.imagemcomofunciona5.sourceUrl}
                  />
                  <p>{comoFunciona.comofunciona5.descricaocomofunciona5}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='coluna-comofunciona'>
                  <img className='comofunciona-img' src={funcionainicio} alt="ComoFunciona" />
                  <img className='imagem-default'
                    src={comoFunciona.comofunciona6.imagemcomofunciona6.sourceUrl}
                  />
                  <p>{comoFunciona.comofunciona6.descricaocomofunciona6}</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComoFunciona;
