
import { gql } from '@apollo/client';


export const OBTERFAQS = gql`
query {
    elementosWP(where: {id: 54}) {
        edges {
            node {
                faqs {
                    descricaoFaq1
                    tituloFaq1
                    descricaoFaq10
                    descricaoFaq11
                    descricaoFaq12
                    descricaoFaq13
                    descricaoFaq14
                    descricaoFaq15
                    descricaoFaq16
                    descricaoFaq2
                    descricaoFaq4
                    descricaoFaq3
                    descricaoFaq5
                    descricaoFaq6
                    descricaoFaq7
                    descricaoFaq8
                    descricaoFaq9
                    fieldGroupName
                    tituloFaq10
                    tituloFaq11
                    tituloFaq12
                    tituloFaq13
                    tituloFaq14
                    tituloFaq15
                    tituloFaq16
                    tituloFaq2
                    tituloFaq3
                    tituloFaq4
                    tituloFaq7
                    tituloFaq6
                    tituloFaq5
                    tituloFaq8
                    tituloFaq9
                }
            }
        }
    }
  }
`;


