import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Spin } from 'antd';
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';
import close from '../../assets/imagens/close.png';
import PreviewEntrega from './preview-entrega';
import { Alert } from 'antd';
import PreviewRecolha from './preview-recolha';
import { Select } from 'antd';
const { Option } = Select;
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 50,
        }}
        spin
    />
);


const Calendario = ({
    validarHorasForm,
    isTabletMobile,
    erroNoInputDataEntrada,
    setErroNoInputDataEntrada,
    setListadeHorasRecolha,
    setListadeHorasEntrega,
    setAnoSelecionadoEntradaParaHoras,
    setMesSelecionadoParaHoras,
    setHorasEstaoDisponiveisRecolha,
    setHorasEstaoDisponiveisEntrega,
    setHoraRecolha,
    horaRecolha,
    setHoraEntrega,
    horaEntrega,
    espacoSelecionados,
    dataentrega,
    datarecolha,
    setOpenCalendario,
    openCalendariosaida,
    openCalendarioentrega,
    setDataentrega,
    setDatarecolha,
    setOpenCalendarioSaida,
    setOpenCalendarioEntrega,
    precototaldareserva,
    setPrecototaldareserva,
    diaSelecionado,
    setDiaSelecionado,
    diaSelecionadoSaida,
    setDiaSelecionadoSaida,
    setPrecoDiaSelecionado,
    setPrecoDiaSelecionadoSaida,
    precodiaSelecionadoSaida,
    precodiaSelecionado,
}) => {

    // console.log('validarHorasFormvalidarHorasFormvalidarHorasForm',validarHorasForm)

    const currentDate = new Date();


    const [anoAPI, setAnoAPI] = useState([])

    const [loadingdias, setLoadingDias] = useState(true)
    const [onPoucasVagas, setOnPoucasVagas] = useState(false)
    const [diaHover, setDiaHover] = useState('');
    const [diaHoverMain, setDiaHoverMain] = useState('');
    const [mesHover, setMesHover] = useState('');
    const [anoHover, setAnoHover] = useState('');
    const [precoHover, setPrecoHover] = useState('');
    const [diadasemana, setDiadaSemana] = useState([]);
    const [diasIndisponiveisJson, setDiasIndisponiveisJson] = useState([]); // Dias indisponiveis (todos)
    const [mesSelecionado, setMesSelecionado] = useState('');
    const [anoSelecionado, setAnoSelecionado] = useState('');
    const [diatoday, setDiatoday] = useState('');
    const [mestoday, setMestoday] = useState('');
    const [anotoday, setAnotoday] = useState('');
    const [diascalendario, setDiasCalendario] = useState("");

    const [testeDia, setTesteDia] = useState("");

    // const [ola, setOla] = useState([]);


    // const [testeDia, setTesteDia] = useState("");


    //Para colocar o mes e o ano da data atual /// NÃO ESTA A SER USADO NO FRONT
    useEffect(() => {
        const date = new Date();
        const mesAtual = (date.getMonth() + 1).toString().padStart(2, '0');
        const anoAtual = date.getFullYear().toString();
        setMesSelecionado(mesAtual);
        // console.log('mesAtual', mesAtual)
        setAnoSelecionado(anoAtual);
        // console.log('anoAtual', anoAtual)
    }, []);




    //OBTER ANOS
    // useEffect(() => {
    //     axios
    //         .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_calendar_years`)
    //         .then((response) => {    
    //             console.log(response)
    //             setAnoAPI(response);
    //         })
    //         .catch((error) => {
    //             console.error('Erro:', error);
    //         });
    // }, []);

    useEffect(() => {
        axios
            .get(`https://app.indoorparkinglowcost.pt/wp-json/api/get_calendar_years?timestamp=${new Date().getTime()}`)
            .then((response) => {
                setAnoAPI(response.data);
            })
            .catch((error) => {
                console.error('Erro:', error);
            });
    }, []);






    //Para colocar o mes e o ano da data atual
    useEffect(() => {

        // console.log('corri novamente o mes ou ano')

        const todayDate = new Date();
        const day = todayDate.getDate();

        if (anoSelecionado && mesSelecionado) {

            const calendario_mes = criarBlocosDiasVazios(anoSelecionado, mesSelecionado);
            // console.log('calendario_mes',calendario_mes)



            axios.get(`https://app.indoorparkinglowcost.pt/wp-json/api/calendar/${anoSelecionado}/${mesSelecionado}?timestamp=${new Date().getTime()}`)
                .then(response => {
                    //console.log(response)
                    let diasCalendarioAtualizado = Object.values(response?.data?.dates).map(dia => {
                        const [diaPart, mesPart, anoPart] = dia.date?.split('-');
                        const formated_dia_loop = new Date(`${anoPart}-${mesPart}-${diaPart}`).getTime();

                        const diaDeHoje = new Date();
                        diaDeHoje.setDate(currentDate.getDate()); // coloca o dia de ontem (-1 dia)

                        // Verifica se a data é o dia de ontem
                        const diaDeOntem = new Date();
                        diaDeOntem.setDate(currentDate.getDate() - 1);
                        var year = diaDeOntem.toLocaleString("default", { year: "numeric" });
                        var month = diaDeOntem.toLocaleString("default", { month: "2-digit" });
                        var day = diaDeOntem.toLocaleString("default", { day: "2-digit" });
                        var formattedDate = year + "-" + month + "-" + day;

                        diaDeOntem.setDate(currentDate.getDate() - 1); // coloca o dia de ontem (-1 dia)


                        if (espacoSelecionados === "Parque Interior") {
                            if (formated_dia_loop < diaDeOntem) {
                                dia.available = "indisponivel";
                            }else{
                                dia.available = dia.espaco_i;
                            }
                            
                        } else {
                            if (formated_dia_loop < diaDeOntem) {
                                dia.available = "indisponivel";
                            }else{
                                dia.available = dia.espaco_o;
                            }
                        }

                        return dia;
                    });

                    diasCalendarioAtualizado = calendario_mes.concat(diasCalendarioAtualizado.slice(0));
                    //console.log('diasCalendarioAtualizado',diasCalendarioAtualizado)


                    setDiasCalendario(diasCalendarioAtualizado);
                    setDiadaSemana(response.data.day_labels);
                    setLoadingDias(false);

                    // PARA SABER OS DIAS INDISPONIVEIS
                    const diasIndisponiveis = diasCalendarioAtualizado.filter(item => item.available === 'indisponivel');
                    // console.log('diasIndisponiveis', diasIndisponiveis)
                    setDiasIndisponiveisJson(diasIndisponiveis);
                })
                .catch(error => {
                    setLoadingDias(false);
                    //console.error('Erro:', error);
                });

        }

    }, [mesSelecionado, anoSelecionado]);


    const handleDiaHover = (dia, mes, ano, precohoverdia) => {
        setDiaHover(dia);
        setDiaHoverMain(dia);
        setMesHover(mes);
        setAnoHover(ano);
        setPrecoHover(precohoverdia);
    };

    const handleDiaLeave = () => {
        setDiaHover();
    };

    const handleAlterarMeses = (value) => {
        setMesSelecionado(value);
        setDiaSelecionado('');
        setLoadingDias(true);
        setDiaSelecionadoSaida('')
    };

    const handleAlterarAno = (selectedYear) => {
        setAnoSelecionado(selectedYear);
        setDiaSelecionado('');
        setLoadingDias(true);
        setDiaSelecionadoSaida('')
    };


    const [arrayComAsHoras, setArrayComAsHoras] = useState([]);


    //CLIQUE DO DIA DE ENTREGA
    const handleDiaClick = (diaFormatted, availability, price) => {
        //So deixa clicar se for ...
        if (availability === 'livre' || availability === 'poucas_vagas') {
            setLoadingDias(false)

            if (openCalendarioentrega === true) {
                const dataFormatada = diaFormatted;
                const partes = dataFormatada.split('-');
                const diaSelecionado = partes[0];
                const mesSelecionado = partes[1];
                const anoSelecionado = partes[2];
                setAnoSelecionadoEntradaParaHoras(anoSelecionado)
                setDataentrega(diaFormatted);
                setDiaSelecionado(diaSelecionado);
                setPrecoDiaSelecionado(price);


                if (isTabletMobile) {
                    setOpenCalendario(false);
                    setOpenCalendarioSaida(false);
                } else {
                    setOpenCalendarioEntrega(false);
                    setOpenCalendarioSaida(true);
                }

                setDatarecolha('');
                setDiaSelecionadoSaida('');
                setPrecoDiaSelecionadoSaida();
                setErroNoInputDataEntrada(false)



                axios.get(`https://app.indoorparkinglowcost.pt/wp-json/api/calendar/${anoSelecionado}/${mesSelecionado}?timestamp=${new Date().getTime()}`)
                    .then(response => {
                        const formattedDateTESTE = diaSelecionado + "-" + mesSelecionado + "-" + anoSelecionado;
                        const diasRecebidosrray = Object.values(response.data.dates);
                        // console.log(diasRecebidosrray)
                        setArrayComAsHoras(Object.values(response.data.dates))
                        const found = diasRecebidosrray.find(day => day.date === formattedDateTESTE);

                        if (found) {
                            if(validarHorasForm === "0"){
                                let primeiroValor = Object.keys(found.horas_entrada)[0];
                                setHoraEntrega(primeiroValor)
                                setListadeHorasEntrega(found.horas_entrada)
                                setHorasEstaoDisponiveisEntrega(true)
                            }else{
                                let primeiroValor = Object.keys(found.horas_entrada_rapida)[0];
                                setHoraEntrega(primeiroValor)
                                setListadeHorasEntrega(found.horas_entrada_rapida)
                                setHorasEstaoDisponiveisEntrega(true)
                            }
                            
                        }
                    })
                    .catch(error => {
                        console.error('Erro:', error);
                    });


            }
        }
        if (availability === 'poucas_vagas') {
            setOnPoucasVagas(true)
        }
    };


    //CLIQUE DO DIA DE RECOLHA
    const handleDiaRecolhaClick = (diaFormatted, availability, price) => {

        // console.log('arrayComAsHoras', arrayComAsHoras)

        if (arrayComAsHoras && arrayComAsHoras.length > 0 && availability === 'livre' || arrayComAsHoras && arrayComAsHoras.length > 0 && availability === 'poucas_vagas') {
            setLoadingDias(false)

            if (openCalendariosaida === true) {

                const dataFormatada = diaFormatted;
                const partes = dataFormatada.split('-');
                const diaSelecionado = partes[0];
                const mesSelecionado = partes[1];
                const anoSelecionado = partes[2];

                setDatarecolha(dataFormatada);
                setDiaSelecionadoSaida(diaSelecionado);
                setPrecoDiaSelecionadoSaida(price);
                setOpenCalendario(false);
                setOpenCalendarioSaida(false);


                const saberodiadeentrega = dataentrega
                const partesdodiadeentrega = saberodiadeentrega.split('-');
                const diaSelecionadododiadeentrega = partesdodiadeentrega[0];
                const mesSelecionadododiadeentrega = partesdodiadeentrega[1];
                const anoSelecionadododiadeentrega = partesdodiadeentrega[2];

                //se o mes ou o ano selecionado for diferente do mes ou o ano selecionado do dia de entrega
                if (mesSelecionado !== mesSelecionadododiadeentrega || anoSelecionado !== anoSelecionadododiadeentrega) {

                    axios.get(`https://app.indoorparkinglowcost.pt/wp-json/api/calendar/${anoSelecionado}/${mesSelecionado}?timestamp=${new Date().getTime()}`)
                        .then(response => {
                            const formattedDateTESTE = diaSelecionado + "-" + mesSelecionado + "-" + anoSelecionado;
                            const diasRecebidosrray = Object.values(response.data.dates);
                            // console.log(diasRecebidosrray)
                            setArrayComAsHoras(Object.values(response.data.dates))
                            const found = diasRecebidosrray.find(day => day.date === formattedDateTESTE);

                            if (found) {
                                if(validarHorasForm === "0"){
                                    let primeiroValor = Object.keys(found.horas_saida)[0];
                                    setHoraRecolha(primeiroValor)
                                    setListadeHorasRecolha(found.horas_saida)
                                    setHorasEstaoDisponiveisRecolha(true)
                                }else{
                                    let primeiroValor = Object.keys(found.horas_saida_rapida)[0];
                                    setHoraRecolha(primeiroValor)
                                    setListadeHorasRecolha(found.horas_saida_rapida)
                                    setHorasEstaoDisponiveisRecolha(true)
                                }
                               
                            }
                        })
                        .catch(error => {
                            console.error('Erro:', error);
                        });

                } else {
                    const found = arrayComAsHoras.find(day => day.date === dataFormatada);

                    if (found) {
                        let primeiroValor = Object.keys(found.horas_saida)[0];
                        setHoraRecolha(primeiroValor)
                        setListadeHorasRecolha(found.horas_saida)
                        setHorasEstaoDisponiveisRecolha(true)
                    }
                }


            }
            
        } else {

            if (arrayComAsHoras) {
                axios.get(`https://app.indoorparkinglowcost.pt/wp-json/api/calendar/${anoSelecionado}/${mesSelecionado}?timestamp=${new Date().getTime()}`)
                    .then(response => {
                        const formattedDateTESTE = diaSelecionado + "-" + mesSelecionado + "-" + anoSelecionado;
                        const diasRecebidosrray = Object.values(response.data.dates);

                        setArrayComAsHoras(Object.values(response.data.dates))
                        const found = diasRecebidosrray.find(day => day.date === formattedDateTESTE);

                        if (found) {
                            let primeiroValor = Object.keys(found.horas_saida)[0];
                            setHoraRecolha(primeiroValor)
                            setListadeHorasRecolha(found.horas_saida)
                            setHorasEstaoDisponiveisRecolha(true)
                        }
                    })

                    .catch(error => {
                        console.error('Erro:', error);
                    });
            }
        }


        if (availability === 'poucas_vagas') {
            setOnPoucasVagas(true)
        }

    };



    //PARA ABRIR O CALENDARIO
    const toggleFecharCalendario = () => {
        setOpenCalendario(false);
        setOpenCalendarioSaida(false);
    };


    //POPUP QUANDO CLICA NUM DIA COM POUCAS VAGAS
    const handleclosealertpoucasvagas = () => {
        setOnPoucasVagas(false);
    };




    function criarBlocosDiasVazios(ano, mes) {

        const primeiroDiaCalendario = new Date(ano, mes - 1, 1);
        // console.log('primeiroDiaCalendario',primeiroDiaCalendario)
        let obterDiaDaSemana = primeiroDiaCalendario.getDay();
        // console.log('obterDiaDaSemana', obterDiaDaSemana)
        obterDiaDaSemana = (obterDiaDaSemana === 0) ? 6 : obterDiaDaSemana - 1;
        //console.log('obterDiaDaSemana',obterDiaDaSemana)
    
        const calendar = [];
        let dia_da_semana_index = 0;
    
        for (let i = obterDiaDaSemana; i > 0; i--) {
            calendar.push({ date: null, dia_da_semana: dia_da_semana_index, available: "indisponivel" });
            dia_da_semana_index++;
        }

        // console.log(calendar)
        return calendar;
    }





    return (
        <div className={`calendario ${openCalendarioentrega ? 'calendarioentrada-open' : 'calendarioentrada-saida'}`}>
            <div className='sec-calendario'>
                {loadingdias ? (
                    <>
                        <div className="loading-sec-dias">
                            <Spin indicator={antIcon} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className='col-mes-ano-calendario'>
                            <Select
                                id="meses"
                                value={mesSelecionado}
                                onChange={handleAlterarMeses}
                                placeholder="Selecione o mês"
                            >
                                <Option value="01">Janeiro</Option>
                                <Option value="02">Fevereiro</Option>
                                <Option value="03">Março</Option>
                                <Option value="04">Abril</Option>
                                <Option value="05">Maio</Option>
                                <Option value="06">Junho</Option>
                                <Option value="07">Julho</Option>
                                <Option value="08">Agosto</Option>
                                <Option value="09">Setembro</Option>
                                <Option value="10">Outubro</Option>
                                <Option value="11">Novembro</Option>
                                <Option value="12">Dezembro</Option>
                            </Select>
                            <Select
                                id="anos"
                                value={anoSelecionado}
                                onChange={handleAlterarAno}
                                placeholder="Selecione o ano"
                            >
                                {anoAPI.map((year) => (
                                    <Option key={year} value={year}>
                                        {year}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        <div className='calendario-main-sec'>
                            <table>
                                <thead>
                                    <tr className='dias-da-semana'>
                                        {diadasemana.map((item, key) => (
                                            <td key={key}>
                                                {item}
                                            </td>
                                        ))}
                                    </tr>
                                </thead>
                                {openCalendarioentrega ? (
                                    <tbody className='todos-dias-do-mes'>
                                        {Object.values(diascalendario).reduce((rows, dia, index) => {
                                            if (index % 7 === 0) {
                                                // console.log('dia', dia)
                                                // console.log('index', index)
                                                rows.push([]);
                                            }
                                            rows[rows.length - 1].push(dia);
                                            // console.log('rows', rows)
                                            return rows;

                                        }, []).map((week, rowIndex) => {

                                            // console.log(rowIndex)

                                            // if (rowIndex === 0) {
                                            //     week = Array(2).fill({});

                                            // }
                                            // console.log(week)
                                            return (
                                                <tr className='dias-no-calendario-main' key={rowIndex}>
                                                    {week.map((dia, cellIndex) => {


                                                        // if (cellIndex === 1) {
                                                        //     dia = ""
                                                        // }


                                                        const [diaPart, mesPart, anoPart] = dia.date ? dia.date.split('-') : ['', '', ''];

                                                        const diaFormatted = `${diaPart}-${mesPart}-${anoPart}`;

                                                        const diaClassName = `dia-${dia.available}`;

                                                        let precohoverdia = null;

                                                        if (espacoSelecionados === "Parque Interior") {
                                                            precohoverdia = dia.valordeparqueinterior;
                                                        } else {
                                                            precohoverdia = dia.valordeparqueexterior;
                                                        }

                                                        let diaDisponivel = dia.available === "livre" || dia.available === "poucas_vagas";



                                                        return (
                                                            <td
                                                                key={cellIndex}
                                                                onClick={() => handleDiaClick(diaFormatted, dia.available, dia.price)}
                                                                onMouseEnter={() => handleDiaHover(diaPart, mesPart, anoPart, precohoverdia)}
                                                                onMouseLeave={() => handleDiaLeave(diaFormatted)}
                                                            >
                                                                <div className={`dia-calendario ${diaClassName} ${diaSelecionado === diaPart ? 'dia-selecionado' : ''}`}>
                                                                    <span className='dia-individual'>{diaPart}</span>
                                                                    {diaDisponivel && espacoSelecionados === "Parque Interior" && (
                                                                        <span className='preco-individual'>{dia.valordeparqueinterior} €</span>
                                                                    )}
                                                                    {diaDisponivel && espacoSelecionados === "Parque Exterior" && (
                                                                        <span className='preco-individual'>{dia.valordeparqueexterior} €</span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                ) : (
                                    <>
                                        <tbody className='todos-dias-do-mes'>
                                            {Object.values(diascalendario).reduce((rows, dia, index) => {
                                                if (index % 7 === 0) {
                                                    rows.push([]);
                                                }
                                                rows[rows.length - 1].push(dia);
                                                return rows;
                                            }, []).map((week, rowIndex) => (
                                                <tr className='dias-no-calendario-main' key={rowIndex}>
                                                    {week.map((dia, cellIndex) => {
                                                        // const [diaPart, mesPart, anoPart] = dia.date.split('-'); //recebe o dia num array
                                                        const [diaPart, mesPart, anoPart] = dia.date ? dia.date.split('-') : ['', '', ''];
                                                        const formattedDateSelecionadoEntrada = dataentrega.split('-');
                                                        const diaaa = parseInt(formattedDateSelecionadoEntrada[0], 10);
                                                        const mes = parseInt(formattedDateSelecionadoEntrada[1], 10) - 1;
                                                        const ano = parseInt(formattedDateSelecionadoEntrada[2], 10);

                                                        let diaFormatted2 = new Date(anoPart, mesPart - 1, diaPart); // DIA QUE ESTÁ A CORRER
                                                        diaFormatted2 = diaFormatted2.getTime() / 1000;

                                                        let formattedDateSelecionadoEntrada2 = new Date(ano, mes, diaaa); // DIA SELECIONADO
                                                        formattedDateSelecionadoEntrada2 = formattedDateSelecionadoEntrada2.getTime() / 1000;


                                                        const diaFormatted = `${diaPart}-${mesPart}-${anoPart}`; //dividir o dia
                                                        const diaClassName = `dia-${dia.available}`; // para saber se o dia está selecionado ou não
                                                        const precoDiaSelecionado = dia.price;
                                                        let diaDisponivel = dia.available === "livre" || dia.available === "poucas_vagas";


                                                        let isDiaEntreEntradaESaida;

                                                        if (diaFormatted2 > diaSelecionado && diaFormatted2 < diaSelecionadoSaida) {
                                                            isDiaEntreEntradaESaida = true;
                                                        } else {
                                                            isDiaEntreEntradaESaida = false;
                                                        }

                                                        let isDiaentreEntradaeMouse;

                                                        if (diaFormatted2 > diaSelecionado && diaFormatted2 <= diaHover) {
                                                            isDiaentreEntradaeMouse = true;
                                                        } else {
                                                            isDiaentreEntradaeMouse = false;
                                                        }


                                                        let temDiaIndisponivel = false;
                                                        for (let i = diaSelecionado; i <= diaSelecionadoSaida; i++) {
                                                            if (diasIndisponiveisJson[i] && diasIndisponiveisJson[i].available === 'indisponivel') {
                                                                temDiaIndisponivel = true;
                                                                break;
                                                            }
                                                        }


                                                        if (!diaDisponivel && diaFormatted2 > formattedDateSelecionadoEntrada2 && testeDia === "") {
                                                            setTesteDia(diaFormatted2)
                                                        }


                                                        if (diaFormatted2 > testeDia && testeDia !== "" || diaFormatted2 < formattedDateSelecionadoEntrada2) {
                                                            diaDisponivel = false;
                                                        }


                                                        let precohoverdia = null;

                                                        if (espacoSelecionados === "Parque Interior") {
                                                            precohoverdia = dia.valordeparqueinterior;
                                                        } else {
                                                            precohoverdia = dia.valordeparqueexterior;
                                                        }

                                                        return (
                                                            <td
                                                                key={cellIndex}
                                                                onClick={() => {
                                                                    handleDiaRecolhaClick(diaFormatted, dia.available, dia.price);
                                                                }}
                                                                onMouseEnter={() => handleDiaHover(diaPart, mesPart, anoPart, precohoverdia)}
                                                                onMouseLeave={() => handleDiaLeave(diaFormatted)}
                                                            >


                                                                <div className={` ${diaDisponivel ? 'dia-disponivel' : 'dia-indisponivel'} dia-calendario ${diaClassName} ${diaSelecionado === diaPart ? 'dia-selecionado' : ''}  ${diaSelecionadoSaida === diaPart ? 'dia-selecionado-saida' : ''} ${isDiaentreEntradaeMouse ? 'dia-selecionado-hover' : ''} ${isDiaEntreEntradaESaida ? 'dia-entre-selecionado' : ''}`}>
                                                                    <span className='dia-individual'>{diaPart}</span>
                                                                    {diaDisponivel && espacoSelecionados === "Parque Interior" && (
                                                                        <span className='preco-individual'>{dia.valordeparqueinterior} €</span>
                                                                    )}

                                                                    {diaDisponivel && espacoSelecionados === "Parque Exterior" && (
                                                                        <span className='preco-individual'>{dia.valordeparqueexterior} €</span>
                                                                    )}

                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>

                                            ))}
                                        </tbody>
                                    </>
                                )}
                            </table>
                        </div>

                        {onPoucasVagas ? (
                            <>
                                <div className="loading-sec-baixo mensagem-de-loading fundo-branco">
                                    <p><b>ATENÇÃO!</b> Poucas vagas.</p>
                                    <CloseCircleOutlined onClick={handleclosealertpoucasvagas} />
                                </div>
                            </>
                        ) : null}

                    </>
                )}
            </div>
            <div className='dia-destaque-calendario'>
                {loadingdias ? (
                    <>
                    </>
                ) : (
                    <>

                        {isTabletMobile ? (
                            <>
                            </>
                        ) : (
                            <>
                                <a className="btn-close-calendario" onClick={toggleFecharCalendario}>
                                    <img src={close} alt="Fechar" />
                                </a>
                            </>
                        )}

                        {openCalendarioentrega ? (
                            <>
                                <PreviewEntrega
                                    isTabletMobile={isTabletMobile}
                                    diaHoverMain={diaHoverMain}
                                    mesHover={mesHover}
                                    anoHover={anoHover}
                                    precoHover={precoHover}
                                    diatoday={diatoday}
                                    mestoday={mestoday}
                                    anotoday={anotoday}
                                    diaSelecionado={diaSelecionado}
                                    precodiaSelecionado={precodiaSelecionado}
                                />
                            </>
                        ) : (
                            <>
                                <PreviewRecolha
                                    isTabletMobile={isTabletMobile}
                                    diaHoverMain={diaHoverMain}
                                    mesHover={mesHover}
                                    anoHover={anoHover}
                                    precoHover={precoHover}
                                    diatoday={diatoday}
                                    mestoday={mestoday}
                                    anotoday={anotoday}
                                    diaSelecionado={diaSelecionado}
                                    diaSelecionadoSaida={diaSelecionadoSaida}
                                    precototaldareserva={precototaldareserva}
                                    precodiaSelecionadoSaida={precodiaSelecionadoSaida}
                                    precodiaSelecionado={precodiaSelecionado}
                                />
                            </>
                        )}

                        {isTabletMobile ? (
                            <>
                                <div className='sec-close-calendario'>
                                    <a className="btn-close-calendario" onClick={toggleFecharCalendario}>
                                        Fechar Calendário  <img src={close} alt="Fechar" />
                                    </a>
                                </div>

                            </>
                        ) : (null)}
                    </>
                )}
            </div>
        </div >
    );
};

export default Calendario;
