import { BiXCircle } from "react-icons/bi";

export function ErroMsg(props) {
    return (
        <div className="mensagem-de-erro msg-sec">
            <BiXCircle />
            <span> Erro, {props.mensagem}</span >
        </div>
    )

}
